<template>
  <section class="blogpost">
    <div class="blogpost--box">
      <div class="blogpost--text">
        <div class="blogpost--text--title">
          <h2>{{user.title}}</h2>
        </div>
        <div class="blogpost--text--author">
          <div class="blogpost--text--author--image">
            <img class="image--sm pfp" :src="author.avatar" alt="" />
          </div>
          <div class="blogpost--text--author--text">
            <h4>{{author.name}}</h4>
            <h6>Share Post</h6>
            <div class="blogpost--text--author--text--share">
             <img src="@/assets/svg/facebook.svg"  height="24" width="24" alt="">
            </div>
            <div>
              <h5>{{date}} • </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="blogpost--post">
        <div class="blogpost--post--box">
          <div class="blogpost--post--image">
            <img :src="user.featured_image" alt="" />
          </div>
          <div class="blogpost--post--text">
            <h2>{{user.content__filtered}}</h2>
            <p>{{user.content}}</p>
            <!-- <p>
              A virtual environment is a way to have multiple, parallel
              instances of the Python interpreter, each with different package
              sets and different configurations. Each virtual environment
              contains a discrete copy of the Python interpreter, including
              copies of its support utilities.
            </p>
            <p>
              [ Also on InfoWorld: Python virtualenv and venv dos and don’ts ]
            </p>
            <p>
              The packages installed in each virtual environment are seen only
              in that virtual environment and no other. Even large, complex
              packages with platform-dependent binaries can be corralled off
              from each other in virtual environments.
            </p>
            <h2>There are a few common use cases for a virtual environment:</h2>
            <p>
              You’re developing multiple projects that depend on different
              versions of the same packages, or you have a project that must be
              isolated from certain packages because of a namespace collision.
              This is the most standard use case.
            </p>
            <p>
              You’re working in a Python environment where you can’t modify the
              site-packages directory. This may be because you’re working in a
              highly controlled environment, such as managed hosting, or on a
              server where the choice of interpreter (or packages used in it)
              can’t be changed because of production requirements.
            </p>
            <p>
              You want to experiment with a specific combination of packages
              under highly controlled circumstances, for instance to test
              cross-compatibility or backward compatibility.
            </p>
            <p>
              You want to run a “baseline” version of the Python interpreter on
              a system with no third-party packages, and only install
              third-party packages for each individual project as needed.
            </p>
            <p>
              Nothing says you can’t simply unpack a Python library into a
              subfolder of a project and use it that way. Likewise, you could
              download a standalone copy of the Python interpreter, unpack it
              into a folder, and use it to run scripts and packages devoted to
              it.
            </p>
            <p>
              But managing such cobbled-together projects soon becomes
              difficult. It only seems easier to do that at first. Working with
              packages that have binary components, or that rely on elaborate
              third-party dependencies, can be a nightmare. Worse, reproducing
              such a setup on someone else’s machine, or on a new machine you
              manage, is tricky.
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore, mapActions, mapGetters } from "vuex";
const store = useStore()
const router = useRoute()
const Id = router.params.id
const user = ref("")
const author = ref("")
const date = ref("")
mapActions(["PostDetails"])
onMounted(async () => {
  await store.dispatch("PostDetails", Id)
  user.value = store.getters.DetailsPost
  author.value = store.getters.DetailsPost.author
  date.value = store.getters.DetailsPost.created_at.slice(0, 10)
})
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.pfp {
  height: 70px;
  width: 70px;
  border-radius: 100%;
}
.blogpost {
  padding: 3rem 7%;
}
.blogpost--box {
  text-align: center;
}
.blogpost--text {
  .blogpost--text--title {
    h2 {
      padding: 0 8rem;
      @include title {
        font-family: $karla;
        color: $black-100;
      }
    }
  }
  .blogpost--text--author--text {
    h4,
    h5 {
      @include caption {
        font-family: $karla;
        color: $black-100;
      }
    }
    h6 {
      @include label {
        font-family: $karla;
        color: $secondary-80;
      }
    }
    .blogpost--text--author--text--share {
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin: 0.5rem;
    }
  }
}
.blogpost--post {
  display: flex;
  justify-content: center;
  .blogpost--post--box {
    width: 70%;
  }
  .blogpost--post--image {
    img {
      width: 100%;
    }
  }
  .blogpost--post--text {
    text-align: left;
    h2 {
      @include sub-heading-bold {
        font-family: $karla;
        color: $black-100;
      }
    }
  }
}
@include xl {
  .blogpost--text {
    text-align: center;
    max-width: 100vw;
      width: max(90%, 300px);
  .blogpost--text--title {
    @include flex(center,center);
    h2 {
      padding: 0 0rem;
      @include title {
        font-size: 2rem;
      }
    }
  }
  .blogpost--text--author--text {
    h4,
    h5 {
      @include caption {
        font-family: $karla;
        color: $black-100;
      }
    }
    h6 {
      @include label {
        font-family: $karla;
        color: $secondary-80;
      }
    }
    .blogpost--text--author--text--share {
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin: 0.5rem;
    }
  }
}
}
@include sm {
  .blogpost--text {
  .blogpost--text--title {
    @include flex(center,center);
    h2 {
      @include title {
        font-size: 1.4rem;
      }
    }
  }
  .blogpost--text--author--text {
    h4,
    h5 {
      @include caption {
        font-size: 0.7rem;
      }
    }
    h6 {
      @include label {
        font-family: $karla;
        color: $secondary-80;
      }
    }
    .blogpost--text--author--text--share {
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin: 0.5rem;
    }
  }
}
.blogpost--post {
  display: flex;
  justify-content: center;
  .blogpost--post--box {
    width: 100%;
  }
  .blogpost--post--image {
    img {
      width: 100%;
    }
  }
  .blogpost--post--text {
    text-align: left;
    h2 {
      @include sub-heading-bold {
        font-size: 1rem;
      }
    }
  }
}
}
@include xs {
  .blogpost--text {
  .blogpost--text--title {
    @include flex(center,center);
    h2 {
      @include title {
        font-size: 1.4rem;
      }
    }
  }
  .blogpost--text--author--text {
    h4,
    h5 {
      @include caption {
        font-size: 0.7rem;
      }
    }
    h6 {
      @include label {
        font-family: $karla;
        color: $secondary-80;
      }
    }
    p {
      @include caption {
        font-family: $karla;
        font-size: 0.7rem;
      }
    }
    .blogpost--text--author--text--share {
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin: 0.5rem;
    }
  }
}
}
</style>