<template>
  <div class="success" :class="{active: $store.state.success}" v-if="$store.state.success === true">
    <p>Success!!</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.success {
    position: absolute;
    z-index: 5;
    top: 10%;
    left: 50%;
    padding: 10px 25px;
    width: Min(150px, 90%);
    border-radius: 8px;
    background-color: #4BB543;
    text-align: center;
    @extend %flex-ac-jc;
    transform: translateY(10vh);
    opacity: 0;
    transition: all 1s ease-in-out;
    &.active {
      opacity: 1;
      transition: all 1s ease-in-out;
      transform: translateY(0);
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: Max(1rem, 16px);
      line-height: Max(1.25rem, 20px);
      letter-spacing: -0.0016em;
      color: $black;
      margin-bottom: unset;
    }
}
</style>