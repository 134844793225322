<template>
<Intro />
  <Form />
</template>

<script>
import Form from './Form.vue';
import Intro from './Intro.vue'
export default {
    components: {
        Form,
        Intro
    }
}
</script>

<style>

</style>