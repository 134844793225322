<template>
  <section class="filter">
    <div class="filter--box">
      <div class="filter--text">
        <h2>Find the best bootcamp for you</h2>
      </div>
      <div class="filter--form">
        <form @submit.prevent="handleSubmit" action="">
          <input
            v-model="input"
            type="text"
            placeholder="Have a bootcamp in mind? Search tech school of your choice..."
            name=""
            id=""
          />
          <button class="button--search" type="submit">Search</button>
        </form>
      </div>
      <div class="intro--search--settings">
        <img src="@/assets/filter.svg" alt="" @click="handleFilter" width="210" height="67" />
        <div v-if="filter" class="intro--search--settings--filter">
          <div class="intro--search--settings--filter--input">
            <input type="checkbox" name="" id="" />
            <label for="">Online Bootcamps</label>
          </div>
          <div class="intro--search--settings--filter--input">
            <input type="checkbox" name="" id="" />
            <label for="">Offline Bootcamps</label>
          </div>
          <div>
            <button class="button--search">Filter</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      filter: false,
      input: "",
    };
  },
  methods: {
    handleFilter() {
      this.filter = !this.filter;
    },
    handleSubmit() {
      this.input = "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.filter {
  padding: 1rem 7% 3rem 7%;
}
.filter--text {
  max-width: 100vw;
  margin: 0 auto 2rem auto;
  text-align: center;
  h2 {
    width: min(90%, 671px);
    margin: 0 auto;
    @include details {
      color: #b86b41;
      font-family: $karla;
    }
  }
}
.filter--form {
  width: min(90%, 750px);
  max-width: 100vw;
  margin: 0 auto;
  text-align: center;
  height: 57px;
  form {
    input {
      outline: none;
      border: 1px solid #E0E0E0;
      width: min(70%, 701px);
      height: 57px;
      background: #ffffff;
      border-radius: 8px 0px 0px 8px;
      padding: 1rem;
      margin: auto 0;
      @include caption {
        font-family: $karla;
        color: $secondary-60;
        font-size: 18px;
      }
    }
    .button--search {
      background: $primary-80;
      border-radius: 0px 8px 8px 0px;
      width: min(30%, 193px);
      height: 57px;
      border: none;
      margin: auto 0;
      @include button {
        font-family: $karla;
        color: $white;
      }
    }
  }
}
.intro--search--settings {
  @include flex(space-between, center);
  margin: 0 auto;
  width: min(90%, 711px);
  .intro--search--settings--filter {
    width: 100%;
    @include flex(space-between, center);
    gap: 3rem;
    .intro--search--settings--filter--input {
      align-self: center;
      margin: auto 0;
      label {
        margin: 0 0.5rem;
        @include label {
          color: $black-50;
          font-family: $karla;
        }
      }
    }
  }
  button {
        background: $primary-80;
      }
}
@include md {
  .intro--search--settings {
    flex-direction: column;
    margin: 0 auto 1rem auto;
    .intro--search--settings--filter {
        flex-direction: column;
        margin: 0.4rem 0;
    }
}
.filter--text {
  h2 {
    @include details {
      font-size: 1.5rem;
    }
  }
}
}
@include xs {
  .filter--text {
  h2 {
    @include details {
      font-size: 1.1rem;
    }
  }
}
}

</style>