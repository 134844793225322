<template>
  <About />
</template>

<script>
import About from '@/pages/About.vue'
export default {
components: {
    About
}
}
</script>

<style>

</style>