import { createStore } from 'vuex'
import AuthModule from "./modules/AuthModules.js"
import BootcampModules from "./modules/BootcampModules.js"
import ProjectModule from "./modules/ProjectModule.js"
import ResourceModules from './modules/ResourceModules.js'
import InterviewModules from './modules/InterviewModules.js'
import BlogModules from './modules/BlogModules.js'
import InternshipModule from './modules/InternshipModule.js'
import MentorModules from './modules/MentorModules.js'
import YoutubeModule from './modules/YoutubeModule.js'
export default createStore({
  state: {
    AccessState: null,
    ChatActive: false,
    CourseSelect: true,
    review: false,
    pfp: "",
    success: false,
    fail: false
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    AuthModule,
    BootcampModules,
    ProjectModule,
    ResourceModules,
    InterviewModules,
    BlogModules,
    InternshipModule,
    MentorModules,
    YoutubeModule
  }
})
