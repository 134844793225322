<template>
  <Intro />
  <Skillset />
  <Roadmap />
  <Hiring />
  <Mentors />
</template>

<script>
import Intro from "./Intro.vue";
import Roadmap from "./Roadmap.vue";
import Skillset from "./Skillset.vue";
import Mentors from "@/components/Mentors.vue";
import Hiring from "@/components/Hiring.vue";
export default {
  components: {
    Intro,
    Roadmap,
    Skillset,
    Hiring,
    Mentors,
  },
};
</script>

<style>
</style>