<template>
  <section class="info">
    <div class="info_box">
        <div class="courses">
            <div class="course_intro">
                <h3>School Information</h3>
                <div class="course_intro--form">
                    <div class="course_intro--formSplit flex_col">
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                                School name <img src="./asterisk.svg" alt="" />
                            </label>
                            <input type="text" class="form_input" placeholder="Tobiloba Adebayo" name="" id="">
                        </div>
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                                Logo <img src="./asterisk.svg" alt="" />
                            </label>
                             <input style="visibility:hidden" type="text" class="form_input" placeholder="Tobiloba Adebayo" name="" id="">
                        </div>
                    </div>
                    <div class="course_intro--textarea">
                        <label class="form__input--label" for="">
                                Description <img src="./asterisk.svg" alt="" />
                            </label>
                        <textarea class="form_textarea" name="" id="" cols="30" rows="10"></textarea>
                    </div>
                    <div class="course_intro--formSplit flex_col">
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                                Founded <img src="./asterisk.svg" alt="" />
                            </label>
                            <input type="text" class="form_input" placeholder="Founded what year" name="" id="">
                        </div>
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                                Location <img src="./asterisk.svg" alt="" />
                            </label>
                            <input type="text" class="form_input" placeholder="Where can you be found?" name="" id="">
                        </div>
                    </div>
                     <div class="course_intro--formSplit flex_col">
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                                website <img src="./asterisk.svg" alt="" />
                            </label>
                            <input type="text" class="form_input" placeholder="Enter URL" name="" id="">
                        </div>
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                                Phone number <img src="./asterisk.svg" alt="" />
                            </label>
                            <input type="number" class="form_input" placeholder="e.g. +2348 111 2345" name="" id="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="information_intro">
                <div class="information_courses">
                <h3>
                    Courses
                </h3>
                <p>Courses Offered:</p>
                <div class="information_courses--container">
                    <div v-for="course in courses" :class="{light: course.active}" @click="handleCourse(course.id)" :key="course.id" class="information_courses--items">
                        {{course.name}}
                    </div>
                    </div>
                </div>
                <div class="information_admission">
                    <p>Admission by:</p>
                    <div class="information_admission--container">
                    <div v-for="admission in admissions" :class="{light: admission.active}" @click="handleAdmission(admission.id)" :key="admission.id" class="information_courses--items">
                        {{admission.name}}
                    </div>
                    </div>
                </div>
                <div class="information_payment">
                    <p>Payment Plan</p>
                    <div class="information_payment--container">
                    <div v-for="payment in payments" :class="{light: payment.active}" @click="handlePayment(payment.id)" :key="payment.id" class="information_courses--items">
                        {{payment.name}}
                    </div>
                    </div>
                </div>
                <div class="information_scholarship">
                    <p>Scholarship</p>
                    <div class="information_scholarship--container">
                    <div v-for="scholarship in scholarships" :class="{light: scholarship.active}" @click="handleScholarship(scholarship.id)" :key="scholarship.id" class="information_courses--items">
                        {{scholarship.name}}
                    </div>
                    </div>
                </div>
            </div>
            <div class="sm_intro">
                <h3>
                    Social media handle
                </h3>
                <div class="course_intro--formSplit flex_col">
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                                Facebook
                            </label>
                            <input type="text" class="form_input" placeholder="Facebook URL" name="" id="">
                        </div>
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                                LinkedIn
                            </label>
                            <input type="text" class="form_input" placeholder="LinkedIn URL" name="" id="">
                        </div>
                    </div>
                    <div class="course_intro--formSplit flex_col">
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                                Instagram
                            </label>
                            <input type="text" class="form_input" placeholder="Instagram URL" name="" id="">
                        </div>
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                               Twitter
                            </label>
                            <input type="text" class="form_input" placeholder="Twitter URL" name="" id="">
                        </div>
                    </div>
            </div>
            <div class="course_details">
                <h3>
                    Course Details
                </h3>
                <div class="course_intro--formSplit flex_col">
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                               Course Name 
                            </label>
                            <input type="text" class="form_input" placeholder="Course name" name="" id="">
                        </div>
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                               Duration
                            </label>
                            <input type="text" class="form_input" placeholder="Duration" name="" id="">
                        </div>
                    </div>
                    <div class="course_intro--formSplit flex_col">
                        <div class="course_intro--formSplit forms">
                            <label class="form__input--label" for="">
                                Price 
                            </label>
                            <input type="text" class="form_input" placeholder="e.g. N300,000" name="" id="">
                        </div>
                    </div>
                    <div class="course_intro--textarea">
                        <label class="form__input--label" for="">
                                Description <img src="./asterisk.svg" alt="" />
                            </label>
                        <textarea class="form_textarea" placeholder="Write your messaage" name="" id="" cols="30" rows="10"></textarea>
                    </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
    data() {
        return {
             scholarships: [
                {
                    id: 1,
                    name: 'None',
                    active: false
                },
                {
                    id: 2,
                    name: 'Partial',
                    active: false
                },
                {
                    id: 3,
                    name: 'Full Discounted',
                    active: false
                }
            ],
            payments: [
                {
                    id: 1,
                    name: 'After Registration',
                    active: false
                },
                {
                    id: 2,
                    name: 'Installment',
                    active: false
                },
                {
                    id: 3,
                    name: 'After getting job',
                    active: false
                }
            ],
            admissions: [
                {
                    id: 1,
                    name: 'Registration',
                    active: false
                },
                {
                    id: 2,
                    name: 'Test',
                    active: false
                },
                {
                    id: 3,
                    name: 'Interview',
                    active: false
                }
            ],
            courses: [
                {
                    id: 1,
                    name: 'Data Science',
                    active: false
                },
                {
                    id: 2,
                    name: 'UI/UX Design',
                    active: false
                },
                {
                    id: 3,
                    name: 'Front-end Dev',
                    active: false
                },
                {
                    id: 4,
                    name: 'Back-end Dev',
                    active: false
                },
                {
                    id: 5,
                    name: 'DevOps',
                    active: false
                },
                {
                    id: 6,
                    name: 'Cybersecurity',
                    active: false
                },
                {
                    id: 7,
                    name: 'Data Analytics',
                    active: false
                },
                {
                    id: 8,
                    name: 'Data Engineering',
                    active: false
                },
                {
                    id: 9,
                    name: 'Machine learning',
                    active:false
                },
                {
                    id: 10,
                    name: 'web Development',
                    active: false
                },
                {
                    id: 11,
                    name: 'Software Engineering',
                    active: false
                },
                {
                    id: 12,
                    name: 'Cybersecurity',
                    active: false
                },
                {
                    id: 13,
                    name: 'Illustration',
                    active: false
                },
                {
                    id: 14,
                    name: 'Digital marketing',
                    active: false
                },
                {
                    id: 15,
                    name: 'Product Management',
                    active: false
                },
                {
                    id: 16,
                    name: 'Social Media Management',
                    active: false
                },
                {
                    id: 17,
                    name: 'Seo Management',
                    active: false
                },
                {
                    id: 18,
                    name: 'Game Development',
                    active: false
                },
                {
                    id: 19,
                    name: 'Mobile Development',
                    active: false
                },
                {
                    id: 20,
                    name: 'Full Stack Development',
                    active: false
                }
            ]
        }
    },
    methods: {
        handleCourse(number) {
            this.courses.map((newCourse) => {
                if(newCourse.id === number) {
                    newCourse.active = !newCourse.active
                }
            })
        },
        handlePayment(number) {
            this.payments.map((newPay) => {
                if(newPay.id === number) {
                    newPay.active = !newPay.active
                }
            })
        },
         handleAdmission(number) {
            this.admissions.map((newAdmission) => {
                if(newAdmission.id === number) {
                    newAdmission.active = !newAdmission.active
                }
            })
        },
         handleScholarship(number) {
            this.scholarships.map((newScholarship) => {
                if(newScholarship.id === number) {
                    newScholarship.active = !newScholarship.active
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.course_intro--form {
    text-align: left;
    // width: 769px;
}
.course_intro--formSplit {
    margin: 0.7rem 0;
}
.information_scholarship, .information_courses, .information_admission, .information_payment, .sm_intro, .course_details {
    margin: 1.2rem 0 0 0;
    h3 {
        @include sub-heading-bold {
            color: $black-100;
            font-family: $karla;
        }
    }
    p {
        @include button {
            color: $black-50;
            font-family: $karla;
        }
    }
}
.information_courses--container, .information_admission--container, .information_payment--container, .information_scholarship--container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
}
 .information_courses--items {
        border: 1px solid rgba(164, 74, 101, 0.3);
        border-radius: 5px;
        @include flex(center, center);
        text-align: center;
        height: 44px;
        padding: 12px 24px;
        transition: all 0.6s ease-in-out;
            cursor: pointer;
            @include button2 {
                color: $secondary-80;
                font-family: $karla;
            }
}
.light {
            background-color: $secondary-80;
            cursor: pointer;
            @include button2 {
                color: $white;
                font-family: $karla;
            }
        }
@include lg {
    .flex_col, .course_intro--formSplit{
        flex-direction: column;
        width: 100%;
    }
    .form_input {
        width: 100%;
    }
    .information_courses--items {
        padding: 10px 20px;
        height: 40px;
}
}
// @include breakpoint(380px) {
//     .information_courses--items {
//         h6 {
//             cursor: pointer;
//             @include button {
//                 font-size: 0.8rem;
//                 text-transform: none;
//             }
//         }
// }
// }
</style>