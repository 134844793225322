import axios from "axios"

const state = {
    interviews: null,
    DetailsInterview: null
};

const getters = {
    InterviewState: state => state.interviews,
    DetailsInterviewState: state => state.DetailsInterview
};

const actions = {
    async Interview({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get("interview-questions", {headers: headers});
         ;
        let interview = res.data.data
        await commit("setInterview", interview)
    },
    async InterviewDetails({commit}, Id) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`interview-questions/${Id}`, {headers: headers})
        let details = res.data.data.interviewQuestion
         
        await commit('setDetails', details)

    },
};

const mutations = {
    setInterview(state, interview) {
        state.interviews = interview
    },
    setDetails(state, details) {
        state.DetailsInterview = details
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}