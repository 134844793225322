<template>
  <section class="mentors">
    <div class="mentors--box">
      <div class="mentors--text">
        <h2>Meet our mentors</h2>
      </div>
      <div class="mentors--image">
        <router-link
          :to="{ name: 'Guardian Details', params: { id: item.id } }" v-for="item in mentor"
          :key="item.id"
        >
          <div
            class="mentors--image--mentor"
          >
            <img :src="item.image" alt="" />
            <h6>{{ item.name }}</h6>
            <h6>{{ item.focus }}</h6>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore, mapActions, mapGetters } from "vuex";
const store = useStore();
const mentor = ref();
onMounted(async () => {
  mapActions(["GetMentor"]);
  await store.dispatch("GetMentor");
  mentor.value = store.getters.MentorState;
});
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.mentors {
  padding: 2rem 7% 3rem 7%;
  text-align: center;
}
.mentors--text {
  h2 {
    margin-bottom: 3rem;
    @include sub-heading-bold {
      font-family: $karla;
      color: $black-50;
    }
  }
}
.mentors--image {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 2rem;
  margin: 0.7rem;

  .mentors--image--mentor {
    img {
      width: 140px;
      height: 140px;
      border-radius: 100%;
      margin: 0.7rem 1rem;
    }
    h6 {
      @include caption {
        color: $black;
        font-family: $karla;
      }
    }
  }
}
</style>
