<template>
  <section class="roadmap">
    <div class="roadmap__box">
      <div class="roadmap__text">
        <h3>
          Roadmap to Becoming a Data Science Engineer
        </h3>
      </div>
      <Roadmap :started="started" :going="going" />
    </div>
  </section>
</template>
<script setup>
import Roadmap from "@/shared/roadmap.vue"
import {ref} from "vue";
const started = ref([
  {
    id: 1,
    title: "Learn the foundation in Mathematics and Statistics",
    desc: "Develop a solid understanding of mathematics and statistics, including probability, linear algebra, calculus, and inferential statistics. These form the foundation of many data science concepts and techniques.",
    bg: "#DAADBB",
    btn: "Get Started"
  },
  {
    id: 2,
    title: "Gain Proficiency in Programming",
    desc: "Learn programming languages commonly used in data science, such as Python or R. Gain proficiency in data manipulation, data analysis, and visualization using libraries such as NumPy, Pandas, and Matplotlib/Seaborn.",
    bg: "#dee2e6"
  },
  {
    id: 3,
    title: "Learn Data Science Concepts and Techniques",
    desc: "Study data science concepts such as exploratory data analysis (EDA), data visualization, statistical analysis, and machine learning algorithms. Understand the data science lifecycle and best practices in data science projects.",
    bg: "#DAADBB"
  },
  {
    id: 4,
    title: "Master Data Visualization and Reporting",
    desc: "Develop proficiency in creating visualizations and reports to communicate data insights effectively. Learn tools such as Tableau, PowerBI, or other data visualization libraries to create compelling visualizations that convey insights to stakeholders.",
    bg: "#dee2e6"
  },
])
const going = ref([
  {
    id: 1,
    title: "Develop Machine Learning Skills",
    desc: "Dive deeper into machine learning algorithms, including supervised and unsupervised learning techniques, ensemble methods, and deep learning. Learn how to evaluate and optimize machine learning models, and gain experience in feature engineering and model selection.",
    bg: "#DAADBB",
    btn: "Get Going"
  },
  {
    id: 2,
    title: "Gain Domain-Specific Knowledge",
    desc: "Acquire domain-specific knowledge in industries such as finance, healthcare, marketing, or any other industry of interest. Understand the unique data challenges, regulations, and industry-specific requirements to effectively analyse and interpret data in that domain.",
    bg: "#dee2e6"
  },
  {
    id: 3,
    title: " Learn Big Data Technologies",
    desc: "Gain knowledge of big data technologies such as Apache Hadoop, Apache Spark, and distributed computing concepts. Understand how to process and analyze large-scale datasets efficiently using big data technologies.",
    bg: "#DAADBB"
  },
  {
    id: 4,
    title: "Develop Advanced Statistical Skills",
    desc: "Deepen your knowledge of advanced statistical techniques such as time series analysis, multivariate analysis, hypothesis testing, and experimental design. Understand when and how to apply these techniques in different data science projects.",
    bg: "#dee2e6"
  },
  {
    id: 5,
    title: " Gain Experience with Data Visualization and Storytelling",
    desc: "Learn how to create compelling data visualizations and use them to tell a compelling story. Develop skills in data storytelling and effectively communicate data insights to non-technical stakeholders.",
    bg: "#DAADBB"
  },
  {
    id: 6,
    title: "Gain Practical Experience through Projects",
    desc: "Apply your skills and knowledge through practical projects that demonstrate your data science expertise. Build a portfolio of projects that showcase your abilities and demonstrate your real-world data science skills to potential employers.",
    bg: "#dee2e6"
  }
])
</script>

<style lang="scss" scss scoped>
@import "@/styles/_typography.scss";
.roadmap {
  background: #F9F0EC;
  padding: 4rem 7%;
  &__box {
    width: 100%;
  }
  &__text {
    max-width: 100vw;
    width: min(90%, 804px);
    margin: 0 auto;
    text-align: center;
  h3 {
    @include heading3 {
      color: $secondary-80;
      font-family: $karla;
    }
    @media screen and (max-width: 900px) {
      margin: 0 auto;
      font-size: 1.5rem;
    }
  }
  }
}
</style>