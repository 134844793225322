<template>
  <footer class="footer">
    <div class="footer--box">
      <div class="footer--logo">
        <img src="@/assets/logo.png" alt="" />
        <h4>Zero to Hero</h4>
      </div>
      <div class="footer--list">
        <ul>
          <h4>Courses</h4>
          <li v-for="course in stack" :key="course.id" @click="handleMove(course.auth, course.link)">
             {{ course.title }}
          </li>
        </ul>
        <ul>
          <h4>Career Links</h4>
          <li v-for="career in careers" :key="career.id" @click="handleMove(career.auth, career.link)">
              {{ career.title }}
          </li>
        </ul>
        <ul>
          <h4>Company</h4>
          <li v-for="company in companies" :key="company.id" @click="handleMove(company.auth, company.link)">
              {{ company.title }}
          </li>
        </ul>
      </div>
    </div>
    <div class="footer--social">
      <div class="footer--social--hide">j</div>
      <div class="footer--social--copyright">
        <h4>Copyright 2023. Zero to Tech. All rights reserved.</h4>
      </div>
      <div class="footer--social--followUs">
        <p>Follow Us</p>
        <div class="footer--social--followUs--media">
        <a href="https://www.facebook.com/zerototech.co?mibextid=ZbWKwl" rel="" target="__blank">
          <svg
            width="8"
            height="16"
            viewBox="0 0 8 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.51997 2.63607H7.96932V0.111793C7.71927 0.0773953 6.85932 0 5.85781 0C3.76814 0 2.33665 1.3144 2.33665 3.73019V5.95349H0.0306702V8.77544H2.33665V15.876H5.1639V8.7761H7.37661L7.72787 5.95415H5.16324V4.01C5.1639 3.19438 5.38352 2.63607 6.51997 2.63607Z"
              fill="#343439"
            />
          </svg>
        </a>
        <a>
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5556 1.881C18.8283 2.2 18.0534 2.41144 17.2456 2.51411C18.0767 2.01789 18.711 1.23811 19.0092 0.298222C18.2343 0.760222 17.3788 1.08656 16.467 1.26867C15.7312 0.485222 14.6826 0 13.5386 0C11.319 0 9.53211 1.80156 9.53211 4.01011C9.53211 4.32789 9.559 4.63344 9.625 4.92433C6.292 4.76178 3.34278 3.16433 1.36156 0.730889C1.01567 1.331 0.812778 2.01789 0.812778 2.75733C0.812778 4.14578 1.52778 5.37656 2.59356 6.08911C1.94944 6.07689 1.31756 5.88989 0.782222 5.59533C0.782222 5.60756 0.782222 5.62344 0.782222 5.63933C0.782222 7.58756 2.17189 9.20578 3.99422 9.57856C3.66789 9.66778 3.31222 9.71056 2.94311 9.71056C2.68644 9.71056 2.42733 9.69589 2.18411 9.64211C2.70356 11.2298 4.17756 12.397 5.93022 12.4349C4.56622 13.5019 2.83433 14.1448 0.959444 14.1448C0.630667 14.1448 0.315333 14.1301 0 14.0898C1.77589 15.235 3.88056 15.8889 6.15022 15.8889C13.5276 15.8889 17.5609 9.77778 17.5609 4.48067C17.5609 4.30344 17.5548 4.13233 17.5462 3.96244C18.3419 3.39778 19.0104 2.69256 19.5556 1.881Z"
              fill="#343439"
            />
          </svg>
        </a>
        <a>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5 0H11C13.761 0 16 2.239 16 5V11C16 13.761 13.761 16 11 16H5C2.239 16 0 13.761 0 11V5C0 2.239 2.239 0 5 0ZM11 14.5C12.93 14.5 14.5 12.93 14.5 11V5C14.5 3.07 12.93 1.5 11 1.5H5C3.07 1.5 1.5 3.07 1.5 5V11C1.5 12.93 3.07 14.5 5 14.5H11Z"
              fill="#343439"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4 8C4 5.791 5.791 4 8 4C10.209 4 12 5.791 12 8C12 10.209 10.209 12 8 12C5.791 12 4 10.209 4 8ZM5.5 8C5.5 9.378 6.622 10.5 8 10.5C9.378 10.5 10.5 9.378 10.5 8C10.5 6.621 9.378 5.5 8 5.5C6.622 5.5 5.5 6.621 5.5 8Z"
              fill="#343439"
            />
            <circle cx="12.3" cy="3.69999" r="0.533" fill="#343439" />
          </svg>
          </a> 
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import Json from '@/Data/Plan.json'
export default {
  data() {
    return {
      stack: Json,
      careers: [
        {
          id: 2,
          title: "Take Personality Test",
          link: "/test",
          auth: false
        },
        {
          id: 3,
          title: "Course Curriculum",
          link: "/curriculum",
          auth: true
        },
        {
          id: 4,
          title: "Tech Personality",
          link: "/techpersonality",
          auth: false
        },
        {
          id: 5,
          title: "Bootcamps",
          link: "/bootcamp",
          auth: false
        },
        {
          id: 6,
          title: "Career Paths",
          link: "/careerpath",
          auth: false
        },
        {
          id: 7,
          title: "Career Road Map",
          auth: false
        },
        {
          id: 8,
          title: "Go Premium!",
          link: "/premium",
          auth: false
        },
      ],
      companies: [
        {
          id: 1,
          title: "About Us",
          link: "/about",
          auth: false
        },
        {
          id: 2,
          title: "Support Chat",
          auth: false
        },
        {
          id: 3,
          title: "We are Hiring!",
          auth: false
        },
        {
          id: 4,
          title: "Mentors",
          link: "/guardian",
          auth: false
        },
        {
          id: 6,
          title: "Tech Community",
          link: "/community",
          auth: false
        },
        {
          id: 6,
          title: "Privacy",
          auth: false
        },
        {
          id: 7,
          title: "Blog",
          link: "/blog",
          auth: true
        },
      ],
    };
  },
  methods: {
    handleMove(auth, link) {
      if(auth === true) {
        this.$store.state.AccessState = 2
      } else {
        this.$router.push(link)
      }
    }
  }
};
</script>

<style lang="scss" scoped >
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.footer {
  padding: 5rem 7% 0rem 7%;
}
.footer--logo {
  display: flex;
  grid-gap: 0.2rem;

  h4 {
    @include body {
      color: $secondary;
      font-family: $karla;
    }
    align-self: center;
  }
}
.footer--box {
  @include flex(space-between, flex-start);
}
.footer--list {
  width: 75%;
  @include flex(space-between, flex-start);
  ul {
    h4 {
      margin: 0 0 1.5rem 0;
      @include body {
        color: $secondary;
        font-family: $karla;
      }
    }
    li {
      margin: 0 0 1.5rem 0;
      @include caption {
        color: $black-50;
        font-family: $black-50;
        cursor: pointer;
      }
    }
  }
}
.footer--social {
  @include flex(space-between, center);
  padding: 2rem 0;
  .footer--social--hide {
    visibility: hidden;
  }

  .footer--social--copyright {
    h4 {
      @include caption {
        font-family: $karla;
        color: $gray-100;
      }
    }
  }
  .footer--social--followUs {
    p {
      margin: 0 0 1rem 0;
      align-self: flex-start;
      @include caption {
        color: $secondary-80;
        font-family: $karla;
      }
    }
    .footer--social--followUs--media {
      display: flex;
      grid-gap: 2rem;
    }
  }
}
@include md {
  .footer--logo {

  h4 {
    @include body {
      font-size: 1.5rem;
    }
    /* align-self: center; */
  }
}
  .footer--box {
    flex-direction: column;
    gap: 3rem;
  }
  .footer--list {
  width: 100%;
  flex-wrap: wrap;
  }
  .footer--social {
    flex-direction: column-reverse;
    gap: 1.5rem;
    .footer--social--copyright {
    h4 {
      @include caption {
        font-size: 0.8rem
      }
    }
  }
  .footer--social--followUs {
    p {
      margin: 0 0 1rem 0;
      @include flex(center, center);
      @include caption {
        font-size: 0.8rem;
      }
    }
    .footer--social--followUs--media {
      display: flex;
      grid-gap: 2rem;
    }
  }
  }
}
</style>