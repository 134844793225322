<template>
  <!-- <div class="featured--cards">
    <div class="featured--card">
      <div class="featured--card--image">
        <img :src="logo" alt="logo" width="100" height="100" />
      </div>
      <a :href="`/bootcamp/${id}`">
        <div class="featured--card--text">
          <h2>{{ company }}</h2>
          <h3>
            {{ courses }}
          </h3>
          <div class="featured--card--text--star">
            <div class="icon__container">
              <div
                class="icon"
                :class="{ icon__active: stars >= rate.id }"
                v-for="rate in rating"
                :key="rate.id"
                v-html="rate.icon"
              ></div>
              <p>{{ stars }} Rating</p>
            </div>
            <div class="featured--card--text--star--reviews">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 14L14.883 14.007C14.6598 14.0333 14.4519 14.1341 14.293 14.293C14.1341 14.4519 14.0333 14.6598 14.007 14.883L14 15V21H3.998C3.73402 21.0005 3.48062 20.8962 3.29349 20.71C3.10636 20.5239 3.00079 20.271 3 20.007V3.993C3 3.445 3.445 3 3.993 3H20.007C20.555 3 21 3.447 21 3.999V14H15ZM21 16L16 20.997V16H21Z"
                  fill="#A44A65"
                />
              </svg>
              <p>{{ review }} Reviews</p>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div> -->
  <div class="cards" @click="$router.push(`/bootcamp/${id}`)">
    <img :src="logo" alt="logo" width="100" height="100" />
    <div class="cards__text">
      <h2>{{ company }}</h2>
      <h3>{{ courses }}</h3>
      <div class="cards__review">
        <div class="icon__container">
            <div class="icon__container__star">
                <div
            class="icon"
            :class="{ icon__active: stars >= rate.id }"
            v-for="rate in rating"
            :key="rate.id"
            v-html="rate.icon"
          ></div>
            </div>
          <p>{{ stars }} Rating</p>
        </div>
        <div class="cards__review__count">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 14L14.883 14.007C14.6598 14.0333 14.4519 14.1341 14.293 14.293C14.1341 14.4519 14.0333 14.6598 14.007 14.883L14 15V21H3.998C3.73402 21.0005 3.48062 20.8962 3.29349 20.71C3.10636 20.5239 3.00079 20.271 3 20.007V3.993C3 3.445 3.445 3 3.993 3H20.007C20.555 3 21 3.447 21 3.999V14H15ZM21 16L16 20.997V16H21Z"
              fill="#A44A65"
            />
          </svg>
          <p>{{ review }} Reviews</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
export default {
  props: {
    company: {
      required: true,
      type: String,
    },
    courses: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: Number,
    },
    review: {
      required: true,
      type: Number,
    },
    logo: {
      required: true,
    },
    stars: {
      required: true,
    },
  },
  setup() {
    const rating = ref([
      {
        id: 1,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`,
      },
      {
        id: 2,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`,
      },
      {
        id: 3,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`,
      },
      {
        id: 4,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`,
      },
      {
        id: 5,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`,
      },
    ]);
    return { rating };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.cards {
    @extend %flex-ac;
    gap: 2rem;
    width: 100%;
    background-color: #8f3d55;
    min-height: 196px;
    border-radius: 8px;
    margin: 1.5rem 0;
    img {
        margin: 0 0 0 2rem;
        width: 100px;
        height: 100px;
        @media screen and (max-width: 430px) {
          width: 60px;
          height: 60px;
        }
    }
    &__text {
        background-color: #F9F2EE;
        min-height: 196px;
        width: 100%;
        padding: 2rem;
        text-align: left;
        h2 {
            font-family: 'Karla', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: Max(1.5rem, 16px);
            line-height: 120%;
            letter-spacing: -0.055em;
            color: $black-100;
            @media screen and (max-width: 430px) {
                font-size: 15px;
            }
        }
        h3 {
            font-family: 'Karla', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: Max(1.5rem, 16px);
            line-height: 120%;
            letter-spacing: -0.03em;
            color: $black;
             //width: fit-content;
             overflow: hidden;
             text-overflow: ellipsis;
             //white-space: wrap;
             display: -webkit-box;
             -webkit-line-clamp: 2;
             -webkit-box-orient: vertical;
            @media screen and (max-width: 500px) {
                font-size: 15px;
                //width: fit-content;
                overflow: hidden;
                text-overflow: ellipsis;
                //white-space: wrap;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
    &__review {
        @extend %flex-ac-jb;
        flex-wrap: wrap;
        margin-top: 1.5rem;
        &__count {
            @extend %flex-ac;
            gap: 0.4rem;
            @media screen and (max-width: 430px) {
                flex-direction: column;
               }
            p {
                margin-bottom: unset;
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: Max(1rem, 16px);
                line-height: Max(1.5rem, 24px);
                letter-spacing: -0.0016em;
                color: $black-100;
            }
        }
    }
}
::v-deep .icon {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  svg {
    margin-bottom: 0.3rem;
    cursor: pointer;
    path {
      // fill: #FFC100;
      fill: #c4c4c4;
    }
  }
  &__active {
    svg {
      path {
        fill: #ffc100;
      }
    }
  }
}
.icon {
   
  &__container {
    @extend %flex-ac-jc;
    gap: 0.3rem;
    @media screen and (max-width: 430px) {
        flex-direction: column;
       }
       &__star {
        @extend %flex-ac-jc;
    gap: 0.3rem;
       }
    p {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: Max(1rem, 16px);
      line-height: Max(1.5rem, 24px);
      letter-spacing: -0.0016em;
      color: $black-100;
      margin-bottom: unset;
    }
  }
}
.featured--card {
  @include flex(flex-start, center);
  height: 196px;
  margin: 1rem 0;
  a {
    width: 100%;
  }
  .featured--card--image {
    width: 176px;
    min-height: 196px;
    background: #8f3d55;
    border-radius: 8px 0px 0px 8px;
    @include flex(center, center);
    img {
      object-fit: contain;
    }
  }
}
.featured--card--text {
  text-align: left;
  background: rgba(240, 222, 213, 0.4);
  min-height: 196px;
  border-radius: 0px 8px 8px 0px;
  padding: 2rem 0 0 2rem;
  h2 {
    @include body-bold {
      color: $black-100;
      font-family: $karla;
    }
  }
  h3 {
    width: 90%;
    
    @include body {
      color: $black;
      font-family: $karla;
    }
  }
}
.featured--card--text--star {
  @include flex(space-between, center);
  @media screen and (max-width: 430px) {
    flex-direction: column;
   }
  .featured--card--text--star--stars {
    @include flex(flex-start, center);
    gap: 0.2rem;
    p {
      align-self: center;
      margin: auto;
      @include label {
        color: $gray-100;
        font-family: $karla;
      }
    }
    svg {
      align-self: center;
      margin: auto;
    }
  }
  .featured--card--text--star--reviews {
    @include flex(flex-start, center);
    p {
      align-self: center;
      height: 24px;
      margin: auto 1rem auto 0;
      /* margin-right: 1rem; */
      @include caption {
        color: $black-100;
        font-family: $karla;
      }
    }
    svg {
      align-self: center;
      margin: auto;
    }
  }
}
@include md {
  .featured--card--text {
    h2 {
      @include body-bold {
        font-size: 1.2rem;
      }
    }
    h3 {
      width: 80%;
      @include body {
        font-size: 1.2rem;
      }
    }
  }
  .featured--card {
    @include flex(center, center);
    height: auto;
    margin: 1rem 0;
    .featured--card--image {
      width: 176px;
      height: 196px;
      background: #8f3d55;
      border-radius: 8px 0px 0px 8px;
      @include flex(center, center);
    }
  }
  .featured--card--text--star {
    @include flex(space-between, center);
    .featured--card--text--star--stars {
      @include flex(flex-start, center);
      gap: 0.2rem;
      p {
        align-self: center;
        margin: auto;
        @include label {
          color: $gray-100;
          font-family: $karla;
        }
      }
      svg {
        align-self: center;
        margin: auto;
      }
    }
    .featured--card--text--star--reviews {
      @include flex(flex-start, center);
      p {
        align-self: center;
        height: 14px;
        margin: auto 1rem auto 0;
        /* margin-right: 1rem; */
        @include label {
          color: $black-100;
          font-family: $karla;
        }
      }
      svg {
        align-self: center;
        margin: auto;
      }
    }
  }
}
@include xs {
  .featured--card--text--star {
    @include flex(space-between, center);
    gap: 0.5rem;
    .featured--card--text--star--stars {
      @include flex(flex-start, center);
      flex-direction: column;
      gap: 0.2rem;
      p {
        align-self: center;
        margin: auto;
        @include label {
          color: $gray-100;
          font-family: $karla;
        }
      }
      svg {
        align-self: center;
        margin: auto;
      }
    }
    .featured--card--text--star--reviews {
      @include flex(flex-start, flex-start);
      flex-direction: column;
      gap: 0.2rem;
      p {
        align-self: flex-start;
        text-align: center;
        height: 14px;
        margin: auto;
        /* margin-right: 1rem; */
        @include label {
          font-size: 0.7rem;
          font-family: $karla;
        }
      }
      svg {
        align-self: flex-start;
        text-align: left;
      }
    }
  }
  .featured--card--text {
    h2 {
      @include body-bold {
        font-size: 1rem;
      }
    }
    h3 {
      width: 80%;
      @include body {
        font-size: 0.8rem;
      }
      //height: 60%;
      //text-overflow: ellipsis;
      //overflow: hidden;
      //white-space: nowrap;
    }
  }
}
</style>
