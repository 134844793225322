<template>
  <DevOps />
</template>

<script>
import DevOps from '@/components/Courses/DevOps/DevOps.vue'
export default {
components: {
    DevOps
}
}
</script>

<style>

</style>