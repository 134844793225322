<template>
  <section class="test" @click.self="testDone">
    <div class="test__content" v-if="step === 1">
      <nav>
        <p @click="testDone">X</p>
      </nav>
      <div class="test__text">
        <h2>Take Personality Test</h2>
        <p>
          It should not take few minutes to conclude, with immediate result you
          will discover what tech career fits you.
        </p>
      </div>
      <div class="test__questions">
        <h3>Question {{ testCount }} of 5</h3>
        <div class="test__questions__list" v-if="testCount === 1">
          <span v-for="item in questions" :key="item.id">
            <input type="checkbox" name="" id="" />
            <p>{{ item.id }} {{ item.content }}</p>
          </span>
        </div>
        <div class="test__questions__list" v-if="testCount === 2">
          <span v-for="item in questions" :key="item.id">
            <input type="checkbox" name="" id="" />
            <p>{{ item.id }} {{ item.content }}</p>
          </span>
        </div>
        <div class="test__questions__list" v-if="testCount === 3">
          <span v-for="item in questions" :key="item.id">
            <input type="checkbox" name="" id="" />
            <p>{{ item.id }} {{ item.content }}</p>
          </span>
        </div>
        <div class="test__questions__list" v-if="testCount === 4">
          <span v-for="item in questions" :key="item.id">
            <input type="checkbox" name="" id="" />
            <p>{{ item.id }} {{ item.content }}</p>
          </span>
        </div>
        <div class="test__questions__list" v-if="testCount === 5">
          <span v-for="item in questions" :key="item.id">
            <input type="checkbox" name="" id="" />
            <p>{{ item.id }} {{ item.content }}</p>
          </span>
        </div>
        <button @click="handleSubmit"> {{ testCount < 5 ? "Submit" : "Done" }} </button>
        <div class="test__bar">
          <div
            class="test__bar__inner"
            v-bind:style="{ width: value + '%' }"
          ></div>
        </div>
      </div>
    </div>
    <div class="test__answer" v-if="step === 2">
      <h3>Personality Test Result</h3>
      <h6>Hello Raymond,</h6>
      <p>
        Your personality test shows that you’re a User Advocate. You possess
        some of the following qualities which further explain your test result:
      </p>
      <p>
        Empathy, visualization, anticipation of behavior and communication are
        your underlying strengths. You like to create maps of processes and
        imagine where the pain points are. You make a difference by walking in
        others’ shoes, looking at problems from multiple angles and seeing the
        long-term consequences of today’s actions. Things you’re into:
        storytelling, exploring, listening and sharing.
      </p>
      <button @click="testDone">Done</button>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps(["test"]);
const emit = defineEmits(["closeTest"]);
const testCount = ref(1);
const step = ref(1);
const questions = ref([
  {
    id: "a.",
    content:
      "Even the all-powerful Pointing has no control about the blind texts",
  },
  {
    id: "b.",
    content:
      "Even the all-powerful Pointing has no control about the blind texts",
  },
  {
    id: "c.",
    content:
      "Even the all-powerful Pointing has no control about the blind texts",
  },
  {
    id: "d.",
    content:
      "Even the all-powerful Pointing has no control about the blind texts",
  },
  {
    id: "e.",
    content:
      "Even the all-powerful Pointing has no control about the blind texts",
  },
]);
const value = ref(0);
const check = ref([]);
const handleSubmit = () => {
  // to check if you have completed the whole test so you can move to the result page
  if (testCount.value === 5) {
    testCount.value = 5;
    step.value = 2
  } else {
    // increase the number you are in in the test
    testCount.value++;
    // returns the check state to original
    check.value = [];
  }
  // to check and increase the percentage the percentage
  const percent = 100 / 5;
  value.value = value.value + percent;
};
</script>

<style lang="scss" scoped>
.test {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(95, 94, 94, 0.5);
  top: 0;
  left: 0;
  z-index: 4;
  @extend %flex-ac-jc;
  @media screen and (max-width: 400px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__content {
    width: Min(90%, 1300px);
    padding: 2rem 1.75rem;
    background-color: $white;
    background-image: url("@/components/Personality/PersonalityTest/First/bg.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    text-align: center;
    @media screen and (max-width: 400px) {
      right: unset;
    }
    @include respondMax("tablet") {
      height: 400px;
      overflow: scroll;
      scroll-behavior: none;
      &::-webkit-scrollbar {
        display: none;
        appearance: none;
      }
    }
    nav {
      p {
        text-align: right;
        font-family: 'Karla', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: Max(1rem, 16px);
        line-height: Max(1, 16px);
        letter-spacing: 0.015em;
        color: $black;
        cursor: pointer;
      }
    }
  }
  &__text {
    width: Min(550px, 100%);
    margin: 1rem auto;
    text-align: center;
    h2 {
      font-family: "Karla", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: Max(3rem, 24px);
      line-height: Max(3rem, 24px);
      letter-spacing: -0.035em;
      color: $secondary-80;
    }
    p {
      font-family: "Karla", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: Max(1.24rem, 18px);
      line-height: 135%;
      text-align: center;
      letter-spacing: -0.015em;
      color: $black-50;
    }
  }
  &__questions {
    text-align: center;
    h3 {
      font-family: "Karla", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: Max(1.875rem, 24px);
      line-height: 120%;
      letter-spacing: -0.045em;
      color: $secondary-80;
    }
    button {
      background: $secondary-80;
      border-radius: Max(2.313rem, 37px);
      padding: 8px 24px;
      height: Max(2.625rem, 42px);
      font-family: "Karla", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: Max(1.125rem, 18px);
      line-height: 120%;
      letter-spacing: 0.085em;
      text-transform: uppercase;
      color: $white;
      margin: 1.5rem auto;
    }
    &__list {
      span {
        @extend %flex-ac-jc;
        gap: 0.75rem;
        margin: 0.75rem auto;
        @include respondMax("mobile3x") {
          align-items: flex-start;
        }
        p {
          font-family: "Karla", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: Max(1.125rem, 18px);
          line-height: 135%;
          letter-spacing: -0.015em;
          color: $black;
          @extend %flex-ac;
          gap: 1rem;
          margin-bottom: unset;
          margin-top: unset;
        }
      }
    }
  }
  &__bar {
    width: Min(100%, 621px);
    height: 8px;
    background: $primary;
    border-radius: 20px;
    margin: 0 auto;
    position: relative;
    &__inner {
      height: 8px;
      position: absolute;
      left: 0;
      top: 0;
      background-color: $secondary-80;
      border-radius: 20px;
    }
  }
  &__answer {
    width: Min(90%, 951px);
    height: 803px;
    padding: 2rem 10%;
    margin-top: 10vh;
    background-color: $white;
    background-image: url("@/components/Personality/PersonalityTest/Results/bg.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    @media screen and (max-width: 400px) {
      right: unset;
    }
    nav {
      p {
        text-align: right;
        font-family: 'Karla', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: Max(1rem, 16px);
        line-height: Max(1, 16px);
        letter-spacing: 0.015em;
        color: $black;
        cursor: pointer;
      }
    }
    h3 {
      font-family: "Karla", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: Max(3rem, 24px);
      line-height: Max(3rem, 24px);
      letter-spacing: -0.035em;
      text-align: center;
      color: $black-50;
      margin-bottom: 5rem;
    }
    h6 {
      font-family: 'Karla', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: Max(1.875rem, 30px);
      line-height: 120%;
      letter-spacing: -0.045em;
      color: $secondary;
    }
    p {
      font-family: 'Karla', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: Max(1.5rem, 24px);
      line-height: 120%;
      letter-spacing: -0.03em;
      color: $black-50;
      margin: 0.75rem 0;
    }
    button {
      background: $secondary-80;
      border-radius: 10px;
      padding: 8px 24px;
      height: Max(2.625rem, 42px);
      font-family: "Karla", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: Max(1.125rem, 18px);
      line-height: 120%;
      letter-spacing: 0.085em;
      text-transform: uppercase;
      color: $white;
      margin: 1.5rem auto;
      @extend %flex-ac-jc;
    }
  }
}
</style>
