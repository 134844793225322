import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import HomeView from '../views/HomeView.vue';
import FrontendView from '@/views/course/FrontendView.vue';
import BackendView from '@/views/course/BackendView.vue';
import DevOpsView from '@/views/course/DevOpsView.vue';
import DataScienceView from '@/views/course/DataScienceView.vue';
import DesignView from '@/views/course/DesignView.vue';
import CareerPathView from '@/views/CareerPathView.vue';
import AboutView from '@/views/AboutView.vue';
import ProfileView from '@/views/personality/ProfileView';
import TestView from '@/views/personality/TestView';
import PremiumView from '@/views/PremiumView.vue';
import CurriculumView from '@/views/CurriculumView.vue';
import ProfileDetails from '@/views/ProfileDetails.vue';
import BlogView from '@/views/BlogView.vue';
import ArticleView from '@/views/ArticleView.vue';
import BootcampView from '@/views/Bootcamps/BootcampView.vue';
import MentorsView from '@/views/Mentors/MentorsView.vue';
import MentorDetailsView from '@/views/Mentors/MentorDetailsView.vue';
import BootcampDetails from '@/components/Bootcamp/Detail/Detail.vue';
import BootcampCompareView from '@/views/Bootcamps/BootcampCompareView.vue';
import BootcampCompareDetails from '@/views/Bootcamps/BootcampCompareDetails.vue';
import BootcampFormView from '@/views/Bootcamps/BootcampFormView.vue';
import Community from "@/views/Community.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ "@/components/Home/Main.vue"),
    meta: { guest: true }
  },
  {
    path: '/frontend',
    name: 'frontend',
    component: FrontendView,
  },
  {
    path: '/backend',
    name: 'backend',
    component: BackendView,
  },
  {
    path: '/devops',
    name: 'devops',
    component: DevOpsView,
  },
  {
    path: '/datascience',
    name: 'datascience',
    component: DataScienceView,
  },
  {
    path: '/design',
    name: 'design',
    component: DesignView,
  },
  {
    path: '/product-management',
    name: 'Product Management',
    component: () => import(/* webpackChunkName: "details" */ '@/components/Courses/Management/Management.vue'),
  },
  {
    path: '/data-engineering',
    name: 'Data Engineering',
    component: () => import(/* webpackChunkName: "details" */ '@/components/Courses/Engineering/Engineering.vue'),
  },
  {
    path: '/careerpath',
    name: 'careerpath',
    component: CareerPathView,
    meta: { guest: true }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: { guest: true }
  },
  {
    path: '/techpersonality',
    name: 'techpersonality',
    component: () => import(/* webpackChunkName: "details" */ '@/components/Personality/Details/Detail.vue'),
  },
  {
    path: '/techpersonality/analyst',
    name: 'Analyst Personality',
    component: () => import(/* webpackChunkName: "details" */ '@/views/Analyst.vue')
  },
  {
    path: '/techpersonality/useradvocate',
    name: 'Advocate Personality',
    component: () => import(/* webpackChunkName: "details" */ '@/views/ProfileDetails.vue')
  },
  {
    path: '/techpersonality/logician',
    name: 'Logician Personality',
    component: () => import(/* webpackChunkName: "details" */ '@/views/Logician.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: TestView,
    meta: {guest: true}
  },
  {
    path: '/premium',
    name: 'premium',
    component: PremiumView,
    meta: { guest: true }
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogView,
    //meta: { guest: true }
  },
  {
    path: '/blog/:id',
    name: 'article',
    component: ArticleView,
    //meta: { guest: true },
    props: true
  },
  {
    path: '/bootcamp',
    name: 'bootcamp',
    component: BootcampView,
    meta: { guest: true }
  },
  {
    path: '/mentors',
    name: 'guard',
    component: MentorsView,
    meta: { guest: true }
  },
  {
    path: '/mentordetails',
    name: 'mentordetails',
    component: MentorDetailsView,
    meta: { guest: true }
  },
  {
    path: '/bootcamp/:id',
    name: 'bootcampdetails',
    component: BootcampDetails,
    props: true,
    // meta: {requiresAuth: true}
  },
  {
    path: '/bootcampcompare',
    name: 'bootcampcompare',
    component: () => import(/* webpackChunkName: "about" */ "@/components/Bootcamp/Compare/Main.vue")
   //meta: { guest: true }
  },
  {
    path: '/bootcampcompare/details',
    name: 'bootcampcomparedetails',
    component: BootcampCompareDetails,
    //meta: { guest: true }
  },
  {
    path: '/community',
    name: 'community',
    component: Community,
    meta: { guest: true }
  },
  {
    path: '/bootcampform',
    name: 'bootcampform',
    component: BootcampFormView,
    meta: { guest: true }
  },
  {
    path: '/internship',
    name: 'Internship',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/InternshipView.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/post',
    name: 'Submit Project',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/WriteView.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/career-guide',
    name: 'career guide',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/Guide.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/resources',
    name: 'My Resources',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/MyResources.vue'),
    meta: {requiresAuth: true}
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component:  () => import(/* webpackChunkName: "about" */ '@/views/Login.vue')
  // },
  // {
  //   path: '/reset',
  //   name: 'Reset',
  //   component:  () => import(/* webpackChunkName: "about" */ '@/views/Reset.vue')
  // },
  // {
  //   path: '/new-password',
  //   name: 'New Password',
  //   component:  () => import(/* webpackChunkName: "about" */ '@/views/NewPassword.vue')
  // },
  {
    path: '/change-password',
    name: 'Change Password',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/ChangePassword.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/basic-profile',
    name: 'Basic Profile',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/BasicProfile.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/guardian',
    name: 'Mentors',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/Guardian.vue'),
  },
  {
    path: '/guardian/:id',
    name: 'Guardian Details',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/GuardianDetails.vue'),
    props: true,
    //meta: {requiresAuth: true}
  },
  {
    path: '/interview-questions',
    name: 'Interview Question',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/InterviewList.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/interview-questions/:id',
    name: 'Interview Answer',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/InterviewAnswer.vue'),
    props: true,
    meta: {requiresAuth: true}
  },
  {
    path: '/project',
    name: 'Projects',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/Project.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/mybootcamps',
    name: 'My Bootcamp',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/MyBootcamp.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/uploaded',
    name: 'Uploaded Resources',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/Uploaded.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/uploaded/:id',
    name: 'Uploaded Video',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/Video.vue'),
    meta: {requiresAuth: true},
    props: true
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/Dashboard.vue'),
    meta: {requiresAuth: true}
  },
  // {
  //   path: '/chat',
  //   name: 'Chat',
  //   component:  () => import(/* webpackChunkName: "about" */ '@/views/Chat.vue'),
  //   meta: {requiresAuth: true}
  // },
  {
    path: '/help',
    name: 'Get Help',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/GetHelp.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/settings',
    name: 'Setting',
    component:  () => import(/* webpackChunkName: "about" */ '@/views/Settings.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/curriculum',
    name: 'Curriculum',
    component:  () => import(/* webpackChunkName: "about" */ '@/components/Resources/Curriculum.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/curriculum/data-science',
    name: 'Data Scientist',
    component:  () => import(/* webpackChunkName: "about" */ '@/components/Curr/DataScience/index.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/curriculum/backend',
    name: 'BackEnd Development',
    component:  () => import(/* webpackChunkName: "about" */ '@/components/Curr/Backend/index.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/curriculum/frontend',
    name: 'FrondEnd Development',
    component:  () => import(/* webpackChunkName: "about" */ '@/components/Curr/Frontend/index.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/curriculum/product-management',
    name: 'Product Management',
    component:  () => import(/* webpackChunkName: "about" */ '@/components/Curr/Management/index.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/curriculum/design',
    name: 'Product Design',
    component: CurriculumView,
    meta: {requiresAuth: true}
  },
  {
    path: "/roadmaps",
    name: "Roadmap",
    component: () => import(/* webpackChunkName: "python" */ "@/components/Roadmaps/Home.vue"),
  },
  {
    path: "/roadmaps/python",
    name: "Python",
    component: () => import(/* webpackChunkName: "python" */ "@/components/Roadmaps/Python.vue"),
  },
  {
    path: "/roadmaps/vue",
    name: "Vue roadmap",
    component: () => import(/* webpackChunkName: "python" */ "@/components/Roadmaps/Vue.vue"),
  },
  {
    path: "/roadmaps/java",
    name: "Java roadmap",
    component: () => import(/* webpackChunkName: "python" */ "@/components/Roadmaps/Java.vue"),
  },
  {
    path: "/beginners",
    name: "Moodboard Beginner",
    component: () => import(/* webpackChunkName: "python" */ "@/components/Resources/Beginners.vue"),
    meta: {requiresAuth: true}
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

// handling unauthorized user

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (window.sessionStorage.getItem("user") === "authenticated") {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

// handling authorized user

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (window.sessionStorage.getItem("user") === "authenticated") {
      next("/dashboard");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router
