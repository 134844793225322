<template>
  <section class="intro">
    <div class="intro--box">
      <div class="intro--text">
        <h1>UI/UX DESIGN</h1>
        <p>
          UI (User Interface) and UX (User Experience) design are two related
          but distinct disciplines that are critical components of creating
          effective and engaging digital products, including websites, mobile
          apps, and other digital interfaces.
        </p>

        <p>
          UI design focuses on the visual aspects of the user interface,
          including the layout, typography, color scheme, and overall aesthetic
          design. The UI designer's goal is to create a visually appealing and
          intuitive interface that users can easily navigate and interact with.
        </p>

        <p>
          UX design, on the other hand, focuses on the overall user experience,
          including the usability, accessibility, and functionality of the
          interface. The UX designer's goal is to create an interface that is
          easy to use, enjoyable to interact with, and meets the
          needs of the user.
        </p>
        <button @click="handleMove" class="btn--course">View Curriculum</button>
        <div class="intro--text--figures">
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.mentors}}</h1>
            <p>
              <router-link to="/mentors"> MENTORS </router-link>
            </p>
          </div>
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.bootcamps}}</h1>
            <p>
              <router-link to="/bootcamp"> BOOTCAMPS </router-link>
            </p>
          </div>
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.projects}}</h1>
            <p>PROJECT</p>
          </div>
        </div>
      </div>
      <div class="intro--image">
        <img
          src="@/assets/Courses/Design/pana.png"
          alt=""
          height="590"
          width="932"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore, mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router"
const router = useRouter
const store = useStore();
const active = ref(true)
const metric = ref()
mapActions(["Metric"])
onMounted(async () => {
  await store.dispatch("Metric")
  metric.value = store.getters.StateMetric
  setInterval(() => {
      active.value = active.value;
    }, 6000);
})
const handleMove = () => {
  if(window.sessionStorage.getItem("user") != null) {
        router.push("/curriculum")
      } else {
        store.state.AccessState = 2
      }
}
</script>

<style lang="scss" scoped>
@import "@/styles/Courses/intro.scss";
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.intro {
  height: auto;
  &--image {
    img {
      object-fit: contain;
      width: 100%;
      height: auto;
    }
  }
}
.intro--box {
  @include flex(space-around, center);
  flex-direction: column;
  grid-gap: 5rem;
}
@include xl {
  .intro {
    height: auto;
  }
  .intro--box {
    @include flex(center, center);
    align-content: center;
    flex-direction: column;
    grid-gap: 2rem;

    .intro--text {
      text-align: center;
      max-width: 100%;
      width: min(90%, 690px);
    }
  }
}
@include lg {
  .intro {
    height: auto;
  }
}
@include md {
  .intro {
    height: auto;
  }
}
@include sm {
  .intro {
    height: auto;
  }
}
@include xs {
  .intro {
    height: auto;
  }
}
</style>
