<template>
  <section class="details">
        <div class="intro--button">
            <button class="button--filter" @click="openFilter">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.571 20.3998L5.82812 7.2854V4.37109H29.1426V7.2854L20.3997 20.3998V29.1427L14.571 32.057V20.3998Z" fill="#8F3D55"/>
</svg>
<p>
    Filter Search
</p>
</button>
<div  v-if="active" class="intro--filter">
    <h3>
        Filter by mentors
    </h3>
    <ul>
        
        <li><input type="checkbox" v-model="filter" value="Software Developer" id=""><p>Software Developer</p></li>
        <li><input type="checkbox" v-model="filter" value="Data Scientist" id=""><p>Data Scientist</p></li>
        <li><input type="checkbox" v-model="filter" value="UI/UX Designer" id=""><p>UI/UX Designer</p></li>
        <li><input type="checkbox" v-model="filter" value="Blockchain Developer" id=""><p>Blockchain Developer</p></li>
        <li><input type="checkbox" v-model="filter" value="DevOps" id=""><p>DevOps</p></li>
        <li><input type="checkbox" v-model="filter" value="Graphics Designer" id=""><p>Graphics Designer</p></li>
</ul>
<div class="btn">
<button @click="closeFilter" class="btn--course">
    Filter
</button>
</div>

</div>
        </div>
      <div class="details--box">
          <div class="details--cards">
              <div class="details--cards--card" v-for="mentor in mentors" :key="mentor.id">
                  <div class="details--cards--card--intro">
                      <router-link to="/mentordetails">
                      <div class="details--cards--card--intro--split">
                      <div class="details--cards--card--intro--image">
                          <img src="@/assets/seyi.png" alt="">
                      </div>
                      <div class="details--cards--card--intro--text">
                          <h3>
                              {{mentor.name}}
                          </h3>
                          <p>
                             {{mentor.role}}
                          </p>
                          <div class="details--cards--card--intro--text--location">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 26.1249L21.1875 19.9374C22.4111 18.7137 23.2444 17.1546 23.5819 15.4572C23.9195 13.7599 23.7462 12.0006 23.0839 10.4018C22.4216 8.80305 21.3001 7.43654 19.8612 6.47511C18.4222 5.51369 16.7306 5.00053 15 5.00053C13.2695 5.00053 11.5778 5.51369 10.1389 6.47511C8.69995 7.43654 7.57844 8.80305 6.91614 10.4018C6.25385 12.0006 6.08052 13.7599 6.41807 15.4572C6.75562 17.1546 7.58888 18.7137 8.8125 19.9374L15 26.1249ZM15 29.6599L7.04501 21.7049C5.47168 20.1316 4.40023 18.127 3.96616 15.9447C3.53209 13.7624 3.75488 11.5004 4.60637 9.44478C5.45786 7.38912 6.8998 5.63212 8.74985 4.39596C10.5999 3.1598 12.775 2.5 15 2.5C17.225 2.5 19.4001 3.1598 21.2502 4.39596C23.1002 5.63212 24.5421 7.38912 25.3936 9.44478C26.2451 11.5004 26.4679 13.7624 26.0338 15.9447C25.5998 18.127 24.5283 20.1316 22.955 21.7049L15 29.6599ZM15 16.2499C15.663 16.2499 16.2989 15.9865 16.7678 15.5177C17.2366 15.0488 17.5 14.4129 17.5 13.7499C17.5 13.0869 17.2366 12.451 16.7678 11.9821C16.2989 11.5133 15.663 11.2499 15 11.2499C14.337 11.2499 13.7011 11.5133 13.2322 11.9821C12.7634 12.451 12.5 13.0869 12.5 13.7499C12.5 14.4129 12.7634 15.0488 13.2322 15.5177C13.7011 15.9865 14.337 16.2499 15 16.2499ZM15 18.7499C13.6739 18.7499 12.4022 18.2231 11.4645 17.2854C10.5268 16.3478 10 15.076 10 13.7499C10 12.4238 10.5268 11.1521 11.4645 10.2144C12.4022 9.27669 13.6739 8.7499 15 8.7499C16.3261 8.7499 17.5979 9.27669 18.5355 10.2144C19.4732 11.1521 20 12.4238 20 13.7499C20 15.076 19.4732 16.3478 18.5355 17.2854C17.5979 18.2231 16.3261 18.7499 15 18.7499Z" fill="#8F3D55"/>
                             </svg>
                              <p>
                                {{mentor.location}}
                             </p>
                          </div>
                      </div>
                      </div>
                      <div class="details--cards--card--intro--content">
                          <p>
                              front-end developer with expertise in React, architecting projects by bringing in the best tools,
                               implementing best practices, and making apps scalable and maintainable in the long run                    .....Read more
                          </p>
                      </div>
                      <div class="details--cards--card--intro--link">
                          <h4>
                              <router-link to="/mentordetails">View full Profile</router-link>
                          </h4>
                      </div>
                      </router-link>
                  </div>
                  <div class="details--cards--card--stacks">
                      <div class="details--cards--card--stacks--text">
                          <h5>
                              Stacks
                          </h5>
                      </div>
                      <div class="details--cards--card--stacks--stack">
                          <button class="button--stacks">HTML</button>
                          <button class="button--stacks">CSS</button>
                          <button class="button--stacks">JavaScript</button>
                          <button class="button--stacks">React</button>
                          <button class="button--stacks">Tailwind</button>
                          <button class="button--stacks">Figma</button>
                          <button class="button--stacks">Interaction Design</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
    methods: {
        closeFilter() {
            this.active = false
        },
        openFilter() {
            this.active = !this.active
        }
    },
    data() {
        return {
            filter: [],
            active: false,
            mentors: [
                {
                    id: 1,
                    name: 'Oluwaseyi Babalola',
                    role: 'Software Developer/ UI Designer',
                    location: 'Yaba, Lagos. Nigeria.'
                },
                {
                    id: 2,
                    name: 'Oluwaseyi Babalola',
                    role: 'Software Developer/ UI Designer',
                    location: 'Yaba, Lagos. Nigeria.'
                },
                {
                    id: 3,
                    name: 'Oluwaseyi Babalola',
                    role: 'Software Developer/ UI Designer',
                    location: 'Yaba, Lagos. Nigeria.'
                },
                {
                    id: 4,
                    name: 'Oluwaseyi Babalola',
                    role: 'Software Developer/ UI Designer',
                    location: 'Yaba, Lagos. Nigeria.'
                },
                {
                    id: 5,
                    name: 'Oluwaseyi Babalola',
                    role: 'Software Developer/ UI Designer',
                    location: 'Yaba, Lagos. Nigeria.'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.intro--button {
        @include flex(flex-end, flex-start);
        /* position: relative;
        left: 1%; */
        button {
            background: transparent;
            border: none;
        }
        .intro--filter {
            position: absolute;
            top: 5rem;
            z-index: 3;
            width: 322px;
            height: 439px;
            border-radius: 20px;
            background-color: $white;
            text-align: center;
            padding: 2rem 2rem;
            h3 {
                @include body-bold {
                    color: $secondary-80;
                    font-family: $karla;
                }
            }
            ul {
                li {
                    display: flex;
                    gap: 0.4rem;
                    justify-content: left;
                    margin: 0.7rem 0;
                    input {
                        align-self: center;
                    }
                    p {
                        align-self: center;
                        margin: auto 0;
                    }
                }
            }
        }
    }
.details {
    padding: 0 7% 3rem 7%;
}
.details--card {
    margin-bottom: 2rem;
}
.details--cards--card {
    display: flex;
    justify-content: center;
    margin: 1rem 0 6rem 0;
}
.details--cards--card--intro {
    width: 707px;
    height: 440px;
    background: #F9F2EE;
    border-radius: 20px 0 0 20px;
    padding: 3rem 4%;
    .details--cards--card--intro--split {
        display: flex;
        gap: 3rem;
        .details--cards--card--intro--image {
            align-self: center;
            margin: auto 0;
        }
        .details--cards--card--intro--text {
            h3 {
                @include body-bold {
                    color: $black-100;
                    font-family: $karla;
                }
            }
            p {
                @include body {
                    color: $gray-100;
                    font-family: $karla;
                }
            }
            .details--cards--card--intro--text--location {
                display: flex;
                gap: 0.4rem;
                p {
                    @include button {
                    color: $black-50;
                    font-family: $karla;
                }
                }
            }
        }
    }
    .details--cards--card--intro--content {
            margin-top: 2.5rem;
            p {
                @include caption {
                    color: $black-100;
                    font-family: $karla;
                }
            }
        }
        .details--cards--card--intro--link {
            text-align: center;
            margin-top: 2rem;
            h4 {
                @include body-bold {
                    color: $secondary-80;
                    font-family: $karla;
                }
            }
        }
}
.details--cards--card--stacks {
    background: #DAADBB;
    border-radius: 0px 20px 20px 0px;
    width: 447px;
    height: 440px;
    padding: 3rem 4%;
    .details--cards--card--stacks--text {
        text-align: center;
        h5 {
            margin-bottom: 2rem;
            @include sub-heading-bold {
                color: $white;
                font-family: $karla;
            }
        }
    }
    .details--cards--card--stacks--stack {
        @include flex(flex-start, flex-start);
        gap: 2rem;
        flex-wrap: wrap;
        .button--stacks {
            background: #FFFFFF;
            border-radius: 37px;
            width: auto;
            height: 38px;
            padding: 8px 16px;
            margin: 0.4rem;
            border: none;
            @include flex(center,center);
             @include button {
                    color: $black-100;
                    font-family: $karla;
                }
                button { 
            margin: 0.4rem;
                }
        }   
    }
}
@include lg {
    .details--cards--card {
    flex-direction: column;
    margin: 1rem 0 4.5rem 0;
}
.details--cards--card--intro {
    width: min(90%, 707px);
    height: auto;
    padding: 3rem 4%;
    border-radius: 0px 0 0 0px;
    margin: 0 auto;
    .details--cards--card--intro--split {
        .details--cards--card--intro--text {
            h3 {
                @include body-bold {
                }
            }
            p {
                @include body {
                }
            }
            .details--cards--card--intro--text--location {
                p {
                    @include button {
                }
                }
            }
        }
    }
    .details--cards--card--intro--content {
            margin-top: 2.5rem;
            p {
                @include caption {
                    color: $black-100;
                    font-family: $karla;
                }
            }
        }
        .details--cards--card--intro--link {
            text-align: center;
            margin-top: 2rem;
            h4 {
                @include body-bold {
                }
            }
        }
}
.details--cards--card--stacks {
    background: #DAADBB;
    border-radius: 0px 0px 0px 0px;
    width: min(90%, 707px);
    margin: 0 auto;
    height: auto;
    padding: 3rem 4%;
    .details--cards--card--stacks--text {
        text-align: center;
        h5 {
            margin-bottom: 2rem;
            @include sub-heading-bold {
                color: $white;
                font-family: $karla;
            }
        }
    }
    .details--cards--card--stacks--stack {
        @include flex(flex-start, flex-start);
        gap: 2rem;
        flex-wrap: wrap;
        margin: 0 auto;
        .button--stacks {
            
        margin: 0 auto;
            background: #FFFFFF;
            border-radius: 37px;
            width: auto;
            height: 38px;
            padding: 8px 16px;
            @include flex(center,center);
             @include button {
                    color: $black-100;
                    font-family: $karla;
                }
        }   
    }
}
}
@include sm {
    .details--cards--card--intro {
    .details--cards--card--intro--split {
        flex-direction: column;
        .details--cards--card--intro--image {
            margin: 0.5 auto;
        }
        .details--cards--card--intro--text {
            text-align: center;
            margin: 0.5 auto;
            h3 {
                @include body-bold {
                }
            }
            p {
                @include body {
                }
            }
            .details--cards--card--intro--text--location {
                justify-content: center;
                p {
                    @include button {
                }
                }
            }
        }
    }
    .details--cards--card--intro--content {
            margin: 2.5rem auto 0 auto;
            text-align: center;
            p {
                @include caption {
                    color: $black-100;
                    font-family: $karla;
                }
            }
        }
        .details--cards--card--intro--link {
            text-align: center;
            margin-top: 2rem;
            h4 {
                @include body-bold {
                }
            }
        }
}
}
@include xs {
    .details--cards--card--intro {
    .details--cards--card--intro--split {
        flex-direction: column;
        .details--cards--card--intro--image {
            margin: 0.5 auto;
        }
        .details--cards--card--intro--text {
            text-align: center;
            margin: 0.5 auto;
            h3 {
                @include body-bold {
                    font-size: 1.2rem;
                }
            }
            p {
                @include body {
                    font-size: 1rem;
                }
            }
            .details--cards--card--intro--text--location {
                justify-content: center;
                p {
                    @include button {
                        font-size: 0.8rem;
                }
                }
            }
        }
    }
    .details--cards--card--intro--content {
            margin: 0.5rem auto 0 auto;
            text-align: center;
            p {
                @include caption {
                    font-size: 0.9rem;
                }
            }
        }
        .details--cards--card--intro--link {
            text-align: center;
            margin-top: 2rem;
            h4 {
                @include body-bold {
                    font-size: 1.2rem;
                }
            }
        }
}
}
</style>