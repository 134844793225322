<template>
  <section class="header">
  <nav>
    <div class="toggle" @click="handleMenu">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <span>
      <img src="@/assets/logo.png" width="90" height="69" alt="" />
      Zero to tech
    </span>
    <div class="search">
      <input type="text" placeholder="Find the best bootcamp for you" v-model="Name"  />
      <button @click="check">Search</button>
    </div>
    <img class="iconSearch" @click="showSearch  = !showSearch" src="@/assets/svg/search.svg" alt="">
    <div class="icons">
      <!-- <router-link to="/chat">
      <img src="@/assets/svg/chat.svg" alt="" />
    </router-link> -->
      <img src="@/assets/svg/notif.svg" alt="" @click="notif = true" />
      <img :src="$store.state.pfp" width="20" height="20" alt="" class="pfp" @click="head = true" />
    </div>
  </nav>
  <div class="showSearch" v-if="showSearch">
    <div class="header__search">
      <input type="text" placeholder="Find the best bootcamp for you" v-model="Name" />
      <button @click="check">Search</button>
    </div>
  </div>
  <Info :head="head" v-show="head" @closeToggle="close($event)" />
  <Notif :notif="notif" v-show="notif" @closeNotif="notsClose($event)" />
</section>
</template>

<script setup>
import {ref} from "@vue/reactivity"
import { onMounted } from "@vue/runtime-core"
import {useStore, mapActions, mapGetters } from "vuex"
import Info from './Info.vue'
import Notif from "./Notif.vue"
const head = ref(false)
const props = defineProps(["active", "bootcamps"])
const emit = defineEmits(["closeActive", "handleBootcamps"])
const store = useStore()
const handleMenu = () => {
  emit("closeActive", !props.active)
}
const close = (toggle) => {
head.value = toggle
}
const notif = ref(false)
const notsClose = (toggle) => {
notif.value = toggle
}
const showSearch = ref(false);
onMounted(async () => {
  window.addEventListener("resize", () => {
    if (window.innerWidth >= 769) {
      showSearch.value = false;
    }
  })
   
  await store.dispatch("User")
  store.state.pfp = store.getters.StateOwner.avatar
})
const avatar = ref()
const Name = ref("")
mapActions(["Search", "User"])
const check = async () => {
  await store.dispatch("Search", Name.value)
  emit("handleBootcamps", props.bootcamps = store.getters.SearchState)
  await store.dispatch("User")
  store.state.pfp = store.getters.StateOwner.avatar
}
if(Name.value.length === 0) {
  emit("handleBootcamps", props.bootcamps = null)
}
</script>

<style lang="scss" scoped>
.pfp {
  height: 20px;
  width: 20px;
  border-radius: 10px;
}
.header {
  position: relative;
  &__search {
    @extend %flex-ac;
    width: Max(442px, 27.625rem);
    height: Max(35px, 2.3125rem);
    @include respondMax('tablet') {
      width: 90%;
    }
    button {
        width: Max(4.375rem, 70px);
        height: 100%;
        background: $primary-80;
        border-radius: 0px 8px 8px 0px;
        font-family: 'Karla', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: Max(0.875rem, 14px);
        line-height: 120%;
        color: $white;
        border: none;
    }
    input {
        height: 100%;
        width: 100%;
        background: $white;
        border: 1px solid #E0E0E0;
        border-radius: 8px 0px 0px 8px;
        padding: 0.3rem;
        &::placeholder {
            text-align: center;
            color: $gray-20;
            font-family: 'Karla', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: Max(0.75rem, 12px);
        line-height: 135%;
        }
    }
  }
  .showSearch {
    margin: 1rem auto;
    @extend %flex-ac-jc;

  }
}
nav {
  @extend %flex-ac-jb;
  padding-right: 2rem;
  position: relative;
  @include respondMax('tablet2x') {
      padding: 1rem 5%;
    }
  .toggle {
    display: none;
    div {
      margin: 0.3rem 0;
      background-color: #E0E0E0;
      &:nth-child(1) {
        height: 1.5px;
        width: 20px;
      }
      &:nth-child(2) {
        height: 1.5px;
        width: 20px;
      }
      &:nth-child(3) {
        height: 1.5px;
        width: 15px;
      }
    }
    @include respondMax('tablet2x') {
      display: block;
    }
  }
  span {
    @extend %flex-ac;
    gap: 0.2rem;
    font-family: 'Karla', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: Max(0.875rem, 14px);
    line-height: 120%;
    letter-spacing: 0.085em;
    text-transform: uppercase;
    color: $secondary-80;
    @include respondMax('tablet2x') {
      display: none;
    }
  }
  .search {
    @extend %flex-ac;
    width: Max(442px, 27.625rem);
    height: Max(35px, 2.3125rem);
    @include respondMax('tablet') {
      display: none;
      width: 90%;
    }
    button {
        width: Max(4.375rem, 70px);
        height: 100%;
        background: $primary-80;
        border-radius: 0px 8px 8px 0px;
        font-family: 'Karla', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: Max(0.875rem, 14px);
        line-height: 120%;
        color: $white;
        border: none;
    }
    input {
        height: 100%;
        width: 100%;
        background: $white;
        border: 1px solid #E0E0E0;
        border-radius: 8px 0px 0px 8px;
        padding: 0.3rem;
        &::placeholder {
            text-align: center;
            color: $gray-20;
            font-family: 'Karla', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: Max(0.75rem, 12px);
        line-height: 135%;
        }
    }
  }
  .iconSearch {
    display: none;
    @include respondMax('tablet') {
      display: block;
    }
  }
  .icons {
    @extend %flex-ac;
    gap: 1rem;
    img {
      cursor: pointer;
    }
  }
}
</style>
