<template>
  <section class="login" @click.self="closeLogin">
    <div class="login__main">
        <nav>
          <p @click="closeLogin">X</p>
        </nav>
        <h2>Welcome to Zero to Tech</h2>
        <button @click="base">
            <img src="@/assets/google.png" height="27" width="27" alt="">
            Login with Google
        </button>
        <h6>or Login with your details</h6>
        <form action="dashboard" method="post" @submit.prevent="handleSubmit">
            <p v-show="error" style="color: red; text-align: center; margin-top: 0.5rem; margin-bottom: 1rem;"> {{ MessageError }} </p>
            <input required type="email" name="" placeholder="Email Address" v-model="Form.email" />
            <div class="password">
                <input required :type="active ? 'text' : 'password'" name="" placeholder="Password" v-model="Form.password" />
                <img @click="active = !active" src="@/assets/svg/eyes.svg" alt="">
            </div>
            <p @click="reset">Forgot Password?</p>
            <button>Login</button>
            <p>Don't have an acoount? <span @click="loginSwitch">Sign Up</span></p>
            <a href="/dashboard" ref="dash" style="display: none"></a>
        </form>
    </div>
  </section>
</template>

<script setup>
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {auth} from "@/FirebaseConfig"
import { useStore, mapActions } from 'vuex';
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from "vue-router";
const store = useStore()
const router = useRouter();
const props = defineProps(["signup", "login"])

const emit = defineEmits(["toggleLoginClose", "toggleClose"])

const active = ref(false);
const error = ref(true)
const MessageError = ref("")
const closeLogin = () => {
    store.state.AccessState = null
}
const openSignUp = () => {
    emit("toggleClose", !props.signup)
}
const reset = () => {
    store.state.AccessState = 3
}
const loginSwitch = () => {
  store.state.AccessState = 1
}
const Form = ref({
    email: "",
    password: ""
})
const dash = ref(null)
mapActions(["Login"])
const handleSubmit = async () => {
    try {
    await store.dispatch("LogIn", Form.value)
    window.sessionStorage.setItem("user", "authenticated")
    store.state.AccessState = null
    dash.value.click();
    router.push("/dashboard")
  }
  catch (error) {
    console.log(error)
    MessageError.value = error.response.data.message
    error.value = false
  }
//   this.$refs.dash.click()
//   router.push("/dashboard")
};
// const base = async () => {

//     const provider = new firebase.auth.GoogleAuthProvider()
//     await firebase.auth().signInWithPopup(provider)
//     try {
//       const result = await signInWithPopup(auth, provider) 
//       let token = result.credential.accessToken;
//       console.log(token);
//       window.localStorage.setItem("token", token);
//       window.sessionStorage.setItem("user", "authenticated");
     
//     } catch(error) {
//         console.log(result)
//     }
// };
const Fire = ref({
    name: "",
    email: "",
    avatar: ''
})
const base = () => {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
        signInWithPopup(auth, provider).then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                Fire.value.name = user.displayName
                Fire.value.email = user.email
                Fire.value.avatar = user.photoURL
                await store.dispatch("Oauth", Fire.value)
                window.sessionStorage.setItem("user", "authenticated")
                store.state.AccessState = null
                dash.value.click();
                router.push("/dashboard")
            }).catch((error) => {
                // Handle Errors here.
                MessageError.value = error.response.data.message
                error.value = false
            });
        }
</script>

<style lang="scss" scoped>
.login {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 4;
    @include flex(center, center);
    background-color: #dddddd80;
    &__main {
        width: Min(90%, 478px);
        background-color: $white;
        padding: 3rem 1.5rem;
        text-align: center;
        nav {
            p {
              text-align: right;
              font-family: 'Karla', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: Max(1rem, 16px);
              line-height: Max(1, 16px);
              letter-spacing: 0.015em;
              color: $black;
              cursor: pointer;
            }
          }
        h2 {
            font-family: 'Karla', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: Max(2rem, 22px);
            line-height: Max(2rem, 22px);
            text-align: center;
            letter-spacing: -0.045em;
            color: $secondary;
            margin-bottom: 2rem;
        }
        button {
            width: min(320px, 100%);
            height: Max(2.75rem, 44px);
            background: $secondary-40;
            border-radius: 6px;
            @extend %flex-ac-jc;
            gap: 1rem;
            font-family: 'Karla', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: Max(0.75rem, 12px);
            line-height: Max(0.875rem, 12px);
            letter-spacing: 0.015em;
            color: $secondary;
            margin: 1rem auto;
        }
        h6 {
            font-family: 'Karla', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: Max(0.75rem, 12px);
            line-height: Max(0.875rem, 12px);
            letter-spacing: 0.015em;
            color: $secondary;
            margin: 1.5rem 0;
        }
        form {
            width: min(320px, 100%);
            margin: 2rem auto;
            input {
                background: $white;
                border: 1px solid #B8B8B8;
                box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
                border-radius: 6px;
                width: 100%;
                height: Max(3rem, 46px);
                padding: 1rem 1.5rem;
                &::placeholder {
                    font-family: 'Karla', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: Max(0.75rem, 12px);
                    line-height: Max(0.875, 14px);
                    letter-spacing: 0.015em;
                    color: $gray-20;
                }
                font-family: 'Karla', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: Max(1rem, 16px);
                line-height: Max(1, 16px);
                letter-spacing: 0.015em;
                color: $black;
            }
            .password {
                @extend %flex-ac;
                width: 100%;
                background: $white;
                border: 1px solid #B8B8B8;
                box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
                border-radius: 6px;
                padding: 1.5px 1px;
                margin: 1rem 0;
                input {
                    border: none;
                    border-radius: 0;
                }
                img {
                    cursor: pointer;
                    padding-right: 1rem;
                }
            }
            p  {
        font-family: 'Karla';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: Max(0.875rem, 14px);
        text-align: center;
        letter-spacing: 0.015em;
        color: $black-100;
        text-align: left;
        span {
            color: $secondary;
            cursor: pointer;
        }
        &.passNumb {
            font-family: 'Karla', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: Max(0.75em, 12px);
        line-height: Max(0.875rem, 14px);
        letter-spacing: 0.015em;
        color: $gray-60;
        }
    }
            button {
                width: 100%;
                height: Max(3rem, 44px);
                background: $secondary-80;
                border-radius: 8px;
                font-family: 'Space Grotesk', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: Max(1.125rem, 18px);
                line-height: Max(1.5rem, 24px);
                letter-spacing: 0.02em;
                text-transform: capitalize;
                color: $white;
            }
        }
    }
}
</style>