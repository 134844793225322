<template>
  <section class="team">
    <div class="team--box">
      <div class="team--text">
        <h3>The Team</h3>
        <p>
          Who are we? Our experienced yet empathetic team comprises people who
          have “been there and done that”. We understand what it feels like to
          start a new tech career. Our in-depth understanding of beginners’
          daily struggles has motivated us to create a compassionate and
          individualised learning style. Within the team, we collectively
          promote knowledge sharing and best practices coaching aimed at helping
          you build a future that starts with taking the first step.
        </p>
      </div>
      <div class="mentors--image">
        <div class="mentors--image--mentor" v-for="item in team" :key="item.id">
          <img :src="item.image" alt="" width="164" height="164" />
          <h6>{{item.name}}</h6>
          <p>{{item.role}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import {ref} from "vue";
const team = ref([
{
    id: 2,
    name: "Paul Ayuk",
    role: "CEO",
    image: "https://res.cloudinary.com/dx9pt4ggx/image/upload/v1676211153/zerotech/Paul_jjas5h.png"
  },
  {
    id: 1,
    name: "Adebayo Ebenezer",
    role: "Product Manager",
    image: "https://res.cloudinary.com/dx9pt4ggx/image/upload/v1676211132/zerotech/mayowa_qdk0yi.png"
  },
  {
    id: 4,
    name: "Tobilola Motunrayo",
    role: "Design Lead",
    image: "https://res.cloudinary.com/dx9pt4ggx/image/upload/v1676211143/zerotech/tobi_hx8t1t.png"
  },
  {
    id: 3,
    name: "Egbaidomeh James",
    role: "Frontend Operation",
    image: "https://res.cloudinary.com/dx9pt4ggx/image/upload/v1701701320/zerotech/11zon_cropped_vkrhrm.png"
  },
  {
    id: 5,
    name: "Ben Arafat",
    role: "Backend Operation",
    image: "https://res.cloudinary.com/dx9pt4ggx/image/upload/v1682017156/zerotech/ben_nhxlw9.png"
  }
])
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.team {
  padding: 4rem 7% 3rem 7%;

  .team--box {
    text-align: center;

    .team--text {
      h3 {
        margin: 2rem 0;
        @include heading2 {
          color: $secondary-80;
          font-family: $karla;
        }
      }
      p {
        padding: 0 10rem;
        @include body {
          color: $black-50;
          font-family: $karla;
        }
      }
    }
  }
}
.mentors--image {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin: 6rem 0;
  flex-wrap: wrap;
  .mentors--image--mentor {
    img {
      margin: 0.7rem 0;
    }
    h6 {
      @include sub-heading-bold {
        margin-bottom: 1rem;
        color: $secondary-80;
        font-family: $karla;
      }
    }
    p {
      @include body {
        color: $secondary-80;
        font-family: $karla;
      }
    }
  }
}
@include xl {
  .team {
    .team--box {
      .team--text {
        width: min(90%, 750px);
        margin: 0 auto;
        h3 {
          padding: 2rem 0;
          @include heading1 {
          }
        }
        p {
          padding: 0rem;
        }
        button {
          margin-bottom: 3rem;
        }
      }
    }
  }
}
@include breakpoint(675px) {
  .team {
    padding: 2rem 7% 0rem 7%;
    .team--box {
      .team--text {
        h2 {
          @include heading1 {
            font-size: 1.5rem;
          }
        }
        button {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
@include breakpoint(475px) {
  .team {
    .team--box {
      .team--text {
        h3 {
          @include heading1 {
            font-size: 1.5rem;
          }
        }
        p {
          @include body {
            font-size: 1rem;
          }
        }
        button {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
</style>
