<template>
  <section class="sort">
      <div class="sort--box">
          <div class="sort--text">
              <h2>
                  Explore by Country
              </h2>
          </div>
          <div class="sort--country">
              <div class="soft--country--names">
                  <div class="soft--country--names--list">
                      <h5 v-for="country in countries" :key="country.id" @click="handleActive(country.id)" :class="{active: country.active}">{{country.name}}</h5>
                  </div>
                  <hr />
              </div>
              <div v-if="steps === 1" class="sort--country--countries">
                  <div class="sort--country--countries--community" v-for="community in communities" :key="community.id">
                      <h2>{{community.name}}</h2>
                      <div class="split">
                          <p>{{community.location}}</p>
                          <img :src="require(`@/assets/Community/${community.image}`)" alt="">
                      </div>
                  </div>
              </div>
              <div v-if="steps === 2" class="sort--country--countries">
                  <div class="sort--country--countries--community" v-for="community in kenya" :key="community.id">
                      <h2>{{community.name}}</h2>
                      <div class="split">
                          <p>{{community.location}}</p>
                          <img :src="require(`@/assets/Community/${community.image}`)" alt="">
                      </div>
                  </div>
              </div>
              <div v-if="steps === 3" class="sort--country--countries">
                  <div class="sort--country--countries--community" v-for="community in ghana" :key="community.id">
                      <h2>{{community.name}}</h2>
                      <div class="split">
                          <p>{{community.location}}</p>
                          <img :src="require(`@/assets/Community/${community.image}`)" alt="">
                      </div>
                  </div>
              </div>
              <div v-if="steps === 4" class="sort--country--countries">
                  <div class="sort--country--countries--community" v-for="community in southy" :key="community.id">
                      <h2>{{community.name}}</h2>
                      <div class="split">
                          <p>{{community.location}}</p>
                          <img :src="require(`@/assets/Community/${community.image}`)" alt="">
                      </div>
                  </div>
              </div>
              <div v-if="steps === 5" class="sort--country--countries">
                  <div class="sort--country--countries--community" v-for="community in rwanda" :key="community.id">
                      <h2>{{community.name}}</h2>
                      <div class="split">
                          <p>{{community.location}}</p>
                          <img :src="require(`@/assets/Community/${community.image}`)" alt="">
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
import axios from 'axios'
import { onMounted, computed, ref } from 'vue';
import { useStore, mapActions, mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({Communities: "stateFiltered"}),
    },
    methods: {
        ...mapActions(["Filtered"]),
        handleActive(number) {
            this.countries.map((newCountry) => {
                if (number === newCountry.id) {
                    newCountry.active = true
                    this.steps = number
                }
                if (number != newCountry.id) {
                    newCountry.active = false
                }
            })
        }
    },
    data() {
        return {
            data: "",
            steps: 1,
            countries: [
                {
                    id: 1,
                    name: "Nigeria",
                    active: true
                },
                {
                    id: 2,
                    name: "Kenya",
                    active: false
                },
                {
                    id: 3,
                    name: "Ghana",
                    active: false
                },
                {
                    id: 4,
                    name: "South Africa",
                    active: false
                },
                {
                    id: 5,
                    name: "Rwanda",
                    active: false
                },
            ],
            communities: [
                {
                    id: 1,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 2,
                    name: "Laravel Accra",
                    location: "Accra,",
                    image: "nigeria.svg"
                },
                {
                    id: 3,
                    name: "VueJs Nigeria",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 4,
                    name: "Friends of Figma",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 5,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 6,
                    name: "GDG Nairobi",
                    location: "Nairobi,",
                    image: "nigeria.svg"
                },
                {
                    id: 7,
                    name: "Open Source Africa",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 8,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 9,
                    name: "Dev Careers",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 10,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 11,
                    name: "Web3 Lagos",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 12,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 13,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 14,
                    name: "For Loop Africa",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 15,
                    name: "She Code Africa",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
                {
                    id: 16,
                    name: "Black Tech Pipeline",
                    location: "Lagos,",
                    image: "nigeria.svg"
                },
            ],
            kenya: [
                 {
                    id: 1,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "kenya.svg"
                },
                {
                    id: 2,
                    name: "Laravel Accra",
                    location: "Accra,",
                    image: "kenya.svg"
                },
                {
                    id: 3,
                    name: "VueJs Nigeria",
                    location: "Lagos,",
                    image: "kenya.svg"
                },
                {
                    id: 4,
                    name: "Friends of Figma",
                    location: "Lagos,",
                    image: "kenya.svg"
                },
                {
                    id: 5,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "kenya.svg"
                },
                {
                    id: 6,
                    name: "GDG Nairobi",
                    location: "Nairobi,",
                    image: "kenya.svg"
                },
                {
                    id: 7,
                    name: "Open Source Africa",
                    location: "Lagos,",
                    image: "kenya.svg"
                },
                {
                    id: 8,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "kenya.svg"
                }
            ],
             ghana: [
                 {
                    id: 1,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "ghana.svg"
                },
                {
                    id: 2,
                    name: "Laravel Accra",
                    location: "Accra,",
                    image: "ghana.svg"
                },
                {
                    id: 3,
                    name: "VueJs Nigeria",
                    location: "Lagos,",
                    image: "ghana.svg"
                },
                {
                    id: 4,
                    name: "Friends of Figma",
                    location: "Lagos,",
                    image: "ghana.svg"
                },
                {
                    id: 5,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "ghana.svg"
                },
                {
                    id: 6,
                    name: "GDG Nairobi",
                    location: "Nairobi,",
                    image: "ghana.svg"
                },
                {
                    id: 7,
                    name: "Open Source Africa",
                    location: "Lagos,",
                    image: "ghana.svg"
                },
                {
                    id: 8,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "ghana.svg"
                }
            ],
             southy: [
                 {
                    id: 1,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "southy.svg"
                },
                {
                    id: 2,
                    name: "Laravel Accra",
                    location: "Accra,",
                    image: "southy.svg"
                },
                {
                    id: 3,
                    name: "VueJs Nigeria",
                    location: "Lagos,",
                    image: "southy.svg"
                },
                {
                    id: 4,
                    name: "Friends of Figma",
                    location: "Lagos,",
                    image: "southy.svg"
                },
                {
                    id: 5,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "southy.svg"
                },
                {
                    id: 6,
                    name: "GDG Nairobi",
                    location: "Nairobi,",
                    image: "southy.svg"
                },
                {
                    id: 7,
                    name: "Open Source Africa",
                    location: "Lagos,",
                    image: "southy.svg"
                },
                {
                    id: 8,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "southy.svg"
                }
            ],
             rwanda: [
                 {
                    id: 1,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "rwanda.svg"
                },
                {
                    id: 2,
                    name: "Laravel Accra",
                    location: "Accra,",
                    image: "rwanda.svg"
                },
                {
                    id: 3,
                    name: "VueJs Nigeria",
                    location: "Lagos,",
                    image: "rwanda.svg"
                },
                {
                    id: 4,
                    name: "Friends of Figma",
                    location: "Lagos,",
                    image: "rwanda.svg"
                },
                {
                    id: 5,
                    name: "GDG Lagos",
                    location: "Lagos,",
                    image: "rwanda.svg"
                },
                {
                    id: 6,
                    name: "GDG Nairobi",
                    location: "Nairobi,",
                    image: "rwanda.svg"
                }
            ]
        }
    },
    setup() {
        const Country = ref("nigeria")
        const store = useStore();
        mapActions(["Filtered"])
        onMounted(() => {
            store.dispatch("Filtered")
        })
        return {Country}
    },
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
/* active country style */
.active {
    color: #D5A48A;
}
.sort {
    padding: 2rem 7%;
}
.sort--text {
    max-width: 100vw;
    width: min(90%, 547px);
    margin: 0 auto;
    text-align: center;
    h2 {
        @include title1 {
            color: $black-100;
            font-family: $karla;
        }
    }
}
.sort--country {
    max-width: 100vw;
    width: min(90%, 1227px);
    height: 785px;
    margin: 2rem auto;
    padding: 2rem;
    background: #FFFFFF;
    border: 1px solid #D5A48A;
    box-sizing: border-box;
    border-radius: 24px;
}
.soft--country--names {
    width: min(90%, 1017px);
    max-width: 100vw;
    margin: 2rem auto;
    .soft--country--names--list {
        @include flex(space-between, flex-start);
        h5 {
            cursor: pointer;
            @include body {
                font-family: 'Montserrat', sans-serif;
                color: $gray-100;
                font-size: 1.2rem;
            }
            &.active {
                color: #D5A48A;
                }
        }
    }
    hr {
        width: 100%;
        border: 1px solid #D5A48A;
    }
}
.sort--country--countries {
    width: min(90%, 1017px);
    max-width: 100vw;
    margin: 2rem auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem;
    place-items: center;
}
.sort--country--countries--community {
    width: 190px;
    h2 {
        @include body {
            font-family: 'Montserrat', sans-serif;
            color: $black;
            font-size: 1rem;
        }
    }
    .split {
        display: flex;
        gap: 0.3rem;
        p {
            margin: auto 0;
            @include caption {
            font-family: 'Montserrat', sans-serif;
            color: $black-100;
            font-size: 0.9rem;
        }
        }
    }
}
@include breakpoint(1250px) {
    .sort--country {
        height: auto;
    }
    .sort--country--countries {
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
}
}
@include md {
    .soft--country--names {
    .soft--country--names--list {
        flex-wrap: wrap;
        gap: 1rem;
        h5 {
            margin: 0.7rem auto;
        }
    }
}
}
@include sm {
    .sort--text {
    h2 {
        @include title1 {
            font-size: 1.9rem;
        }
    }
}
}
.sort--country--countries--community {
    width: min(90%, 190px);
    margin: 0.5rem auto;
    text-align: center;
    .split {
        display: flex;
        justify-content: center;
        gap: 0.3rem;
        p {
            margin: auto 0;
            @include caption {
            font-family: 'Montserrat', sans-serif;
            color: $black-100;
        }
        }
    }
}
@include xs {
    .soft--country--names {
    .soft--country--names--list {
        flex-direction: column;
        gap: 1rem;
        h5 {
            margin: 0.7rem auto;
        }
    }
}
}
</style>