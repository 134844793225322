import axios from "axios";

const state = {
    project: null,
    details: null,
    field: null,
    location: null,
};

const getters = {
    ProjectState: state => state.project,
    DetailsState: state => state.details,
    FieldState: state => state.field,
    LocationState: state => state.location
};

const actions = {
    async Project({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get("project", {headers: headers});
         ;
        let project = res.data.data
        await commit("setProject", project);
    },
    async ProjectDetails({commit}, Id) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`project/${Id}`, {headers: headers});
        let details = res.data.data.project
        await commit("setDetails", details)
    },
    async Field({commit}, Field) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`project/filter?fields[courses]=${Field}`, {headers: headers})
         
        let project = res.data.data.project
        await commit("setField", project);
    },
    async Level({commit}, Level) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`project/filter?fields[level]=${Level}`, {headers: headers})
         
        let project = res.data.data.project
        await commit("setLocation", project);
    }
};

const mutations = {
    setProject(state, project) {
    state.project = project
    },
    setDetails(state, details) {
        state.details = details
    },
    setField(state, fields) {
        state.field = fields
    },
    setLocation(state, location) {
        state.location = location
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}