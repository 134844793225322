<template>
<section class="intro">
    <div class="intro--box">
        <div class="intro--text">
            <h1>
                Our Mentors
            </h1>
        </div>
      
    </div>
</section>
<div class="intro--details">
            <Details />
        </div>
</template>

<script>
import Details  from './Details'
export default {
    components: {
        Details
    },
    data() {
        return {
            filter: [],
            active: false
        }
    },
    methods: {
        closeFilter() {
            this.active = false
        },
        openFilter() {
            this.active = !this.active
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.intro {
    background: $secondary-40;
    height: 429.24px;
    /* position: relative; */
}
.intro--details {
    position: relative;
    top: -8rem;
    @include flex(center,center);
}
.intro--box {
    padding: 5rem 7%;
    text-align: center;
    /* position: relative; */
    .intro--text {
        margin-bottom: 4rem;
        h1 {
            @include heading1 {
                color: $secondary-80;
                font-family: $karla;
            }
        }
    }
    .intro--button {
        @include flex(flex-end, flex-start);
        position: relative;
        left: 1%;
        button {
            background: transparent;
        }
        .intro--filter {
            position: absolute;
            top: 5rem;
            z-index: 3;
            width: 322px;
            height: 439px;
            border-radius: 20px;
            background-color: $white;
            text-align: center;
            padding: 2rem 2rem;
            h3 {
                @include body-bold {
                    color: $secondary-80;
                    font-family: $karla;
                }
            }
            ul {
                li {
                    display: flex;
                    gap: 0.4rem;
                    justify-content: left;
                    margin: 0.7rem 0;
                    input {
                        align-self: center;
                    }
                    p {
                        align-self: center;
                        margin: auto 0;
                    }
                }
            }
        }
    }
}
@include xl {
    .intro {
    background-image: url('./bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 329.24px;
    position: relative;
}
    .intro--box {
    padding: 3rem 7%;
    .intro--text {
        margin-bottom: 0rem;
        h1 {
            @include heading1 {
            }
        }
    }
    .intro--button {
        @include flex(flex-end, flex-start);
        margin: 1.5rem 0;
        position: relative;
        .intro--filter {
            position: absolute;
            top: 5rem;
            z-index: 3;
            width: min(90%, 322px);
            height: auto;
            margin: 0 auto;
            border-radius: 20px;
            text-align: center;
            padding: 0rem 2rem;
            h3 {
                @include body-bold {
                    color: $secondary-80;
                    font-family: $karla;
                }
            }
            ul {
                li {
                    display: flex;
                    gap: 0.4rem;
                    justify-content: left;
                    margin: 0.7rem 0;
                    input {
                        align-self: center;
                    }
                    p {
                        align-self: center;
                        margin: auto 0;
                    }
                }
            }
        }
    }
}
}
@include lg {
    .intro {
        background: $secondary-40;
        height: 280px;
    }
    
}
@include breakpoint(490px) {
   
.intro--box {
    padding: 3rem 7%;
    .intro--text {
        margin-bottom: 0rem;
        h1 {
            @include heading1 {
                font-size: 2.5rem;
            }
        }
    }
    .intro--button {
        @include flex(center, flex-start);
        margin: 1.5rem 0;
        position: relative;
        .intro--filter {
            position: absolute;
            top: 5rem;
            z-index: 3;
            width: min(90%, 322px);
            height: auto;
            margin: 0 auto;
            border-radius: 20px;
            text-align: center;
            padding: 0rem 2rem;
            h3 {
                @include body-bold {
                    color: $secondary-80;
                    font-family: $karla;
                }
            }
            ul {
                li {
                    display: flex;
                    gap: 0.4rem;
                    justify-content: left;
                    margin: 0.7rem 0;
                    input {
                        align-self: center;
                    }
                    p {
                        align-self: center;
                        margin: auto 0;
                    }
                }
            }
        }
    }
}
}
@include md {
.intro--box {
    padding: 3rem 7%;
    .intro--text {
        margin-bottom: 0rem;
        h1 {
            @include heading1 {
                font-size: 2.5rem;
            }
        }
    }
    .intro--button {
        @include flex(flex-end, flex-start);
        margin: 1.5rem 0;
        position: relative;
        .intro--filter {
            position: absolute;
            top: 5rem;
            z-index: 3;
            width: min(90%, 322px);
            height: auto;
            margin: 0 auto;
            border-radius: 20px;
            text-align: center;
            padding: 0rem 2rem;
            h3 {
                @include body-bold {
                    color: $secondary-80;
                    font-family: $karla;
                }
            }
            ul {
                li {
                    display: flex;
                    gap: 0.4rem;
                    justify-content: left;
                    margin: 0.7rem 0;
                    input {
                        align-self: center;
                    }
                    p {
                        align-self: center;
                        margin: auto 0;
                    }
                }
            }
        }
    }
}
}
@include sm {
.intro--box {
    padding: 3rem 7%;
    .intro--text {
        margin-bottom: 0rem;
        h1 {
            @include heading1 {
                font-size: 2.5rem;
            }
        }
    }
    .intro--button {
        @include flex(flex-end, flex-start);
        margin: 1.5rem 0;
        position: relative;
        .intro--filter {
            position: absolute;
            top: 5rem;
            z-index: 3;
            width: min(90%, 322px);
            height: auto;
            margin: 0 auto;
            border-radius: 20px;
            text-align: center;
            padding: 0rem 2rem;
            h3 {
                @include body-bold {
                    color: $secondary-80;
                    font-family: $karla;
                }
            }
            ul {
                li {
                    display: flex;
                    gap: 0.4rem;
                    justify-content: left;
                    margin: 0.7rem 0;
                    input {
                        align-self: center;
                    }
                    p {
                        align-self: center;
                        margin: auto 0;
                    }
                }
            }
        }
    }
}
}
@include breakpoint(500px) {
    .intro {
    background: $secondary-40;
    height: 239.24px;
    /* position: relative; */
}
.intro--box {
    padding: 3rem 7%;
    .intro--text {
        margin-bottom: 0rem;
        h1 {
            @include heading1 {
                font-size: 2.5rem;
            }
        }
    }
    .intro--button {
        @include flex(flex-end, flex-start);
        margin: 1.5rem 0;
        position: relative;
        .intro--filter {
            position: absolute;
            top: 5rem;
            z-index: 3;
            width: min(90%, 322px);
            height: auto;
            margin: 0 auto;
            border-radius: 20px;
            text-align: center;
            padding: 0rem 2rem;
            h3 {
                @include body-bold {
                    color: $secondary-80;
                    font-family: $karla;
                }
            }
            ul {
                li {
                    display: flex;
                    gap: 0.4rem;
                    justify-content: left;
                    margin: 0.7rem 0;
                    input {
                        align-self: center;
                    }
                    p {
                        align-self: center;
                        margin: auto 0;
                    }
                }
            }
        }
    }
}
}
@include xs {
.intro--box {
    padding: 3rem 7%;
    .intro--text {
        margin-bottom: 0rem;
        h1 {
            @include heading1 {
                font-size: 2.5rem;
            }
        }
    }
    .intro--button {
        @include flex(flex-end, flex-start);
        margin: 1.5rem 0;
        position: relative;
        .intro--filter {
            position: absolute;
            top: 5rem;
            z-index: 3;
            width: min(90%, 322px);
            height: auto;
            margin: 0 auto;
            border-radius: 20px;
            text-align: center;
            padding: 0rem 2rem;
            h3 {
                @include body-bold {
                    color: $secondary-80;
                    font-family: $karla;
                }
            }
            ul {
                li {
                    display: flex;
                    gap: 0.4rem;
                    justify-content: left;
                    margin: 0.7rem 0;
                    input {
                        align-self: center;
                    }
                    p {
                        align-self: center;
                        margin: auto 0;
                    }
                }
            }
        }
    }
}
}
</style>