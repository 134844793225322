<template>
  <Intro />
  <Comparison />
  <Filter />
</template>

<script>
import Intro from './Intro.vue';
import Comparison from './Comparison.vue';
import Filter from './Filter.vue'
export default {
    components: {
        Intro,
        Comparison,
        Filter
    }
}
</script>

<style>

</style>