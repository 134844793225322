<template>
<Payment v-if="active" :active="active" @togglePay.self="closePayment($event)" />
  <section class="about--intro" >
      <div class="intro--box">
          <div class="intro--text">
              <h2>
                  A Powerful Tech-Edu Platform for Everybody
              </h2>
              <div class="btn">
                  <button @click="openPay" class="button--premium">
                  Go Premium Now!
              </button>
              </div>
          </div>
          <div class="intro--image">
              <img src="@/assets/About/group.png" alt="">
          </div>
      </div>
  </section>
</template>

<script>
import Payment from '@/Modals/Payment/Payment.vue'
export default {
    components: {
        Payment
    },
    data() {
        return {
            active: false
        }
    },
    methods: {
        openPay() {
            this.active = true
        },
        closePayment(closed) {
            this.active = closed
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";

.about--intro {
    background: linear-gradient(0deg, rgba(240, 222, 213, 0.4), rgba(240, 222, 213, 0.4)), #FFFFFF;

    .intro--box {
        padding: 5rem 7% 0 7%;
        text-align: center;
        
        .intro--text {
             h2 {
                 padding: 3rem 15%;
            @include heading1 {
                color: $secondary-80;
                font-family: $karla;
                
            }
        }
        button {
            margin-bottom: 3rem;
        }
        }
    }
}
.btn {
    @include flex(center,center);
}
@include xl {
    .about--intro {
    .intro--box {
        .intro--text {
            width: min(90%, 750px);
            margin: 0 auto;
             h2 {
                 padding: 2rem 0;
            @include heading1 {
                font-size: 3.5rem;
            }
        }
        button {
            margin-bottom: 3rem;
        }
        }
    }
}
.intro--image {
    max-width: 100vw;
    img {
      width: 85%;
    }
}
}
@include breakpoint(675px) {
    .about--intro {
    .intro--box {
        padding: 2rem 7% 0 7%;
        .intro--text {
             h2 {
            @include heading1 {
                font-size: 2.5rem;
            }
        }
        button {
            margin-bottom: 2rem;
        }
        }
    }
}
}
@include breakpoint(675px) {
    .about--intro {
    .intro--box {
        .intro--text {
             h2 {
            @include heading1 {
                font-size: 1.5rem;
            }
        }
        button {
            margin-bottom: 2rem;
        }
        }
    }
}

}
@include breakpoint(350px) {
.button--premium {
    font-size: 0.8rem;
}
}
</style>