<template>
  <section class="info">
    <div class="info__item">
        <div class="info__item__details">
            <img class="cancel" src="@/assets/svg/cancel2.svg" @click="cancel" alt="">
            <img :src="$store.state.pfp" class="pfp" width="106" height="97" alt="">
            <h6>{{first}}</h6>
            <p>{{email}}</p>
        </div>
        <div class="info__item__actions">
            <router-link to="/basic-profile">
                <div class="item" @click="cancel">
                    <img src="@/assets/svg/align.svg" alt="">
                    <div>
                        <p>Personal Details</p>
                        <img src="@/assets/svg/arr-right.svg" alt="">
                    </div>
                </div>
            </router-link>
            <router-link to="/change-password">
                <div class="item" @click="cancel">
                    <img src="@/assets/svg/padlock.svg" alt="">
                    <div>
                        <p>Change Password</p>
                        <img src="@/assets/svg/arr-right.svg" alt="">
                    </div>
                </div>
            </router-link>
            <a href="/" ref="dash" style="display: none"></a>
                <div class="item" @click="logout">
                    <img src="@/assets/svg/logout.svg" alt="">
                    <div>
                        <p>Logout</p>
                        <img src="@/assets/svg/arr-right.svg" alt="">
                    </div>
                </div>
        </div>
    </div>
  </section>
</template>

<script setup>
import {useStore, mapActions} from "vuex"
import { ref, onMounted } from "vue"
const store = useStore()
const props = defineProps(["head"])
const emit = defineEmits(["closeToggle"])
const cancel = () => {
    emit("closeToggle", !props.head)
}
const dash = ref(null)
const first = ref()
const email = ref()
const avatar = ref()
mapActions(["Logout", "User"])
onMounted(async () => {
    await store.dispatch("User")
    email.value = store.getters.StateOwner.email
  first.value = store.getters.StateOwner.name
  store.state.pfp = store.getters.StateOwner.avatar
})
// const logout = async () => {
//     try {
//         await store.dispatch("Logout")
//         window.sessionStorage.setItem("user", "unauthenticated");
//         cancel()
//         dash.value.click();
//     }
//     catch(error) {
//         console.log(error.message)
//     }
// };
const logout = () => {
        window.sessionStorage.setItem("user", null);
        cancel()
        dash.value.click();
}
</script>

<style lang="scss" scoped>
.pfp {
    height: 100px;
    width: 100px;
    border-radius: 50px;
  }
.info {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(95, 94, 94, 0.5);
  top: 0;
  left: 0;
  @media screen and (max-width: 400px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
  &__item {
    position: absolute;
    width: Min(90%, 354px);
    right: 3rem;
    top: 10%;
    background-color: $primary-20;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    @media screen and (max-width: 400px) {
        right: unset;
    }
    &__details {
        width: 100%;
        background-color: $secondary;
        border-radius: none;
        height: Max(14.125rem, 226px);
        padding: 1.5rem 1rem;
        text-align: center;
        img {
            &.cancel {
                margin-left: auto;
                @include flex(flex-end, center);
                cursor: pointer;
            }
        }
        h6 {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: Max(1rem, 16px);
            line-height: Max(1.5rem, 24px);
            letter-spacing: -0.0016em;
            color: $black-100;
            margin-bottom: unset;
            margin-top: 1rem;
            text-transform: capitalize;
        }
        p {
            font-family: 'Karla', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: Max(0.75rem, 12px);
            line-height: Max(1.25rem, 20px);
            letter-spacing: -0.0016em;
            color: $white;
        }
    }
    &__actions {
        width: 100%;
        padding-bottom: 2rem;
        .item {
            padding: 0.35rem 0.5rem;
            margin: 0.5rem 0;
            @extend %flex-ac;
            gap: 1rem;
            background: #F9F2EE;
            box-shadow: 0px 4px 4px rgba(73, 67, 67, 0.12);
            cursor: pointer;
            &>div {
                width: 100%;
                padding-right: 0.35rem;
                @extend %flex-ac-jb;
                p {
                    margin-bottom: unset;
                    font-family: 'Roboto', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: Max(0.75rem, 12px);
                    line-height: Max(1.25rem, 20px);
                    letter-spacing: -0.0016em;
                    color: $black-10;
                }
            }
        }
    }
  }
}
</style>
