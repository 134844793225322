<template>
  <section class="intro">
      <div class="intro--box">
          <div class="intro--text">
              <h2>
                  Connect  with a tech community
              </h2>
          </div>
          <div class="intro--image">
              <img src="@/assets/Community/group.svg" alt="">
          </div>
          <div class="intro--search">
              <div class="intro--search--input">
                  <input type="text" placeholder="Join a tech  community around you? Search city" id="">
                  <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.75 28.75C28.2728 28.75 32.75 24.2728 32.75 18.75C32.75 13.2272 28.2728 8.75 22.75 8.75C17.2272 8.75 12.75 13.2272 12.75 18.75C12.75 24.2728 17.2272 28.75 22.75 28.75Z" stroke="#8F3D55" stroke-opacity="0.36" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M35.25 31.25L29.8125 25.8125" stroke="#8F3D55" stroke-opacity="0.36" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.intro {
    background: linear-gradient(180deg, #F0DED5 -18.15%, rgba(240, 222, 213, 0) 100%);
}
.intro--box {
    padding: 3rem 7%;
}
.intro--text {
    text-align: center;
    max-width: 100vw;
    width: min(90%, 947px);
        margin: 0 auto;
    h2 {
        @include title1 {
            color: $black-100;
            font-family: $karla;
        }
    }
}
.intro--image {
    margin-top: 2rem;
    text-align: center;
    max-width: 100vw;
    img {
        margin-top: 1rem;
        width: min(90%, 1106px);
    margin: 0 auto;
    }
}
.intro--search {
    .intro--search--input {
        max-width: 100vw;
        margin: 0 auto;
        /* text-align: center; */
        position: relative;
        width: min(90%, 603px);
        height: 68px;
        input {
            background: #EBEBEB;
            border-radius: 15px;
            width: 100%;
            height: 67px;
            border: none;
            padding: 1.5rem;
            position: absolute;
            @include caption {
                color: $black-50;
                font-family: $karla;
            }
        }
        svg {
            position: absolute;
            left: 85%;
            top: 20%;
        }
    }
}
::placeholder {
    @include caption {
        color: $primary-60;
        font-family: $karla;
    }
}
@include xl {
    .intro--text {
    h2 {
        @include title1 {
            font-size: 3rem;
        }
    }
}
}
@include md {
    .intro--text {
    h2 {
        @include title1 {
            font-size: 2rem;
        }
    }
}
}
@include sm {
    .intro--text {
    h2 {
        @include title1 {
            font-size: 1.5rem;
        }
    }
}
.intro--search {
    .intro--search--input {
        max-width: 100vw;
        margin: 0 auto;
        /* text-align: center; */
        position: relative;
        width: min(90%, 603px);
        height: 68px;
        input {
            background: #EBEBEB;
            border-radius: 15px;
            width: 100%;
            height: 67px;
            border: none;
            padding: 1.5rem 3rem 1.5rem 1.5rem;
            position: absolute;
            @include caption {
                color: $black-50;
                font-family: $karla;
            }
        }
    }
}
::placeholder {
    @include caption {
        font-size: 0.8rem;
    }
}
}
@include xs {
    .intro--text {
    h2 {
        @include title1 {
            font-size: 1.5rem;
        }
    }
}
}
</style>