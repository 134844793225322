<template>
  <div v-if="signup === 1">
    <Signup />
  </div>
  <section class="first">
    <div class="first__out" v-if="details">
      <div class="item">
        <div class="item__text" v-if="stages === 1">
          <h2>What’s your first name ?</h2>
          <input type="text" name="" id="" required>
          <button @click="stages = 2">Next</button>
        </div>
        <div class="item__text" v-if="stages === 2">
          <h2>What’s your last name?</h2>
          <input type="text" name="" id="" required>
          <button @click="stages = 3">Next</button>
        </div>
        <div class="item__text" v-if="stages === 3">
          <h2>What’s your email address ?</h2>
          <input type="email" required name="" id="">
          <button @click="handleResult">Submit</button>
        </div>
      </div>
    </div>
    <div class="first--box">
      <!-- <div class="first--image">
        <div>
          <img src="@/assets/Personality/PersonalityTest/question.png" alt="" />
          <p>
            Fill the test questions with honest answers
          </p>
        </div>
        <div>
           <img src="@/assets/Personality/PersonalityTest/result.png" alt="" />
           <p>
             See your test result
           </p>
        </div>
        <div>
          <img src="@/assets/Personality/PersonalityTest/start.png" alt="" />
          <p>
            Start your career
          </p>
        </div>
      </div> -->
      <div class="first--question">
        <div class="bg">
          <img src="./bg.png" alt="" />
        </div>
        <div class="test--question">
          <div class="test--question--text">
            <h3>Question {{ test }} of 5</h3>
            <p>
              Far far away, behind the word mountains, far from the countries
              Vokalia and Consonantia, there live the blind texts.
            </p>
          </div>
          <div class="test--questions--box">
            <!-- test question 1 -->
            <div v-if="test === 1">
              <div
                v-for="question in questions"
                :key="question.id"
                class="test--questions--question"
              >
                <div class="check">
                  <input
                    type="checkbox"
                    v-model="check"
                    :value="question.id"
                    id=""
                  />
                  <p>{{ question.id }}</p>
                </div>
                <div class="text">
                  <h5>
                    {{ question.content }}
                  </h5>
                </div>
              </div>
            </div>
            <!-- test question 2 -->
            <div v-if="test === 2">
              <div
                v-for="question in questions2"
                :key="question.id"
                class="test--questions--question"
              >
                <div class="check">
                  <input
                    type="checkbox"
                    v-model="check"
                    :value="question.id"
                    id=""
                  />
                  <p>{{ question.id }}</p>
                </div>
                <div class="text">
                  <h5>
                    {{ question.content }}
                  </h5>
                </div>
              </div>
            </div>
            <!-- test question  3-->
            <div v-if="test === 3">
              <div
                v-for="question in questions"
                :key="question.id"
                class="test--questions--question"
              >
                <div class="check">
                  <input
                    type="checkbox"
                    v-model="check"
                    :value="question.id"
                    id=""
                  />
                  <p>{{ question.id }}</p>
                </div>
                <div class="text">
                  <h5>
                    {{ question.content }}
                  </h5>
                </div>
              </div>
            </div>
            <!-- test question 4 -->
            <div v-if="test === 4">
              <div
                v-for="question in questions2"
                :key="question.id"
                class="test--questions--question"
              >
                <div class="check">
                  <input
                    type="checkbox"
                    v-model="check"
                    :value="question.id"
                    id=""
                  />
                  <p>{{ question.id }}</p>
                </div>
                <div class="text">
                  <h5>
                    {{ question.content }}
                  </h5>
                </div>
              </div>
            </div>
            <!-- test question 5 -->
            <div v-if="test === 5">
              <div
                v-for="question in questions"
                :key="question.id"
                class="test--questions--question"
              >
                <div class="check">
                  <input
                    type="checkbox"
                    v-model="check"
                    :value="question.id"
                    id=""
                  />
                  <p>{{ question.id }}</p>
                </div>
                <div class="text">
                  <h5>
                    {{ question.content }}
                  </h5>
                </div>
              </div>
            </div>
            <div class="btn">
              <button @click="handleCount" class="btn--course">
                <p v-if="test === 5">Submit</p>
                <p v-else>Next</p>
              </button>
            </div>
            <div class="first--progressbar">
              <div class="percent">
                <p>{{ value }}%</p>
                <div class="progress">
                  <div class="line1"></div>
                  <div
                    class="line2"
                    v-bind:style="{ width: value + '%' }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Signup from "@/Modals/Access/TestSignup.vue";
export default {
  props: {
    count: {
      required: true,
      type: Number,
    },
  },
  components: {
    Signup,
  },
  methods: {
    handleCount() {
      
      // increase the number you are in in the test
      this.testCount++;
      // returns the check state to original
      this.check = [];
      // to check if you have completed the whole test so you can move to the result page
      if (this.test === 5) {
        this.details = true
        this.stages = 1
      } else {
        this.test++;
      }
      // to check and increase the percentage the percentage
      const percent = 100 / 5;
      this.value = this.value + percent;
    },
    handleResult() {
      this.$emit("changeCount", 3);
      this.details = false
      this.status = 1
    }
  },
  data() {
    return {
      stages: 1,
      details: false,
      value: 0,
      signup: 0,
      test: 1,
      testCount: 1,
      check: [],
      questions: [
        {
          id: "a.",
          content:
            "Even the all-powerful Pointing has no control about the blind texts",
        },
        {
          id: "b.",
          content:
            "Even the all-powerful Pointing has no control about the blind texts",
        },
        {
          id: "c.",
          content:
            "Even the all-powerful Pointing has no control about the blind texts",
        },
        {
          id: "d.",
          content:
            "Even the all-powerful Pointing has no control about the blind texts",
        },
        {
          id: "e.",
          content:
            "Even the all-powerful Pointing has no control about the blind texts",
        },
      ],
      questions2: [
        {
          id: "a.",
          content:
            "Even the all-powerful Pointing has no control about the visible texts",
        },
        {
          id: "b.",
          content:
            "Even the all-powerful Pointing has no control about the visible texts",
        },
        {
          id: "c.",
          content:
            "Even the all-powerful Pointing has no control about the visible texts",
        },
        {
          id: "d.",
          content:
            "Even the all-powerful Pointing has no control about the visible texts",
        },
        {
          id: "e.",
          content:
            "Even the all-powerful Pointing has no control about the visible texts",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/TestStyle/Test.scss";
@import "@/styles/_variables.scss";
.percent {
  position: relative;
  z-index: 2;
  width: 80%;
  text-align: center;
  p {
    margin-bottom: -0.3rem;
    text-align: left;
    @include caption {
      color: $black-50;
      font-family: $karla;
    }
  }
}
.progress {
  position: relative;
  height: 12px;
  border-radius: 8px;
  width: 100%;
  .line1 {
    width: 100%;
    height: 12px;
    background: $secondary-80;
  }
  .line2 {
    position: absolute;
    /* width: 50%; */
    height: 12px;
    background: #b86b41;
    border-radius: 0 8px 8px 0;
    top: 0;
  }
}

.first {
  position: relative;
  &__out {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(95, 94, 94, 0.5);
    top: 0;
    left: 0;
    z-index: 3;
    @extend %flex-ac-jc;
    @media screen and (max-width: 400px) {
          display: flex;
          justify-content: center;
          align-items: center;
      }
    .item {
      background-color: $white;
      padding: 2rem;
      width: Min(90%, 498px);
      height: Max(24.688rem, 395px);
      @extend %flex-ac-jc;
      &__text {
        h2 {
          font-family: 'Karla', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: Max(1.875rem, 30px);
          line-height: 120%;
          text-align: center;
          letter-spacing: -0.045em;
          color: $secondary;
        }
        input[type="text"], input[type="email"] {
          border: 1px solid #B8B8B8;
          box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
          border-radius: 6px;
          background-color: transparent;
          padding: 16px 70px 16px 24px;
          width: Min(100%, 320px);
          height: Max(2.875rem, 46px);
          margin: 2rem 0;
          font-family: 'Karla', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: Max(1.125rem, 18px);
          line-height: 120%;
          letter-spacing: 0.02em;
          color: $black;
        }
        button {
          background: $secondary-80;
          border-radius: 8px;
          width: Min(100%, 320px);
          height: Max(2.875rem, 46px);
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: Max(1.125rem, 18px);
          line-height: 120%;
          letter-spacing: 0.02em;
          text-transform: capitalize;
          color: $white;
        }
      }
    }
  }
}
</style>
