<template>
  <section class="hiring">
    <div class="hiring--box">
      <div class="hiring--text">
        <h2>Top Companies Hiring</h2>
      </div>
      <div class="hiring--companies">
        <div class="hiring--company--nig">
          <img src="@/assets/Hiring/konga.png" alt="" />
          <img src="@/assets/Hiring/piggyvest.png" alt="" />
          <img src="@/assets/Hiring/patricia.png" alt="" />
        </div>
        <div class="hiring--company--faang">
          <img src="@/assets/Hiring/faang.png" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.hiring {
  padding: 2rem 7% 3rem 7%;
  text-align: center;
}
.hiring--text {
  h2 {
    margin-bottom: 3rem;
    @include sub-heading-bold {
      font-family: $karla;
      color: $black-50;
    }
  }
}
.hiring--company--nig {
  @include flex(center, flex-start);
  flex-wrap: wrap;
  gap: 5rem;
  margin-bottom: 3rem;
  img {
    margin: 0.7rem;
  }
}
</style>