<template>
  <div class="home">
    <Curriculum />
  </div>
</template>

<script>
// @ is an alias to /src
import Curriculum from '@/pages/Curriculum/Curriculum.vue'
export default {
  components: {
    Curriculum
  }
}
</script>

<style lang="scss" scoped>
</style>
