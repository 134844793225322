<template>
  <section class="roadmap">
    <div class="roadmap__box">
      <div class="roadmap__text">
        <h3>
          Roadmap to Becoming a Devops Engineer
        </h3>
      </div>
      <Roadmap :started="started" :going="going" />
    </div>
  </section>
</template>
<script setup>
import Roadmap from "@/shared/roadmap.vue"
import {ref} from "vue";
const started = ref([
  {
    id: 1,
    title: "A strong foundation in software development",
    desc: "DevOps is about integrating software development and operations, so it's important to have a solid understanding of software development principles, methodologies, and best practices.",
    bg: "#DAADBB",
    btn: "Get Started"
  },
  {
    id: 2,
    title: "Proficiency in system administration",
    desc: "DevOps engineers need to have a good understanding of system administration concepts, including operating systems, networking, virtualization, and cloud computing. Gain hands-on experience in managing and configuring different operating systems, networking protocols, and virtualization technologies.",
    bg: "#dee2e6"
  },
  {
    id: 3,
    title: "Automation and scripting",
    desc: " Automation is a key aspect of DevOps, so learning automation tools and scripting languages is crucial. Familiarize yourself with popular automation tools such as Jenkins, GitLab CI/CD, Ansible, or Terraform, and learn scripting languages such as Python, Ruby, or Shell scripting.",
    bg: "#DAADBB"
  },
])
const going = ref([
  {
    id: 1,
    title: "Skills in infrastructure as code (IaC)",
    desc: "IaC is a fundamental concept in DevOps, so learn about tools such as Terraform, CloudFormation, or Ansible for defining and managing infrastructure resources in a declarative way.",
    bg: "#dee2e6",
    btn: "Get Going"
  },
  {
    id: 2,
    title: "Experience with containerization and orchestration",
    desc: "Containerization technologies such as Docker and container orchestration platforms such as Kubernetes are widely used in DevOps practices. Learn how to create and manage container images, deploy containers to clusters, and optimize containerized applications for performance and scalability.",
    bg: "#DAADBB"
  },
  {
    id: 3,
    title: "Monitoring and logging",
    desc: "Monitoring and logging are critical for ensuring the reliability and performance of software systems. Familiarize yourself with monitoring and logging tools such as Prometheus, Grafana, ELK stack, or Splunk, and learn how to set up monitoring and alerting, analyze system metrics and logs, and troubleshoot issues.",
    bg: "#dee2e6"
  },
])
</script>

<style lang="scss" scss scoped>
@import "@/styles/_typography.scss";
.roadmap {
  background: #F9F0EC;
  padding: 4rem 7%;
  &__box {
    width: 100%;
  }
  &__text {
    max-width: 100vw;
    width: min(90%, 804px);
    margin: 0 auto;
    text-align: center;
  h3 {
    @include heading3 {
      color: $secondary-80;
      font-family: $karla;
    }
    @media screen and (max-width: 900px) {
      margin: 0 auto;
      font-size: 1.5rem;
    }
  }
  }
}
</style>