<template>
    <Test v-if="test" :test="test" @closeTest="testCancel($event)" />
    <section class="selector">
        <div class="selector__item">
            <nav>
                <p @click="cancel">X</p>
              </nav>
            <h2>Already have a <span>career</span> in mind?</h2>
            <div class="selector__buttons">
                <button v-for="item in courses" :key="item.id" :class="{ active : item.id === number }" @click="number = item.id, data.career_field = item.title, postCourse(), close()"> {{ item.title }} </button>
            </div>
            <h2> <span>No?</span> Let’s help you.</h2>
            <button class="submit" @click="test = !test">Take our test</button>
        </div>
        </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import {useStore, mapActions, mapGetters} from "vuex";
import Test from "../Modals/Test.vue";
const store = useStore()
const courses = ref([
    {
        id: 1,
        title: "UI/UX Design"
    },
    {
        id: 2,
        title: "Frontend "
    },
    {
        id: 3,
        title: "BackEnd"
    },
    {
        id: 4,
        title: "Datascience"
    },
    {
        id: 5,
        title: "Cloud Eng."
    },
    {
        id: 6,
        title: "UX  Research"
    },
    {
        id: 7,
        title: "Dev/Ops"
    },
    {
        id: 8,
        title: "Blockchain"
    },
    {
        id: 9,
        title: "Data Eng."
    },
    {
        id: 10,
        title: "Product Management"
    },
])
const number = ref(null)
const test = ref(false)

const close = () => {
    store.state.CourseSelect = false
    window.sessionStorage.setItem("loaded", true)
}
const cancel = () => {
    store.state.CourseSelect = false
}
const testCancel = (item) => {
    test.value = item
}
const data = ref({
  name: "",
  career_field: "",
  telephone: "",
  country: ""
})
mapActions(["User"])
onMounted(async () => {
    await store.dispatch("User")
    data.value.name = first.value
    data.value.telephone = null
    data.value.career_field = null
    data.value.country = null
})
const postCourse = async () => {
    data.value.name = store.getters.StateOwner.name
    data.value.telephone = null
    data.value.country = null
  await store.dispatch("Update", data.value)
  await store.dispatch("User")
}
</script>

<style lang="scss" scoped>
.selector {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(95, 94, 94, 0.5);
    top: 0;
    left: 0;
    z-index: 3;
    @extend %flex-ac-jc;
    @media screen and (max-width: 400px) {
          display: flex;
          justify-content: center;
          align-items: center;
      }
      &__item {
        width: Min(90%, 900px);
        padding: 2rem 1.75rem;
        background-color: $white;
        border-radius: 10px;
        @media screen and (max-width: 400px) {
            right: unset;
        }
        nav {
            p {
              text-align: right;
              font-family: 'Karla', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: Max(1rem, 16px);
              line-height: Max(1, 16px);
              letter-spacing: 0.015em;
              color: $black;
              cursor: pointer;
            }
          }
        h2 {
            font-family: 'Karla', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: Max(2rem, 24px);
            line-height: Max(2.5rem, 30px);
            letter-spacing: -0.0016em;
            color: $black-100;
            span {
                color: $secondary;
            }
        }
        .submit {
            background: $secondary;
            box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
            border-radius: 8px;
            padding: 12px 24px;
            height: Max(3.25rem, 52px);
            font-family: 'Mulish', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: Max(1rem, 16px);
            line-height: Max(1.75rem, 28px);
            color: $white;
            margin-top: 1.5rem;
        }
      }
      &__buttons {
        @extend %flex-ac;
        gap: 1.25rem;
        flex-wrap: wrap;
        margin-bottom: 2.5rem;
        button {
            @extend %sh-0;
            background: $primary-20;
            border-radius: 5px;
            height: Max(2.5rem, 40px);
            padding: 10px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: Max(0.875rem, 14px);
            line-height: Max(1.375, 22px);
            letter-spacing: -0.0016em;
            color: $black-100;
            &.active {
                border: 0.5px solid $secondary;
            }
        }
      }
}
</style>