<template>
  <BootcampForm />
</template>

<script>
import BootcampForm from '@/components/Bootcamp/bootcampForm/BootcampForm.vue'
export default {
components: {
    BootcampForm
}
}
</script>

<style>

</style>