<template>
  <section class="featured">
    <div class="featured--box">
      <div class="featured--text">
        <h3>Popular Bootcamps</h3>
      </div>
      <div class="featured--cards">
        <div class="featured--card" v-for="bootcamp in bootcamps" :key="bootcamp.id">
        <Card :company="bootcamp.company"  :courses="bootcamp.courses"/>
        </div>>
      </div>
    </div>
  </section>
</template>

<script>
import Card from '@/shared/Featured.vue'
export default {
    components: {
        Card
    },
    data() {
        return {
            bootcamps: [
                {
                    id: 1,
                    company: "Alt School",
                    courses: "Courses: Front-End Development, Back-End Development, Devops, Cyber Security etc."
                },
                 {
                    id: 2,
                    company: "Alt School",
                    courses: "Courses: Front-End Development, Back-End Development, Devops, Cyber Security etc."
                },
                 {
                    id: 3,
                    company: "Aorthar Design Agency",
                    courses: "Courses: UI/UX Design, Graphics Design, Brand Identity Design."
                }
            ]
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.featured {
    padding: 1rem 7%;
}
.featured--box {
    text-align: center;
    .featured--text {
        @include heading2 {
            color: $primary;
            font-family: $karla;
        }
    }
}
.featured--card {
    @include flex(center, center);
    height: 196px;
    margin: 1rem 0;
    .featured--card--image {
        width: 176px;
        height: 196px;
        background: #8F3D55;
        border-radius: 8px 0px 0px 8px;
        @include flex(center, center);
    }
}
.featured--card--text {
    text-align: left;
    background: rgba(240, 222, 213, 0.4);
    height: 196px;
    border-radius: 0px 8px 8px 0px;
    padding: 2rem 0 0 2rem;
    h2 {
        @include body-bold {
            color: $black-100;
            font-family: $karla;
        }
    }
     h3 {
         width: 90%;
        @include body {
            color: $black;
            font-family: $karla;
        }
    }
}
.featured--card--text--star {
    @include flex(space-between, center);
    .featured--card--text--star--stars {
        @include flex(flex-start, center);
        gap: 0.2rem;
        p {
            align-self: center;
            margin: auto;
            @include label {
                color: $gray-100;
                font-family: $karla;
            }
        }
        svg {
             align-self: center;
             margin: auto;
        }
    }
    .featured--card--text--star--reviews {
        @include flex(flex-start, center);
        p {
            align-self: center;
            height:24px;
            margin: auto 1rem auto 0;
            /* margin-right: 1rem; */
            @include caption {
                color: $black-100;
                font-family: $karla;
            }
        }
        svg {
             align-self: center;
             margin: auto;
        }
    }
}
@include md {
  .featured--card--text {
    h2 {
        @include body-bold {
            font-size: 1.2rem;
        }
    }
     h3 {
         width: 80%;
        @include body {
            font-size: 1.2rem;
        }
    }
}
.featured--card {
    @include flex(center, center);
    height: auto;
    margin: 1rem 0;
    .featured--card--image {
        width: 176px;
        height: 196px;
        background: #8F3D55;
        border-radius: 8px 0px 0px 8px;
        @include flex(center, center);
    }
}
.featured--card--text--star {
    @include flex(space-between, center);
    .featured--card--text--star--stars {
        @include flex(flex-start, center);
        gap: 0.2rem;
        p {
            align-self: center;
            margin: auto;
            @include label {
                color: $gray-100;
                font-family: $karla;
            }
        }
        svg {
             align-self: center;
             margin: auto;
        }
    }
    .featured--card--text--star--reviews {
        @include flex(flex-start, center);
        p {
            align-self: center;
            height:14px;
            margin: auto 1rem auto 0;
            /* margin-right: 1rem; */
            @include label {
                color: $black-100;
                font-family: $karla;
            }
        }
        svg {
             align-self: center;
             margin: auto;
        }
    }
}
}
@include xs {
  .featured--card--text--star {
    @include flex(space-between, center);
    gap: 0.5rem;
    .featured--card--text--star--stars {
        @include flex(flex-start, center);
        flex-direction: column;
        gap: 0.2rem;
        p {
            align-self: center;
            margin: auto;
            @include label {
                color: $gray-100;
                font-family: $karla;
            }
        }
        svg {
             align-self: center;
             margin: auto;
        }
    }
    .featured--card--text--star--reviews {
        @include flex(flex-start, flex-start);
        flex-direction: column;
        gap: 0.2rem;
        p {
            align-self: flex-start;
            text-align: center;
            height: 14px;
            margin: auto;
            /* margin-right: 1rem; */
            @include label {
                font-size: 0.7rem;
                font-family: $karla;
            }
        }
        svg {

             align-self: flex-start;
             text-align: left;
        }
    }
}
.featured--card--text {
    h2 {
        @include body-bold {
            font-size: 1rem;
        }
    }
     h3 {
         width: 80%;
        @include body {
            font-size: 0.8rem;
        }
    }
}
}
</style>