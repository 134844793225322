<template>
  <section class="featured">
    <div class="featured--box">
      <div class="featured--text">
        <h3>Featured Tech Bootcamps</h3>
      </div>
      <div class="featured--cards">
        <!-- <div class="featured--card" v-for="bootcamp in bootcamps" :key="bootcamp.id"> -->
            <Card
            v-for="bootcamp in currentPosts"
            :key="bootcamp.id"
            :company="bootcamp.name"
            :logo="bootcamp.logo"
            :courses="bootcamp.tag"
            :id="bootcamp.id"
            :review="4"
            :stars="Math.ceil(bootcamp.reviews_avg_rating)"
          />
        <!-- </div> -->
      </div>
      <h6 class="more" @click="handleMove">see more...</h6>
    </div>
  </section>
</template>

<script setup>
import Card from '@/shared/Featured.vue'
import { onMounted, watch, ref } from "vue";
import { useStore, mapActions, mapGetters } from "vuex";
import { useRouter, useRoute } from "vue-router";
const router = useRouter()
const store = useStore();
mapActions(["Bootcamps", "Search"]);
const currentPosts = ref([]);
const totalArray = ref();
const review = ref()
onMounted(async () => {
  await store.dispatch("Bootcamps");
  totalArray.value = await store.getters.BootcampState;
  currentPosts.value = totalArray.value.slice(0, 6);
});
const handleMove = () => {
      if(window.sessionStorage.getItem("user") != null) {
        router.push("/curriculum/backend")
      } else {
        store.state.AccessState = 2
      }
    }
// export default {
//     components: {
//         Card
//     },
//     data() {
//         return {
//             bootcamps: [
//                 {
//                     id: 1,
//                     company: "Aorthar Design Agency",
//                     courses: "Courses: UI/UX Design, Graphics Design, Brand Identity Design.",
//                     logo: require("@/assets/aorthar.png")
//                 },
//                  {
//                     id: 2,
//                     company: "Aorthar Design Agency",
//                     courses: "Courses: UI/UX Design, Graphics Design, Brand Identity Design.",
//                     logo: require("@/assets/aorthar.png")
//                 },
//                  {
//                     id: 3,
//                     company: "Aorthar Design Agency",
//                     courses: "Courses: UI/UX Design, Graphics Design, Brand Identity Design.",
//                     logo: require("@/assets/aorthar.png")
//                 },
//                  {
//                     id: 4,
//                     company: "Aorthar Design Agency",
//                     courses: "Courses: UI/UX Design, Graphics Design, Brand Identity Design.",
//                     logo: require("@/assets/aorthar.png")
//                 },
//                  {
//                     id: 5,
//                     company: "Aorthar Design Agency",
//                     courses: "Courses: UI/UX Design, Graphics Design, Brand Identity Design.",
//                     logo: require("@/assets/aorthar.png")
//                 },
//             ]
//         }
//     }
// };
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.featured {
    padding: 1rem 7%;
}
.more {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: Max(1rem, 16px);
    line-height: 150%;
    letter-spacing: -0.16px;
    color: #A44A65;
    text-align: right;
}
.featured--box {
    text-align: center;
    .featured--text {
        @include heading2 {
            color: $secondary-80;
            font-family: $karla;
        }
    }
}
.featured--card {
    @include flex(center, center);
    height: 196px;
    margin: 1rem 0;
    .featured--card--image {
        width: 176px;
        height: 196px;
        background: #8F3D55;
        border-radius: 8px 0px 0px 8px;
        @include flex(center, center);
    }
}
.featured--card--text {
    text-align: left;
    background: rgba(240, 222, 213, 0.4);
    height: 196px;
    border-radius: 0px 8px 8px 0px;
    padding: 2rem 0 0 2rem;
    h2 {
        @include body-bold {
            color: $black-100;
            font-family: $karla;
        }
    }
     h3 {
         width: 70%;
        @include body {
            color: $black;
            font-family: $karla;
        }
    }
}
.featured--card--text--star {
    @include flex(space-between, center);
    .featured--card--text--star--stars {
        @include flex(flex-start, center);
        gap: 0.2rem;
        p {
            align-self: center;
            margin: auto;
            @include label {
                color: $gray-100;
                font-family: $karla;
            }
        }
        svg {
             align-self: center;
             margin: auto;
        }
    }
    .featured--card--text--star--reviews {
        @include flex(flex-start, center);
        p {
            align-self: center;
            height:24px;
            margin: auto 1rem auto 0;
            /* margin-right: 1rem; */
            @include caption {
                color: $black-100;
                font-family: $karla;
            }
        }
        svg {
             align-self: center;
             margin: auto;
        }
    }
}
@include md {
  .featured--card--text {
    h2 {
        @include body-bold {
            font-size: 1.2rem;
        }
    }
     h3 {
         width: 80%;
        @include body {
            font-size: 1.2rem;
        }
    }
}
.featured--card {
    @include flex(center, center);
    height: auto;
    margin: 1rem 0;
    .featured--card--image {
        width: 176px;
        height: 196px;
        background: #8F3D55;
        border-radius: 8px 0px 0px 8px;
        @include flex(center, center);
    }
}
.featured--card--text--star {
    @include flex(space-between, center);
    .featured--card--text--star--stars {
        @include flex(flex-start, center);
        gap: 0.2rem;
        p {
            align-self: center;
            margin: auto;
            @include label {
                color: $gray-100;
                font-family: $karla;
            }
        }
        svg {
             align-self: center;
             margin: auto;
        }
    }
    .featured--card--text--star--reviews {
        @include flex(flex-start, center);
        p {
            align-self: center;
            height:14px;
            margin: auto 1rem auto 0;
            /* margin-right: 1rem; */
            @include label {
                color: $black-100;
                font-family: $karla;
            }
        }
        svg {
             align-self: center;
             margin: auto;
        }
    }
}
}
@include xs {
  .featured--card--text--star {
    @include flex(space-between, center);
    .featured--card--text--star--stars {
        @include flex(flex-start, center);
        flex-direction: column;
        gap: 0.2rem;
        p {
            align-self: center;
            margin: auto;
            @include label {
                color: $gray-100;
                font-family: $karla;
            }
        }
        svg {
             align-self: center;
             margin: auto;
        }
    }
    .featured--card--text--star--reviews {
        @include flex(flex-start, center);
        flex-direction: column;
        gap: 0.2rem;
        p {
            align-self: center;
            height:14px;
            margin: auto 1rem auto 0;
            /* margin-right: 1rem; */
            @include label {
                color: $black-100;
                font-family: $karla;
            }
        }
        svg {
             align-self: flex-start;
             text-align: left;
        }
    }
}
.featured--card--text {
    h2 {
        @include body-bold {
            font-size: 1rem;
        }
    }
     h3 {
         width: 80%;
        @include body {
            font-size: 0.8rem;
        }
    }
}
}
</style>