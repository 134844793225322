<template>
  <section class="testimonials">
      <div class="bg">
        <img src="@/assets/quote.svg" alt="">
      </div>
    <div class="testimonials--box">
      <div class="testimonials--text">
        <h2>Connect with other students</h2>
        <p>See what people are saying about Zero to Hero</p>
      </div>
      <div class="testimonials--testimonial--box">
         <Slide />
      </div>
    </div>
  </section>
</template>

  <script>
  import Slide from "./Btest.vue"

  export default {
    components: {
      Slide
    },
    data() {
      return {}
      }
    }
</script>


<style lang="scss" scoped>


@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";



/* background */
.bg {
  position: absolute;
  z-index: -1;
  right: 3%;
  top: -7%;
}
.testimonials {
  padding: 3rem 7%;
  position: relative;
  height: 659px;
}
.testimonials--box {
  
  .testimonials--text {
    position: relative;
  z-index: 2;
    padding-left: 2rem;
    margin-bottom: 3rem;
    h2 {
      @include heading2 {
        color: $black-100;
        font-family: $karla;
      }
    }
    p {
      @include caption {
        color: $black-100;
        font-family: $karla;
      }
    }
  }
}
.testimonials--testimonial--box {
  /* display: flex;
  gap: 2rem;
  margin-top: 3rem;
  flex-wrap: wrap; */
  .testimonials--testimonial {
    width: 35rem;
    height: 228.38px;
    display: flex;
    margin: 2rem 0rem;
    background: $white;
    box-shadow: 5px 4px 4px rgba(143, 61, 85, 0.3);
    .testimonials--testimonial--text {
      align-self: center;
      p {
        @include caption {
          color: $black;
          font-family: $karla;
        }
      }
      h4 {
        margin-top: 3rem;
        @include flex(flex-end, flex-end);
        @include button {
          color: $black;
          font-family: $karla;
        }
      }
    }
  }
}
@include xl {
  .testimonials {
    height: auto;
  }
  .testimonials--testimonial--box {
    height: auto;
.testimonials--testimonial {
  flex-direction: column;
  width: 100%;
  height: auto;
}
  }
}
@include xs {
  .testimonials--testimonial--box {
    height: auto;
.testimonials--testimonial {
  .testimonials--testimonial--image {
    img {
      width: 100%;
    }
  }
  flex-direction: column;
  width: 100%;
  height: auto;
}
  }
}
</style>