import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './styles/global.scss'
import store from './store'
import axios from 'axios';

axios.defaults.baseURL = 'https://api-v1.zerototech.co/api/'

// axios.defaults.headers.common["Authorization"] = `Bearer` + " " + window.sessionStorage.getItem("token")

createApp(App).config.productionTip = false

createApp(App).use(store).use(router).mount('#app')
