import axios from "axios";

const state = {};

const getters = {};

const actions = {
    async AddResource({commit}, Details) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.post("project/add-resource", Details, {headers: headers})
        console.log(res.data)
         
    },
    async Resources({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get("resource/myresource",  {headers: headers})
        console.log(res.data)
         
    }
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
}