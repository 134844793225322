<template>
    <div class="review" @click.self="close">
        <div class="review__content">
            <img src="@/assets/svg/cancel.svg" height="24" width="24" alt="" class="cancel" @click="close">
            <div class="review__content__items">
                <h4>Review <span> {{ name }} </span></h4>
                <div class="review__content__info">
                    <input type="text" :value="username" readonly>
                    <div class="review__content__info__btn">
                       <button v-for="item in title" :key="item.id" class="status" @click="statusId = item.id, data.user_level= item.status" :class="{status__active: statusId === item.id}">{{ item.status }}</button>
                    </div>
                </div>
                <h6>Rating</h6>
                <div class="review__rating">
                    <div class="review__rating__item">
                        <p>Curriculum:</p>
                        <div class="icon" :class="{icon__active: item.active}" v-for="item in curr" :key="item.id" v-html="item.icon" @click="currStars(item.id)"></div>
                    </div>

                    <div class="review__rating__item">
                        <p>Job assistance:</p>
                        <div class="icon" :class="{icon__active: item.active}" v-for="item in asst" :key="item.id" v-html="item.icon" @click="asstStars(item.id)"></div>
                    </div>

                    <div class="review__rating__item">
                        <p>Instructors:</p>
                        <div class="icon" :class="{icon__active: item.active}" v-for="item in inst" :key="item.id" v-html="item.icon" @click="instStars(item.id)"></div>
                    </div>

                    <div class="review__rating__item">
                        <p>Curriculum:</p>
                        <div class="icon" :class="{icon__active: item.active}" v-for="item in curr2" :key="item.id" v-html="item.icon" @click="stars(item.id)"></div>
                    </div>
                </div>
                <a href="/" ref="dash" style="display: none"></a>
                <h6>Your review</h6>
                <p v-show="error" style="color: red; text-align: center; margin-top: 0rem; margin-bottom: 0rem; font-size: 12px; text-align: center;"> {{ MessageError }} </p>
                <textarea name="" id="" cols="30" rows="10" placeholder="100 character minimum" minlength="100" v-model="data.rating_comment"></textarea>
                <p class="reminder" v-if="authenticated">
                    You're leaving a review as <span class="info">{{ user }} Not you? Click <span class="login" @click="logout">here</span> to log out.</span>
                </p>
                <p class="reminder" v-if="!authenticated">
                    <span class="info">You must log in to submit a review.</span> <br />
                    Click <span class="login" @click="login">here</span> to log in or sign up and continue.
                </p>
                <button v-if="authenticated" class="submit" @click="handleSubmit">Submit</button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from "@vue/reactivity";
import { onMounted } from "vue";
import {useStore, mapActions, mapGetters} from "vuex";
const store = useStore()
const id = ref(null)
const dash = ref(null)
const authenticated = ref(null)
const username = ref()
mapActions(["Login", "User", "Reviews"])
onMounted(async () => {
    data.value.bootcamp_id = props.BootId
    if (window.sessionStorage.getItem("user") != null) {
        authenticated.value = true
    } else {
        authenticated.value = false
    }
    await store.dispatch("User")
  username.value = store.getters.StateOwner.name
})
const props = defineProps(["review", "email", "name", "user", "BootId"]);
const emit = defineEmits(["closeReview"])

// close popup
const close = () => {
    emit("closeReview", !props.review)
}

// toggle access stata 
const login = () => {
    close()
    store.state.AccessState = 2
}
const logout = () => {
    window.sessionStorage.setItem("user", null);
    dash.value.click();
}
// control stars for curriculum
const currStars = (number) => {
    data.value.curriculum = number;
    curr.value.map(star => {
        if (star.id <= number) {
            star.active = true;
        } else {
            star.active = false;
        }
})
}
// control stars for assistance
const asstStars = (number) => {
    data.value.job_assistance = number;
    asst.value.map(star => {
        if (star.id <= number) {
            star.active = true;
        } else {
            star.active = false;
        }
})
}
// control stars for intructions
const instStars = (number) => {
    data.value.instructors = number;
    inst.value.map(star => {
        if (star.id <= number) {
            star.active = true;
        } else {
            star.active = false;
        }
})
}
// contol stars for secind curriculum
const stars = (number) => {
    curr2.value.map(star => {
        if (star.id <= number) {
            star.active = true;
        } else {
            star.active = false;
        }
})
}

const statusId = ref(null)
const title = ref([
    {
        id: 1,
        status: "Student"
    },
    {
        id: 2,
        status: "Graduate"
    }
])
const curr = ref([
    {
        id: 1,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 2,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 3,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 4,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 5,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
])
const asst = ref([
    {
        id: 1,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 2,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 3,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 4,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 5,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
])
const inst = ref([
    {
        id: 1,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 2,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 3,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 4,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 5,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
])
const curr2 = ref([
    {
        id: 1,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 2,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 3,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 4,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
    {
        id: 5,
        active: false,
        icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`
    },
])

const data = ref({
    bootcamp_id: null,
    job_assistance: null,
    instructors: null,
    curriculum: null,
    rating_comment: "", 
    user_level: ""
})
const error = ref(true)
const MessageError = ref("")
const handleSubmit = async () => {
    try {
    await store.dispatch("Reviews", data.value)
    close()
    store.state.success = true
    setTimeout(() => {
        store.state.success = false
    }, 3000)
    } catch(error) {
    MessageError.value = error.response.data.message
    error.value = false
    }
}
</script>

<style lang="scss" scoped>
.review {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(95, 94, 94, 0.5);
    top: 0;
    left: 0;
    z-index: 4;
    @extend %grid-items;
    @media screen and (max-width: 400px) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    &__content {
        position: absolute;
        width: Min(90%, 539px);
        max-height: 594px;
        background: $white;
        padding: 1rem;
        overflow: auto;
        scroll-behavior: none;
        ::-webkit-scrollbar {
            appearance: none;
        }
        &__info {
            @extend %flex-ac;
            gap: 2rem;
            @include respondMax("mobile3x") {
                flex-direction: column;
                align-items: flex-start;
                gap: 1rem;
            }
            input[type = "text"] {
                width: Max(14rem, 224px);
                height: Max(2.5rem, 40px);
                background-color: $primary-20;
                border: 0.5px solid rgba(164, 74, 101, 0.7);
                border-radius: 5px;
                padding: 5px;
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: Max(0.875rem, 14px);
                line-height: Max(1.25rem, 20px);
                letter-spacing: -0.0016em;
                color: $black-100;
                &::placeholder {
                    font-family: 'Roboto', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: Max(0.875rem, 14px);
                    line-height: Max(1.25rem, 20px);
                    letter-spacing: -0.0016em;
                    color: $gray;
                }
                @include respondMax("mobile3x") {
                    width: 100%;
                }
            }
            .status {
                background-color: $primary-20;
                border: 0.5px solid rgba(164, 74, 101, 0.7);
                border-radius: 5px;
                height: Max(2.5rem, 40px);
                width: Max(80px, 5rem);
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: Max(0.875rem, 14px);
                line-height: Max(1.25rem, 20px);
                letter-spacing: -0.0016em;
                color: $gray;
                transition: all 0.3s ease-in-out;
                display: inline;
                &__active {
                    background-color: $secondary;
                    color: $white;
                    transition: all 0.3s ease-in-out;
                }
            }
            &__btn {
                // width: 100%;
                @extend %flex-ac;
                gap: 2rem;
            }
        }
        .cancel {
            margin-left: auto;
            @include flex(flex-end, center);
            cursor: pointer;
        }
        &__items {
            width: 85%;
            h4 {
            font-family: 'Karla', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: Max(1.25rem, 20px);
            line-height: Max(2rem, 22px);
            letter-spacing: -0.0016em;
            color: $black-100;
            span {
                color: $secondary-80;
            }
        }
        h6 {
            font-family: 'Karla', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: Max(0.875rem, 14px);
            line-height: Max(1.375rem, 22px);
            letter-spacing: -0.0016em;
            color: $black-100;
            margin-top: 1.5rem;
        }
        textarea {
            background-color: $primary-20;
            border: 0.5px solid rgba(164, 74, 101, 0.7);
            border-radius: 5px;
            resize: none;
            height: 83px;
            width: 100%;
            padding: 8px 12px;
            outline: none;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: Max(0.875rem, 14px);
            line-height: Max(1.25rem, 20px);
            letter-spacing: -0.0016em;
            color: $black-100;
            &::placeholder {
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: Max(0.875rem, 14px);
                line-height: Max(1.25rem, 20px);
                letter-spacing: -0.0016em;
                color: $gray;
            }
        }
        .reminder {
            font-family: 'Karla', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: Max(0.875rem, 14px);
            line-height: Max(1.25rem, 20px);
            letter-spacing: -0.0016em;
            color: $black;
            margin: 2rem 0;
            .info {
                font-weight: 700;
            }
            .login {
                font-weight: 400;
                color: $primary-60;
                cursor: pointer;
            }
        }
        .submit {
            background: $secondary;
            border: 0.5px solid #8F3D55;
            border-radius: 5px;
            height: Max(2.5rem, 40px);
            width: Max(80px, 5rem);
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: Max(0.875rem, 14px);
            line-height: Max(1.25rem, 20px);
            letter-spacing: -0.0016em;
            color: $white;
        }
        }
    }
    &__rating {
        background: $primary-20;
        border: 0.5px solid rgba(164, 74, 101, 0.7);
        border-radius: 5px;
        width: 100%;
        min-height: 83px;
        padding: 1.5rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @include respondMax("mobile3x") {
            grid-template-columns: 1fr;
            gap: 0.6rem
        }
        &__item {
            @extend %flex-ac;
            gap: 0.25rem;
            p {
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: Max(0.875rem, 14px);
                line-height: Max(1.25rem, 20px);
                letter-spacing: -0.0016em;
                color: $gray;
                margin-bottom: unset;
            }
            ::v-deep .icon {
                svg {
                    margin-bottom: 0.3rem;
                    cursor: pointer;
                    path {
                    // fill: #FFC100;
                    fill: #C4C4C4;
                    }
                }
                &__active {
                    svg {
                        path {
                            fill: #FFC100;
                        }
                    }
                }
            }
        }
    }
}
</style>