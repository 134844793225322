<template>
  <Frontend />
</template>

<script>
import Frontend from '@/components/Courses/Frontend/Frontend.vue'
export default {
components: {
    Frontend
}
}
</script>

<style>

</style>