<template>
  <section class="intro">
    <div class="intro_box">
          <div class="intro_box--text">
              <h2>
                  Submit  your bootcamp
              </h2>
              <p>
                  Can’t find you bootcamp? Submit your school, and get featured on our bootcamps list.
              </p>
          </div>
          <div  class="intro_box--options">
            <h3 v-for="item in items" :key="item.id" :class="{light: item.active}" @click="handleClick(item.id)">
            {{item.name}}
            </h3>
          </div>
    </div>
  </section>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    id: 1,
                    name: 'School Info ',
                    active: true
                },
                {
                    id: 2,
                    name: 'Courses',
                    active: false
                },
                {
                    id: 3,
                    name: 'Social Media',
                    active: false
                },
                 {
                    id: 4,
                    name: 'Course Details',
                    active: false
                }
            ]
        }
    },
    methods: {
        handleClick(number) {
            this.items.map((newItem) => {
                if(newItem.id === number) {
                    newItem.active = true
                }
                else {
                    newItem.active = false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.intro {
    padding: 4rem 7% 0rem 7%;
    background: linear-gradient(180deg, #F0DED5 0%, rgba(240, 222, 213, 0) 100%);
}
.intro_box--text {
    max-width: 100vw;
    text-align: center;
    h2 {
        width: min(90%, 621px);
        margin: 0 auto;
        @include title1 {
            font-family: $karla;
            color: $black-100;
        }
    }
    p {
        width: min(90%, 714px);
        margin: 0.5rem auto;
        @include caption {
            font-family: $karla;
            color: $black-100;
        }
    }
}
.intro_box--options {
    --gap-space: 1.3rem;
    margin-top: 4rem;
    @include flex(flex-end, center);
    flex: 0 0 0;
    gap: var(--gap-space);
    h3 {
        cursor: pointer;
        @include profile {
            color: #9F9F9F;
        }
    }
     .light {
        cursor: pointer;
            @include profile {
            color: #B86B41;
        }
        }
}
@include md {
    .intro_box--text {
    h2 {
        width: min(90%, 621px);
        margin: 0 auto;
        @include title1 {
            font-size: 2rem;
        }
    }
    p {
        width: min(90%, 714px);
        margin: 0.5rem auto;
        @include caption {
            font-family: $karla;
            color: $black-100;
        }
    }
}
.intro_box--options {
    --gap-space: 1.3rem;
    margin-top: 4rem;
    @include flex(flex-end, center);
    flex: 0 0 0;
    gap: var(--gap-space);
    h3 {
        cursor: pointer;
        @include profile {
            font-size: 0.9rem;
            color: #9F9F9F;
        }
    }
     .light {
        cursor: pointer;
            @include profile {
                font-size: 0.9rem;
            color: #B86B41;
        }
        }
}
}
@include breakpoint(400px) {
    .intro_box--text {
    h2 {
        width: min(90%, 621px);
        margin: 0 auto;
        @include title1 {
            font-size: 1.5rem;
        }
    }
    p {
        width: min(90%, 714px);
        margin: 0.5rem auto;
        @include caption {
            font-size: 0.85rem;
        }
    }
}
}
</style>