<template>
  <Hero />
  <Plan />
  <Students />
</template>

<script>
import Hero from '@/components/Premium/Hero.vue'
import Plan from '@/components/Premium/Plan.vue'
import Students from '@/components/Testimonials.vue'
export default {
components: {
    Hero,
    Plan,
    Students
}
}
</script>

<style>

</style>