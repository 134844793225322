<template>
  <section class="faq">
    <div class="faq--box">
      <div class="faq--text">
        <h2>Frequently Asked Questions</h2>
       <img src="@/assets/svg/faq.svg" width="25" height="28" alt="" />
      </div>
      <div class="faq--questionss" v-for="faq in faqs" :key="faq.id">
        <div class="faq--questions">
          <div
            class="faq--question"
            @click="handleToggle(faq.id, faq.isActive)"
          >
            <h2>
              {{ faq.content }}
            </h2>
            <svg
              class="click"
              :class="{ active: faq.isActive }"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"
                fill="#9F9F9F"
              />
            </svg>
          </div>
          <div class="faq--answer" v-if="faq.isActive">
            <div
              class="text"
              v-for="item in faq.answer"
              :key="item.title"
              v-html="item.title"
            ></div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    handleToggle(number, active) {
      this.faqs.map((newFaq, id) => {
        if (newFaq.id === number) {
          newFaq.isActive = !newFaq.isActive;
        }
      });
    },
  },
  data() {
    return {
      faqs: [
        {
          id: 1,
          content: "What is a Bootcamp?",
          answer: [
            {
              title:
                "<p>A bootcamp is an intensive, short-term learning program that focuses on teaching specific skills in a particular field, such as coding or data science. It is designed to provide students with a fast-paced, immersive learning experience that prepares them for entry-level jobs in that field.</p>",
            },
          ],
          isActive: false,
        },
        {
          id: 3,
          content: "What is the best programming language to learn?",
          answer: [
            {
              title:
                "<p>The best programming language to learn depends on your goals, interests, and the type of tech career or project you want to work on. Here are a few popular programming languages and their common uses:</p>",
            },
            {
              title:
                "<p>1. <span>Python</span>: Python is a versatile and easy-to-learn language that is widely used in data science, machine learning, web development, and scientific computing.</p>",
            },
            {
              title:
                "<p>2. <span>JavaScript</span>: JavaScript is the most widely-used language for web development, and is essential for creating dynamic, interactive web pages and web applications.</p>",
            },
            {
              title:
                "<p>3. <span>Java</span>: Java is a popular language for developing enterprise-level software, as well as Android mobile apps.</p>",
            },
            {
              title:
                "<p>4. <span>C#</span>: C# is a language used for Windows desktop and game development, as well as web development with the .NET framework.</p>",
            },
            {
              title:
                "<p>5. <span>Swift</span>: Swift is a language used for developing iOS and macOS applications.</p>",
            },
          ],
          isActive: false,
        },
        {
          id: 4,
          content: "How long does it take to get admitted into a Bootcamp?",
          answer: [
            {
              title:
                "<p>The admission process for a bootcamp can vary depending on the program and the specific requirements of the bootcamp. Some bootcamps have rolling admissions and can admit students on a continuous basis, while others may have specific application deadlines and only admit students a few times a year.</p>",
            },
            {
              title:
                "<p>Typically, the admission process involves filling out an application form, submitting relevant documents, and potentially completing an interview or technical assessment. The length of time it takes to get admitted into a bootcamp can also vary based on how quickly you complete these steps and how long it takes the bootcamp to review</p>",
            },
          ],
          isActive: false,
        },
        {
          id: 5,
          content: "Is 'Zero to Tech' a bootcamp?",
          answer: [
            {
              title:
                "<p>Our platform differs from a bootcamp in that we do not offer intensive training programs. Instead, our main focus is to provide the essential resources required to initiate a career in the tech industry. Additionally, we provide micro-learning opportunities where industry experts teach specific skill sets in their area of specialization. Our micro-learning courses are designed as short videos, unlike conventional online courses, to complement your self-taught learning or bootcamp experience.</p>",
            },
          ],
          isActive: false,
        },
        {
          id: 2,
          content: "How to I get financial  help to fund  my  bootcamp courses",
          answer: [
            {
              title:
                "<p>There are several ways to get financial help to fund your bootcamp courses:</p>",
            },
            {
              title:
                "<p> <span>Scholarships</span>: Many bootcamps offer scholarships to help students cover the cost of tuition. These scholarships may be merit-based or need-based, and the application process can vary depending on the bootcamp.</p>",
            },
            {
              title:
                "<p> <span>Loans</span>: Some bootcamps offer financing options, such as loans, to help students cover the cost of tuition. Additionally, there are a number of private lenders that offer loans specifically for bootcamp students.</p>",
            },
            {
              title:
                "<p> <span>Employer sponsorship</span>: If you're currently employed, your employer may be willing to sponsor your bootcamp course as part of your professional development. This can be a great way to get financial help while also gaining new skills that can benefit your employer.</p>",
            },
            {
              title:
                "<p> <span> Crowdfunding</span>: You can also consider crowdfunding platforms, such as GoFundMe, to raise money to cover the cost of your bootcamp. This approach may be particularly effective if you have a strong network of friends, family, or colleagues who are willing to support your education.</p>",
            },
            {
              title:
                "<p> <span> Income-share agreements</span>: Some bootcamps offer income-share agreements (ISAs), which allow students to defer payment until after they've completed the course and started earning a certain level of income. This can be a good option for students who don't have the financial resources to pay upfront but are confident in their ability to succeed in the field.</p>",
            },
          ],
          isActive: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.faq {
  padding: 3rem 7%;
  .faq--box {
    text-align: center;
    .faq--text {
      display: flex;
      justify-content: center;
      position: relative;
      width: Min(650px, 90%);
      margin: 0 auto;
      h2 {
        @include heading2 {
          color: $secondary-80;
          font-family: $karla;
        }
      }
      svg {
        position: absolute;
        top: 1%;
        right: -2%;
      }
    }
    .faq--questionss {
      @include flex(center, flex-start);
      width: 850px;
      margin: 0 auto;
    }
    .faq--question {
      @include flex(space-between, center);
      width: 100%;
      height: 25px;
      margin-top: 2rem;
      h2 {
        @include body {
          color: $black-100;
          font-family: $karla;
        }
      }
    }
    .faq--answer {
      :deep(.text) {
        text-align: left;
        margin-top: 1rem;
        span {
          font-weight: 700;
        }
        p {
          @include caption {
            color: $black-100;
            font-family: $karla;
          }
        }
      }
    }
  }
}
hr {
  width: 850px;
  margin: 0 auto;
  border: 1.5px solid #a44a65;
}
/* to rotate svg */
.click {
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  flex-shrink: 0;
}
.active {
  transform: rotate(45deg);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
@include breakpoint(850px) {
  .faq {
    padding: 3rem 7%;
    .faq--box {
      text-align: center;
      .faq--text {
        display: flex;
        justify-content: center;
        h2 {
          @include heading2 {
            font-size: 2.5rem;
          }
        }
      }
      .faq--questionss {
        @include flex(center, flex-start);
        width: 90vw;
        margin: 0 auto;
      }
      .faq--question {
        @include flex(space-between, center);
        width: 100%;
        height: auto;
        margin-top: 2rem;
        h2 {
          text-align: left;
          @include body {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
  hr {
    width: 90vw;
    margin: 0 auto;
    border: 1.5px solid #a44a65;
  }
}
@include xs {
  .faq {
    padding: 3rem 7%;
    .faq--box {
      text-align: center;
      .faq--text {
        display: flex;
        justify-content: center;
        h2 {
          @include heading2 {
            font-size: 1.5rem;
          }
        }
        svg {
          top: -10%;
        }
      }
      .faq--questionss {
        @include flex(center, flex-start);
        width: 90vw;
        margin: 0 auto;
      }
      .faq--question {
        @include flex(space-between, center);
        width: 100%;
        height: auto;
        margin-top: 2rem;
        h2 {
          text-align: left;
          @include body {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
  .faq--answer {
    p {
      @include caption {
        color: $black-100;
        font-family: $karla;
        font-size: 0.9rem;
      }
    }
  }
}
</style>
