<template>
  <Bootcamp />
</template>

<script>
import Bootcamp from '@/components/Bootcamp/Bootcamps/Bootcamps.vue'
export default {
components: {
    Bootcamp
}
}
</script>

<style>

</style>