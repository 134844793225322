import axios from "axios"

const state = {
    posts: null,
    postDetails: null
};

const getters = {
    PostState: state => state.posts,
    DetailsPost: state => state.postDetails
};

const actions = {
    async PostBlog({commit}, Post) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.post("post/create", Post, {headers: headers})
         
    },
    async GetPost({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get("post", {headers: headers})
         
        let getpost = res.data.data.data
        await commit("setPosts", getpost)
    },
    async PostDetails({commit}, Id) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`post/${Id}`, {headers: headers})
        let details = res.data.data.post
        await commit("setDetails", details)
    }
};

const mutations = {
    setPosts(state, getpost) {
        state.posts = getpost
    },
    setDetails(state, details) {
        state.postDetails = details
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}