<template>
  <nav>
    <div class="nav--logo">
      <router-link to="/">
        <img src="@/assets/logo.png" width="90" height="69" alt="" />
      </router-link>
      <h4>Zero to Tech</h4>
    </div>
    <div class="menu">
      <h4 @click="openSignUp">Login</h4>
      <div class="box--wrap" :class="{ active: active }" @click="handleActive">
        <div class="box"></div>
        <div class="box"></div>
        <div class="box"></div>
      </div>
    </div>
    <ul class="nav--list" :class="{ ulactive: active }">
      <li class="test">
        <router-link to="/test"> <button class="test--btn"> Take a Test </button> </router-link>
      </li>
      <li class="tech">
        <p  @click="openDrop">
        Zero to Tech
        </p>
        <svg
        class="drop"
          @click="openDrop"
          width="15"
          height="8"
          viewBox="0 0 15 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.462693 0.292893C0.853217 -0.0976311 1.48638 -0.0976311 1.87691 0.292893L7.1698 5.58579L12.4627 0.292893C12.8532 -0.0976311 13.4864 -0.0976311 13.8769 0.292893C14.2674 0.683418 14.2674 1.31658 13.8769 1.70711L7.87691 7.70711C7.48638 8.09763 6.85322 8.09763 6.46269 7.70711L0.462693 1.70711C0.0721687 1.31658 0.0721687 0.683418 0.462693 0.292893Z"
            fill="#8F3D55"
          />
        </svg>
        <div class="dropdown" v-if="dropdown">
          <ul class="dropdown--list">
            <li>
              <router-link to="/careerpath">Careers In Tech</router-link>
            </li>
            <li><router-link to="/techpersonality">Tech Personality</router-link></li>
            <li><router-link to="/bootcamp">Bootcamps</router-link></li>
            <li><router-link to="/curriculum">Tech Curriculum</router-link></li>
            <li><router-link to="/blog">Blog</router-link></li>
          </ul>
        </div>
      </li>
      <li class="signup" @click="openSignUp">Sign In</li>
      <div class="hidden">
        <button>
          <router-link to="/test">
          Take a Test
          </router-link>
        </button>
          <button @click="openSignUp">
            Sign Up
          </button>
      </div>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    signup: {
      type: Boolean,
    },
    login: {
      type: Boolean,
    },
  },
  data() {
    return {
      dropdown: false,
      active: false,
    };
  },
  methods: {
    openSignUp() {
      this.$store.state.AccessState = 2
      handleActive()
    },
    openLogin() {
      this.$emit("loginopen", !this.login);
    },
    openDrop() {
      this.dropdown = !this.dropdown;
    },
    handleActive() {
      this.active = !this.active;
      this.dropdown = !this.dropdown;
      const Nav = document.querySelector('.box--wrap')
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.hidden {
  display: none;
}
nav {
  height: 5rem;
  background: $white;
  position: relative;
  z-index: 3;
  box-shadow: 0px 4px 10px rgba(164, 74, 101, 0.15);
  padding: 0 5%;
  @include flex(space-between, center);
}
.menu {
  display: none;
}
.nav--logo {
  display: flex;
  grid-gap: 0.2rem;

  h4 {
    @include nav;
    align-self: center;
  }
}
.nav--list {
  display: flex;
  grid-gap: 2rem;
  align-items: center;
}
li {
  cursor: pointer;
  @include caption {
    color: $secondary-80;
    font-family: "mullish", sans-serif;
  }
  &.tech {
    display: flex;
    gap: 0.2rem;
    margin: auto 0;
    height: 51px;
    p {
      margin: auto 0;
    }
    svg {
      align-self: center;
    }
  }
}

.dropdown {
  height: 260px;
  width: 250px;
  border-radius: 10px;
  padding: 1rem 2rem;
  background: $secondary-80;
  position: absolute;
  z-index: 3;
  right: 5%;
  top: 3.5rem;
  ul {
    li {
      color: $white;
      margin: 1rem 0;
    }
  }
}
@include xl {
  .nav--logo {
    h4 {
      @include nav {
        font-size: 1rem;
      }
    }
  }
  .nav--list {
    display: flex;
    grid-gap: 0.7rem;
  }
  li {
    cursor: pointer;
    @include caption {
      color: $secondary-80;
      font-family: "mullish", sans-serif;
      font-size: 16px;
    }
  }
}
@include md {
  .menu {
    display: flex;
    gap: 1rem;
    position: relative;
    h4 {
      align-self: center;
      margin: 0 1rem;
      cursor: pointer;
      @include caption {
        color: $secondary-80;
        font-family: "mullish", sans-serif;
      }
    }
  }
  .box--wrap {
    display: inline-block;
    margin-right: 0.8rem;
    cursor: pointer;
    order: 2;
    z-index: 6;
  }
  .box {
    height: 4px;
    width: 25px;
    margin: 5px auto;
    background-color: $secondary;
    transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .active {
    .box:nth-child(1) {
      transform: rotate(45deg) translate(12px, 12px);
    }
    .box:nth-child(2) {
      visibility: hidden;
    }
    .box:nth-child(3) {
      transform: rotate(-45deg) translate(15px, -16px);
    }
    .menu {
    h4 {
      opacity: 0;
      @include caption {
        color: $secondary-80;
        font-family: "mullish", sans-serif;
      }
    }
  }
  body {
    overflow: hidden;
  }
  }
  .nav--list {
    position: fixed;
    display: inline-block;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 4;
    background-color: $white;
    overflow-x: hidden;
    padding: 0.7rem;
    color: $black-50;
    opacity: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
    transition: all 0.6s ease-in-out;
    transform: translateX(100%);
  }
  li {
    cursor: pointer;
    @include caption {
      color: $black-50;
      font-family: "mullish", sans-serif;
    }
    &.test {
    order: 3;
    height: 42px;
    background: $secondary-80;
    width: 8rem;
    @include flex(flex-end, center);
    @include button {
      color: $white;
    }
    align-self: flex-start;
    display: none;
  }
  &.tech {
    order: 1;
    p,svg {  
    display: none;
    }
    .dropdown {
  height: 250px;
  width: 100%;
  border-radius: 10px;
  padding: 1rem 0.2rem;
  background: transparent;
  position: absolute;
  z-index: 3;
  right: 1%;
  ul {
    li {
      @include body {
        font-family: $karla;
      color: $black-50;
      }
      margin: 1.5rem 0;
    }
  }
}
  }
  &.signup {
    order: 2;
    height: 42px;
    background: $secondary-80;
    width: 8rem;
    @include flex(center, center);
    @include button {
      color: $white;
    }
    margin: 0.5rem 0;
    align-self: flex-start;
    display: none;
  }
  }
  .ulactive {
    opacity: 1;
    transform: translateX(0);
  }
  .hidden {
  display: flex;
  padding-bottom: 70%;
  button {
     height: 42px;
    background: $secondary-80;
    width: 9rem;
    border: none;
    border-radius: 30px;
    @include flex(center, center);
    @include button {
      color: $white;
    }
    margin: 0.5rem 0.5rem;
  }
}
}
@include xs {
  .nav--logo {
    justify-content: space-between;
    h4 {
      @include nav {
        font-size: 1rem;
      }
    }
  }
  li {
    font-size: 16px;
  }
  nav {
    padding: 0 0.2rem;
  }
}
</style>