<template>
<section class="price">
    <div class="price--box">
        <div class="price--plan">
            <div class="price--plan--text">
                <h3>
                    Average Course Price
                </h3>
            </div>
            <div class="price--plan--number">
                <h3>
                    #59,999
                </h3>
                <router-link to="/bootcamp">
                <button class="button--premium">
                    Check Schools
                </button>
                </router-link>
            </div>
        </div>
         <!-- <div class="profile--box">
          <div class="profile--text">
              <h2>
                  Course Instructors and Mentors
              </h2>
          </div>
          <div class="profile--images">
              <div class="profile--images--details">
                  <img src="@/assets/Personality/Profile/graphics.png" alt="">
                  <h4>
                      Instructor 1
                  </h4>
                  
              </div>
              <div class="profile--images--details">
                  <img src="@/assets/Personality/Profile/backend.png" alt="">
                  <h4>
                      Instructor 2
                  </h4>
              </div>
              <div class="profile--images--details">
                  <img src="@/assets/Personality/Profile/writer.png" alt="">
                  <h4>
                      Mentor  1
                  </h4>
              </div>
              <div class="profile--images--details">
                  <img src="@/assets/Personality/Profile/blockchain.png" alt="">
                  <h4>
                      Mentor  2
                  </h4>
              </div> 
          </div>
      </div> -->
    </div>
</section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.price--box {
    background: linear-gradient(180deg, #F0DED5 -18.15%, rgba(240, 222, 213, 0) 100%);
}
.price--box {
    padding: 3rem 7%;
    text-align: center;
}
.price--plan {
    @include flex(space-between, center);
    padding: 56px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(118, 111, 113, 0.25);
    border-radius: 12px;
    width: min(800px, 90%);
    margin: 0 auto;
    .price--plan--text {
        h3 {
            width: 85%;
            text-align: center;
            @include heading2 {
                line-height: 3.5rem;
                color: $black-100;
                font-family: $karla;
            }
        }
    }
    .price--plan--number {
        h3 {
            margin-bottom: 1.5rem;
            @include heading2 {
                color: $black-100;
                font-family: $karla;
            }
        }
    }
}
.profile--box {
    padding: 4rem 7%;
    text-align: center;
}
.profile--text {
    h2 {
        font-family: 'Karla';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 90%;
        letter-spacing: -0.035em;
        color: $secondary-80;
        margin-bottom: 4rem;
    }
}
.profile--images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 2rem;
    row-gap: 2rem;
    place-items: center;

    img {
        margin-bottom: 2rem;
    }
    h4 {
        margin-bottom: 1.5rem;
        @include profile {
            color: $black-50;
        }
    }
    p {
        @include profile-p {
            color: $black;
        }
    }
}
@include md {
    .profile--text {
    h2 {
        font-size: 1.5rem;
    }
}
.price--plan {
    flex-wrap: wrap;
    justify-content: center;
    .price--plan--text {
        h3 {
            width: 100%;
            text-align: center;
            @include heading2 {
                font-size: 1.8rem;
                line-height: 110%;
            }
        }
    }
    .price--plan--number {
        margin-top: 1.5rem;
        h3 {
            @include heading2 {
                font-size: 1.5rem;
            }
        }
    }
}
@include sm {
     .price--plan--text {
        h3 {
            @include heading2 {
                font-size: 1.2rem;
            }
        }
    }
    .price--plan--number {
        h3 {
            @include heading2 {
                font-size: 1rem;
            }
        }
    }
}
}
</style>