<template>
 <Intro />
 <Featured />
 <Faq />
</template>

<script>
import Intro from "./Intro.vue"
import Featured from "./Featured.vue"
import Faq from "../../../shared/Faq.vue"
export default {
components: {
    Intro,
    Featured,
    Faq
}
}
</script>

<style>

</style>