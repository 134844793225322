<template>
  <section class="mission">
      <div class="mission--box">
          <div class="mission--text">
              <h3>
                  Our Mission
              </h3>
              <p>
                There is a massive pool of young and passionate entry-level professionals/beginners at the bottom of most tech careers, with just a few of those professionals making it to the senior level. The reason? Many beginners are often overwhelmed by the abyss of learning resources with no clear understanding of the right resources or pathway. For such people, their careers have stagnated, and many have given up on their dreams. Our goal is to put an end to that.
                Equipped with the best resources and tools, we are here to provide the building blocks for a successful and excellent career in tech in the most detailed yet, simplified way. Our style of knowledge delivery is designed to help beginners and intermediate professionals experience remarkable career growth at a personalised pace.
                With us, you can grow into that huge role you’ve always dreamt of and move from zero to a tech expert.
                
              </p>
          </div>
      </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.mission {
    padding: 4rem 7% 3rem 7%;

    .mission--box {
        text-align: center;

        .mission--text {
            h3 {
                margin: 2rem 0;
                @include heading2 {
                    color: $black-50;
                    font-family: $karla;
                }
            }
            p {
                padding: 0 10rem;
                @include body {
                    color: $gray-100;
                    font-family: $karla;
                }
            }
        }
    }
}
@include xl {
    .mission{
    .mission--box {
        .mission--text {
            width: min(90%, 750px);
            margin: 0 auto;
             h3 {
                 padding: 2rem 0;
            @include heading1 {
            }
        }
        p {
            padding: 0rem;
        }
        button {
            margin-bottom: 3rem;
        }
        }
    }
}
}
@include breakpoint(675px) {
    .mission {
        padding: 2rem 7% 0rem 7%;
    .mission--box {
        .mission--text {
             h2 {
                 padding: 0rem 0;
            @include heading1 {
                font-size: 1.5rem;
            }
        }
        button {
            margin-bottom: 2rem;
        }
        }
    }
}
}
@include breakpoint(475px) {
    .mission {
    .mission--box {
        .mission--text {
             h3 {
            @include heading1 {
                font-size: 1.5rem;
            }
        }
        p {
            @include body {
                    font-size: 1rem;
                }
        }
        button {
            margin-bottom: 2rem;
        }
        }
    }
}

}
</style>