<template>
  <BootcampCompareDetails />
</template>

<script>
import BootcampCompareDetails from '@/components/Bootcamp/BootcampCompare/BootcampCompareDetails/Details.vue'
export default {
components: {
    BootcampCompareDetails
}
}
</script>

<style>

</style>