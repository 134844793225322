<template>
  <section class="form">
    <div class="flex_col form_box">
        <div class="form_guidelines">
            <div class="form_guidelines--content">
                <div class="form_guidelines--contentImg">
                <div role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100" style='--value:50'></div>
                </div>
                <div class="form_guidelines--contentText">
                <h6>
                    Guidelines:
                </h6>
                <p>
                    FIll in details about your school, share with your colleagues and friends. 
                    We will feature your bootcamp once we get 10 reviews above 3.0 rating on the profile.
                </p>
                <p>
                    Featuring your school is a good way to market your courses and make it easier for 
                    prospective student to see what you have to offer.
                </p>
                </div>
            </div>
        </div>
        <div class="form_information">
            <Information />
            <Featured />
            <Filter />
        </div>
    </div>
  </section>
</template>

<script>
import Information from './Information.vue';
import Filter from '@/components/Bootcamp/BootcampCompare/BootcampCompareDetails/Filter.vue';
import Featured from './Featured.vue';
export default {
    components: {
        Information,
        Filter,
        Featured
    },
    data() {
        return {
            count: 50
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.form {
    padding: 2.5rem 7%;
    --space-gap: 1rem;
    --space-gap2: 2rem;
}
.form_guidelines {
    // opacity: 0.5;
    border: 0.5px solid rgba(159, 159, 159, 0.2);
    border-radius: 8px;
    width: 326px;
    height: auto;
    padding: var(--space-gap2) var(--space-gap);
    .form_guidelines--content {
        .form_guidelines--contentImg {
            margin: 1rem 2rem;
        @keyframes growProgressBar {
  0%, 33% { --pgPercentage: 0; }
  100% { --pgPercentage: var(--value); }
}

// @property --pgPercentage {
//   syntax: '<number>';
//   inherits: false;
//   initial-value: 0;
// }

div[role="progressbar"] {
  --size: 12rem;
  --fg: #A44A65;
  --bg: #DAADBB;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: 
    radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0)
    ;
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 5);
  color: $black;
  font-family: $karla;
}

div[role="progressbar"]::before {
  counter-reset: percentage var(--value);
  content: counter(percentage) '%';
}
        }
        .form_guidelines--contentText {
        h6 {
            @include sub-heading-medium {
                color: $primary;
                font-family: $karla;
            }
        }
        p {
            width: min(90%, 209px);
            @include body {
                color: $black;
                font-family: $karla;
            }
        }
    }
    }
}
.form_information {
    // opacity: 0.5;
    border: 0.5px solid rgba(159, 159, 159, 0.2);
    border-radius: 8px;
    width: min(90%, 920px);
    height: auto;
    padding: var(--space-gap2) var(--space-gap);
}
.form_box {
    gap: var(--space-gap);
}
@include xl {
    .form_box {
    flex-direction: column;
    align-items: center;
}
.form_information {
     width: 100%;
}
.form_guidelines {
    width: 100%;
    height: 330px;
    margin: 0 auto;
    padding: var(--space-gap2) var(--space-gap);
    .form_guidelines--content {
        @include flex(spece-between, flex-start);
        gap: var(--space-gap2);
        .form_guidelines--contentText {
            height: 80%;
        h6 {
            @include sub-heading-medium {
                color: $primary;
                font-family: $karla;
            }
        }
        p {
            width: min(90%, 890px);
            @include caption {
                color: $black;
                font-family: $karla;
            }
        }
    }
    }
}
}
@include md {
    .form_guidelines {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: var(--space-gap2) var(--space-gap);
    .form_guidelines--content {
        flex-direction: column;
        align-items: center;
        gap: var(--space-gap2);
        .form_guidelines--contentText {
            height: 80%;
        h6 {
            width: 100%;
            @include sub-heading-medium {
                color: $primary;
                font-family: $karla;
            }
        }
        p {
            width: 100%;
            @include caption {
                color: $black;
                font-family: $karla;
            }
        }
    }
    }
}
}
</style>