<template>
  <Intro />
  <Mission />
  <Team />
</template>

<script>
import Intro from '@/components/Company/About/Intro.vue';
import Mission from '@/components/Company/About/Mission.vue';
import Team from '@/components/Company/About/Team.vue'
export default {
    components: {
        Intro,
        Mission,
        Team
    }
}
</script>

<style>

</style>