<template>
  <section class="camps">
    <div class="camps--box">
      <div class="camps--button">
          <div class="camps--button--text">
              <h6>Filter by:</h6>
          </div>
        <div class="camps--buttons--button">
          <button>React</button>
          <button>figma</button>
          <button>UI/UX</button>
          <button>PYTHON</button>
          <button>women in tech</button>
          <button>LARAVEL</button>
          <button>DATA</button>
        </div>
      </div>
      <div class="container">
         <div class="svg one">
          <svg
            @click="canLeft ? moveLeft() : null"
            width="28"
            height="32"
            viewBox="0 0 28 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="20.3872"
              y="1.41992"
              width="2.84105"
              height="24.8592"
              transform="rotate(46.1479 20.3872 1.41992)"
              fill="#A44A65"
            />
            <rect
              x="25.1587"
              y="29.1191"
              width="2.84105"
              height="24.8592"
              transform="rotate(116.417 25.1587 29.1191)"
              fill="#A44A65"
            />
          </svg>
      </div>
      <div ref="container" class="camps--slide">
        <div ref="inner" class="inner">
          <div
            class="camp--slide--box"
            ref="card"
            v-for="camp in Communities"
            :key="camp.id"
          >
            <div class="camp--slide--image">
              <a href="">
              <img :src="camp.logo" alt="" width="162" height="162" />
              </a>
            </div>
            <div class="camp--slide--text">
              <h2>
                {{ camp.name }}
              </h2>
              <p>
                {{ camp.description }}
              </p>
              <div class="footer--social--followUs--media">
                <a href="">
                <svg
                  width="8"
                  height="16"
                  viewBox="0 0 8 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.51997 2.63607H7.96932V0.111793C7.71927 0.0773953 6.85932 0 5.85781 0C3.76814 0 2.33665 1.3144 2.33665 3.73019V5.95349H0.0306702V8.77544H2.33665V15.876H5.1639V8.7761H7.37661L7.72787 5.95415H5.16324V4.01C5.1639 3.19438 5.38352 2.63607 6.51997 2.63607Z"
                    fill="#343439"
                  />
                </svg>
                </a>
                <a href="">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.5556 1.881C18.8283 2.2 18.0534 2.41144 17.2456 2.51411C18.0767 2.01789 18.711 1.23811 19.0092 0.298222C18.2343 0.760222 17.3788 1.08656 16.467 1.26867C15.7312 0.485222 14.6826 0 13.5386 0C11.319 0 9.53211 1.80156 9.53211 4.01011C9.53211 4.32789 9.559 4.63344 9.625 4.92433C6.292 4.76178 3.34278 3.16433 1.36156 0.730889C1.01567 1.331 0.812778 2.01789 0.812778 2.75733C0.812778 4.14578 1.52778 5.37656 2.59356 6.08911C1.94944 6.07689 1.31756 5.88989 0.782222 5.59533C0.782222 5.60756 0.782222 5.62344 0.782222 5.63933C0.782222 7.58756 2.17189 9.20578 3.99422 9.57856C3.66789 9.66778 3.31222 9.71056 2.94311 9.71056C2.68644 9.71056 2.42733 9.69589 2.18411 9.64211C2.70356 11.2298 4.17756 12.397 5.93022 12.4349C4.56622 13.5019 2.83433 14.1448 0.959444 14.1448C0.630667 14.1448 0.315333 14.1301 0 14.0898C1.77589 15.235 3.88056 15.8889 6.15022 15.8889C13.5276 15.8889 17.5609 9.77778 17.5609 4.48067C17.5609 4.30344 17.5548 4.13233 17.5462 3.96244C18.3419 3.39778 19.0104 2.69256 19.5556 1.881Z"
                    fill="#343439"
                  />
                </svg>
                </a>
                <a href="">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5 0H11C13.761 0 16 2.239 16 5V11C16 13.761 13.761 16 11 16H5C2.239 16 0 13.761 0 11V5C0 2.239 2.239 0 5 0ZM11 14.5C12.93 14.5 14.5 12.93 14.5 11V5C14.5 3.07 12.93 1.5 11 1.5H5C3.07 1.5 1.5 3.07 1.5 5V11C1.5 12.93 3.07 14.5 5 14.5H11Z"
                    fill="#343439"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4 8C4 5.791 5.791 4 8 4C10.209 4 12 5.791 12 8C12 10.209 10.209 12 8 12C5.791 12 4 10.209 4 8ZM5.5 8C5.5 9.378 6.622 10.5 8 10.5C9.378 10.5 10.5 9.378 10.5 8C10.5 6.621 9.378 5.5 8 5.5C6.622 5.5 5.5 6.621 5.5 8Z"
                    fill="#343439"
                  />
                  <circle cx="12.3" cy="3.69999" r="0.533" fill="#343439" />
                </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
       <div class="svg two">
          <svg
            @click="canRight ? moveRight() : null"
            class="right"
            width="28"
            height="32"
            viewBox="0 0 28 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="20.3872"
              y="1.41992"
              width="2.84105"
              height="24.8592"
              transform="rotate(46.1479 20.3872 1.41992)"
              fill="#A44A65"
            />
            <rect
              x="25.1587"
              y="29.1191"
              width="2.84105"
              height="24.8592"
              transform="rotate(116.417 25.1587 29.1191)"
              fill="#A44A65"
            />
          </svg>
        </div>
        </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { Draggable } from 'gsap/Draggable'
import { onMounted, computed } from 'vue';
import { useStore, mapActions, mapGetters } from "vuex";
gsap.registerPlugin(Draggable)
export default {
  computed: {
        ...mapGetters({Communities: "stateCommunity"}),
    },
  mounted() {
    const width = this.$refs.inner.scrollwidth;
    if (this.initial === 0) {
      this.canLeft = false;
    }
    // this.initial = 0
    // // gsap slider
    // const {container, inner, card} = this.$refs
    // var sections = card.length;
    // var boxWidth = container.getBoundingClientRect().width
    // // draggable
    // Draggable.create(inner, {
    //   type: "x",
    //   edgeResistance: 0.5,
    //   bounds: width,
    //   throwProps: true,
    //   onDrag: this.addMove(),
    //   // onThrowUpdate: this.moveLeft(),
    //   snap: this.moveLeft()
    // })
  },
  methods: {
    ...mapActions(["Community"]),
    addMove() {
      this.initial = this.initial + 242;
    },
    moveLeft() {
      //    reset()
      this.canRight = true;
      if (this.initial === 0) {
        this.canLeft = false;
      }
      this.initial = this.initial + 242;
      gsap.to(".inner", {
        x: this.initial,
      });
      if (this.initial === 0) {
        this.canLeft = false;
      }
    },
    moveRight() {
      //    reset()
      this.canLeft = true;
      const width = this.$refs.inner.offsetWidth;
      if (this.initial <= -1240) {
        this.canRight = false;
      }
      this.initial = this.initial - 242;
      gsap.to(".inner", {
        x: this.initial,
      });
      if (this.initial <= -1240) {
        this.canRight = false;
      }
      //    if(this.initial === -450) {
      //     this.initial = 0
      // }
    },
    reset(e) {
      this.initial = 0;
    },
  },
  data() {
    return {
      initial: 0,
      canLeft: true,
      canRight: true,
      camps: [
        {
          id: 1,
          name: "SheCodeAfrica",
          content:
            "Focused on celebrating and empowering young Girls and Women in Technology across Africa.",
          image: "shecodeafrica.svg",
          web: "https://shecodeafrica.org",
          twitter: "https://www.twitter.com/SheCodeAfrica",
          instagram: "https://www.instagram.com/shecodeafrica",
          facebook: "https://www.facebook.com/shecodeafrica"
        },
        {
          id: 2,
          name: "GDG Lagos",
          content:
            "A community of (and for) developers, designers, and tech enthusiasts.",
          image: "gdg.svg",
          web: "https://gdg.community.dev",
          twitter: "https://www.twitter.com/gdglagos",
          instagram: "https://www.instagram.com/gdglagos"
        },
        {
          id: 3,
          name: "Open Source  Africa",
          content:
            "A community of creative minds driving the Open Source movement in Africa.",
          image: "osca.svg",
          web: "https://oscafrica.org",
          twitter: "https://www.twitter.com/oscafrica",
          instagram: "https://www.instagram.com/oscafrica",
          facebook: "https://www.facebook.com/opensourcecommunityafrica"
        },
        {
          id: 4,
          name: "Web3Lagos",
          content:
            "A conference for people interested in building for the next phase of the internet.",
          image: "web3.svg",
          web: "https://web3lagos.org",
          twitter: "https://www.twitter.com/web3lagos"
        },
        {
          id: 5,
          name: "Web3Lagos",
          content:
            "A conference for people interested in building for the next phase of the internet.",
          image: "web3.svg",
          web: "https://web3lagos.org",
          twitter: "https://www.twitter.com/web3lagos"
        },
        {
          id: 6,
          name: "Open Source  Africa",
          content:
            "A community of creative minds driving the Open Source movement in Africa.",
          image: "osca.svg",
          web: "https://oscafrica.org",
          twitter: "https://www.twitter.com/oscafrica",
          instagram: "https://www.instagram.com/oscafrica",
          facebook: "https://www.facebook.com/opensourcecommunityafrica"
        },
        {
            id: 7,
            name: "SheCodeAfrica",
            content: "Focused on celebrating and empowering young Girls and Women in Technology across Africa.",
            image: "shecodeafrica.svg",
            web: "https://shecodeafrica.org",
          twitter: "https://www.twitter.com/SheCodeAfrica",
          instagram: "https://www.instagram.com/shecodeafrica",
          facebook: "https://www.facebook.com/shecodeafrica"
        },
        // {
        //     id: 8,
        //     name: "GDG Lagos",
        //     content: "A community of (and for) developers, designers, and tech enthusiasts.",
        //     image: "gdg.svg"
        // }
      ],
    };
  },
  setup() {
        const store = useStore();
        mapActions(["Community"])
        onMounted(() => {
            store.dispatch("Community")
        })
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.container {
  @include flex(center, center);
}
.camps {
  padding: 3rem 7%;
  max-width: 100vw;
  /* overflow-X: hidden; */
}
.camps--box {
  text-align: center;
  margin: 0 auto;
}
.camps--button {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  .camps--button--text {
       margin-right: 0.5rem;
    width: 80px;
    flex-shrink: 0;
  h6 {
    @include caption {
      color: $secondary-80;
      font-family: $karla;
    }
  }
  }
  .camps--buttons--button {
    display: flex;
    gap: 1rem;
    overflow-X: scroll;
    overflow-Y: hidden;
    &::-webkit-scrollbar {
        height: 0.3rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $secondary-80;
      }
    flex-shrink: 0;
    width: 100%;
    height: auto;
    button {
      background: #ffefef;
      border-radius: 37px;
      height: 38px;
      width: 144px;
      padding: 8px 16px;
      border: none;
      margin: 0.4rem;
      flex-shrink: 0;
      @include flex(center, center);
      @include button {
        color: $black;
        font-family: $karla;
      }
      &:nth-child(1) {
        width: 95px;
      }
      &:nth-child(5) {
        width: 182px;
      }
      &:nth-child(7) {
        width: 90px;
      }
    }
  }
}
/* carousel slide style */
.camps--slide {
  margin: 2rem auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 400px;
  width: min(90%, 1227px);
  overflow: hidden;
  /* padding: 0 2rem; */
  .inner {
    position: absolute;
    display: flex;
    gap: 2rem;
    justify-content: flex-start;
    flex-shrink: 0;
    /* overflow: hidden; */
    width: 1200px;
    padding: 0 1rem;
  }
  .svg {
    width: 100%;
    &.one {
      margin-right: 0.5rem;
      cursor: pointer;
    }
    &.two {
     
      cursor: pointer;
    }
  }
}

.camp--slide--box {
  background: #ffffff;
  border: 1px solid #d5a48a;
  /* box-sizing: border-box; */
  border-radius: 24px;
  // width: 15rem;
  display: block;
  height: 392px;
  margin: 0 1.5rem 0 0rem;
  padding: 0 1.5rem;
  @extend %sh-0;
  .camp--slide--image {
    margin: 2rem 0;
  }
  .camp--slide--text {
    h2 {
      @include caption {
        color: $black;
        font-family: $karla;
      }
    }
    p {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 9px;
      line-height: 24px;
      color: $black;
      max-width: 100%;
      width: 168px;
      margin: 0 auto;
    }
  }
}
.footer--social--followUs--media {
  @include flex(center, center);
  gap: 0.5rem;
  margin-top: 1rem;
  svg {
    margin: 0 0.4rem;
  }
}
.right {
  transform: rotate(180deg);
}
@include xl {
  .camps--button {
    .camps--buttons--button {
      overflow-x: scroll;
      width: 75%;
      gap: 0.2rem;
      /* &::-webkit-scrollbar {
        height: 0.3rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: red;
      }
      &::-webkit-scrollbar-button {
        display: none;
      } */
      button {
        flex-shrink: 0;
        width: auto;
        @include button {
        font-size: 0.9rem;
      }
        
      }
    }
  }
  /* carousel slide style */
.camps--slide {
  .svg {
    &.one {
      right: 43%;
      cursor: pointer;
    }
    &.two {
      left: 45%;
      cursor: pointer;
    }
  }
}
}

</style>