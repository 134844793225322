<template>
  <section class="skillset">
    <div class="skillset--box">
      <div class="skillset--text">
        <h3>Skill Set</h3>
        <p>
          By the end of your training with Zero-to-Hero, you would have acquired
          the neccessary skill sets to be called a Frontend Developer. Skill
          sets which includes:
        </p>
      </div>
      <div class="skillset__skills">
        <div class="item" v-for="item in skills" :key="item.id">
          {{ item.title }}
        </div>
        <!-- <div class="skill--flex">
          <div
            class="skillset--skills--skill skills"
            v-for="skill in skills"
            :key="skill.id"
          >
            <p>{{ skill.title }}</p>
          </div>
        </div>
        <div class="skill--flex">
          <div
            class="skillset--skills--skill skills2"
            v-for="skill2 in skills2"
            :key="skill2.id"
          >
            <p>{{ skill2.title }}</p>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      skills: [
        {
          id: 1,
          title: "DATA ANALYSIS",
        },
        {
          id: 2,
          title: "DATA  VISUALIZATION",
        },
        {
          id: 3,
          title: "PROGRAMMING",
        },
        {
          id: 4,
          title: "MACHINE LEARNING",
        },
        {
          id: 5,
          title: "CLOUD   SERVICES",
        },
        {
          id: 6,
          title: "MATHS & STATISTICS",
        },
        {
          id: 7,
          title: "RESEARCH",
        },
      ],
      skills2: [
        {
          id: 4,
          title: "MACHINE LEARNING",
        },
        {
          id: 5,
          title: "CLOUD   SERVICES",
        },
        {
          id: 6,
          title: "MATHS & STATISTICS",
        },
        {
          id: 7,
          title: "RESEARCH",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scss scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";

.skillset {
  padding: 5rem 7% 3rem 7%;
 background: #fff;
 &__skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  align-items: flex-start;
  .item {
    background: linear-gradient(218deg, #4e1b3c, #231f20);
    width: 134px;
    height: 134px;
    border-radius: 100%;
    padding: 0.5rem;
    @extend %flex-ac-jc;
    font-family: $karla;
    color: $white;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 135%;
    letter-spacing: -0.015em;
    text-align: center;
  }
}
}
.skillset--box {
  @include flex(space-around, flex-start);
  grid-gap: 5rem;
  padding: 0 3rem;
  .skillset--text {
    align-self: center;
    width: 50%;
    h3 {
      margin-bottom: 2rem;
      @include heading2 {
        font-family: $karla;
        color: $secondary;
      }
    }
    p {
      @include caption {
        font-family: $karla;
        color: $gray-100;
      }
    }
  }
  .skillset--skills {
    .skill--flex {
      display: flex;
      grid-gap: 3rem;
      justify-content: center;
      margin-bottom: 2rem;
      flex-wrap: wrap;
    }
    .skillset--skills--skill {
       background: linear-gradient(218deg, #4e1b3c, #231f20);
      width: 134px;
      height: 134px;
      border-radius: 70px;
      position: relative;
      p {
        // margin: auto;
        // @include flex(center, center);
          text-align: center;
        @include caption {
          font-family: $karla;
          color: $white;
          font-size: 0.95rem;
          position: relative;
          /* left: 14px;
          top: 55px; */
        }
      }
      :nth-child(2) {
          p {
          position: relative;
          left: 4px;
          top: 20px;
          text-align: center;
          @include caption {
            font-size: 0.98rem;
          }
          }
      }
      &.skills:nth-child(1) {
        p {
          position: relative;
          left: 4px;
          top: 55px;
        }
      }
      &.skills:nth-child(2) {
        p {
          position: relative;
          left: 4px;
          top: 43px;
          @include caption {
            font-size: 0.95rem;
          }
        }
      }
      &.skills:nth-child(3) {
        p {
          position: relative;
          left: 4px;
          top: 55px;
        }
      }
      &.skills2:nth-child(4) {
        p {
          position: relative;
          left: 4px;
          top: 55px;
        }
      }
      &.skills2:nth-child(3) {
        p {
          position: relative;
          left: 4px;
          top: 43px;
        }
      }
      &.skills2:nth-child(2) {
        p {
          position: relative;
          left: 4px;
          top: 55px;
          @include caption {
            font-size: 0.85rem;
          }
        }
      }
      &.skills2:nth-child(1) {
        p {
          position: relative;
          left: 4px;
          top: 43px;
          width: 120px;
        }
      }
    }
  }
}
@include xl {
  .skillset {
    padding: 5rem 1% 3rem 1%;
    background: #daadbb60;
  }
  .skillset--box {
    flex-direction: column;
    grid-gap: 3rem;
  padding: 0 1rem;
  .skillset--text {
    text-align: center;
    max-width: 100vw;
    width: min(90%, 570px);
  }
  }
  .skillset--skills {
    padding: 0 5%;
    .skill--flex {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 3rem;
      justify-content: center;
      margin: 0 auto 2rem auto;
    }
    .skillset--skills--skill {
      background: $black-100;
      margin: 0.7rem 0.7rem;
      width: 134px;
      height: 134px;
      border-radius: 70px;
      position: relative;
      p {
        margin: auto;
        @include caption {
          font-family: $karla;
          color: $white;
          position: relative;
          left: 14px;
          top: 55px;
        }
      }
      /* :nth-child(2) {
          p {
          position: relative;
          left: 14px;
          top: 20px;
          }
      }
      &.skills2:nth-child(4) {
        p {
          position: relative;
          left: 50px;
          top: 55px;
        }
      }
      &.skills2:nth-child(3) {
        p {
          position: relative;
          left: 47px;
          top: 55px;
        }
      }
      &.skills2:nth-child(2) {
        p {
          position: relative;
          left: 23px;
          top: 43px;
        }
      }
      &.skills2:nth-child(1) {
        p {
          position: relative;
          left: 23px;
          top: 43px;
        }
      } */
    }
  }
}
@include sm {
  .skillset--text {
    h3 {
      margin-bottom: 1rem;
      @include heading2 {
        font-size: 1.5rem;
      }
    }
    p {
      @include caption {
        font-size: 0.7rem;
      }
    }
  }
}
@include xs {
  .skillset--text {
    h3 {
      margin-bottom: 1rem;
      @include heading2 {
        font-size: 1rem;
      }
    }
    p {
      @include caption {
        font-size: 0.7rem;
      }
    }
  }
}

</style>