<template>
  <section class="signup" @click.self="closeLogin">
    <div class="signup--box">
      <div class="close">
        <svg class="click"  @click.self="closeLogin" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z" fill="#9F9F9F"/>
</svg>
      </div>
      <p>
        Signup now to view test result
      </p>
      <h2>Personality Test Result</h2>
      <div class="signup--google">
          <img src="@/assets/google.png" alt="">
        <p>Login with Google</p>
      </div>
      <p class="signup--email">
      or Signup with your email address
      </p>
      <form @submit.prevent="handleSubmit" action="">
        <input
        class="type--text"
          type="email"
          name=""
          v-model="email"
          placeholder="Email Address"
          required
        />
        <input
        class="type--text"
          type="password"
          name=""
          v-model="password"
          placeholder="Password"
          required
        />
        <button type="submit" class="button--form">Login</button>
      </form>
      <p class="login">By creating your zero to hero account, you agree to our Terms, Data
Policy and Cookie Policy.</p>
    </div>
  </section>
</template>

<script>
export default {
    props: {
       signup: {
            type: Boolean
        },
        login: {
            type: Boolean
        }
    },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      box: [],
    };
  },
  methods: {
    handleSubmit() {
      if(this.box.length > 1) {
         
      }
    },
    closeLogin() {
        this.$emit('toggleLoginClose', !this.login)
    },
     openSignup() {
      this.$emit('toggleClose', !this.signup)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.signup {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 1;
    @include flex(center, center);
    background-color: #dddddd80;
}
.close {
  @include flex(flex-end, center);
  .click {
    transform: rotate(45deg);
     cursor: pointer;
  }
}
.signup--box {
    width: 30rem;
    height: auto;
    background: $white;
    padding: 2rem 5%;
    text-align: center;
    h2 {
        margin-bottom: 1rem;
        @include sub-heading-bold {
            color: $secondary-80;
            font-family: $karla;
        }
    }
    p {
        margin-bottom: 0.7rem;
        @include caption {
            color: $black-100;
            font-family: $karla;
        }
        &.signup--terms {
    @include label {
        color: $black-50;
        font-family: $karla;
    }
}
&.signup--email {
  margin: 1.5rem 0;
      @include label {
        color: $secondary;
        font-family: $karla;
      }
    }
&.login {
    margin-top: 2rem;
     @include label {
        color: $black-50;
        font-family: $karla;
    }
    span {
        color: $secondary;
        cursor: pointer;
    }
}
    }
}
.signup--google {
    @include flex(center, center);
    gap: 0.7rem;
    width: 20rem;
    height: 44px;
    background: $secondary-40;
    border-radius: 6px;
    margin-bottom: 1rem;
    p {
        padding-top: 0.4rem;
        @include label {
            color: $secondary;
            font-family: $karla;
        }
    }
}
form {
  width: 20rem;
    .type--text {
        display: block;
        width: 20rem;
        height: 46px;
        padding: 16px 70px 16px 24px;
        background: $white;
        border: 1px solid $gray-60;
        box-sizing: border-box;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        margin: 0.5rem 0;
        outline: none;
        @include label {
            font-size: 18px;
            color: $secondary;
            font-family: mullish;
        }
    }
    label {
        margin-bottom: 0.7rem;
        @include caption {
            font-family: $karla;
            color: $black-50;
        }
        &.signup--forgot {
          @include flex(flex-end, flex-start);
          @include label {
            font-family: $karla;
            color: $secondary-80;
        };
        }
    }
}
.signup--checkboxes {
    @include flex(space-between, flex-start);
    margin-top: 0.5rem;
    width: 20rem;
    .signup--checkbox {
        display: flex;
        gap: 0.5rem;
        p {
            @include label {
                color: $black-50;
                font-family: $karla;
            }
        }
    }
}

</style>