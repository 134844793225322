<template>
  <section class="roadmap">
    <div class="roadmap__box">
      <div class="roadmap__text">
        <h3>
          Roadmap to Becoming a Back-End Engineer
        </h3>
      </div>
      <Roadmap :started="started" :going="going" />
    </div>
  </section>
</template>
<script setup>
import Roadmap from "@/shared/roadmap.vue"
import {ref} from "vue";
const started = ref([
  {
    id: 1,
    title: "Choose Programming Language",
    desc: "You should have a good understanding of at least one programming language, such as Java, Python, Ruby, or Node.js",
    bg: "#DAADBB",
    btn: "Get Started"
  },
  {
    id: 2,
    title: "Learn a Framework",
    desc: "Back-end frameworks, such as Spring, Flask, Ruby on Rails, or Express.js, that provide tools and conventions for building web applications and APIs.",
    bg: "#dee2e6"
  },
  {
    id: 3,
    title: "Master a Database",
    desc: "You should be able to design, model, and query databases such as MySQL, PostgreSQL and NoSQL such as MongoDB or Cassandra",
    bg: "#DAADBB"
  },
  {
    id: 4,
    title: "Practice with Projects",
    desc: "Practice your back-end skills by working on small projects prepared for you in our dashboard . This will help you apply what you have learned and reinforce your knowledge.",
    bg: "#dee2e6"
  }
])
const going = ref([
  {
    id: 1,
    title: "Learn About APIs",
    desc: "Learn how to design and implement APIs (Application Programming Interfaces).They  provide a structured way for applications to communicate with each other.",
    bg: "#DAADBB",
    btn: "Get Going"
  },
  {
    id: 2,
    title: "Study Security",
    desc: "Learn about security and how to implement secure authentication and authorization mechanisms to protect user data.",
    bg: "#dee2e6"
  },
  {
    id: 3,
    title: "Participate in Open Source",
    desc: "Gain experience and learn from other developers and connect with mentors on your dashboard.",
    bg: "#DAADBB"
  },
  {
    id: 4,
    title: "Keep Learning",
    desc: "Stay up-to-date with the latest trends and best practices.",
    bg: "#dee2e6"
  },
  {
    id: 5,
    title: "Build a Portfolio",
    desc: "Share your projects on GitHub or other online platforms to get feedback and increase your visibility.",
    bg: "#DAADBB"
  }
])
</script>

<style lang="scss" scss scoped>
@import "@/styles/_typography.scss";
.roadmap {
  background: #F9F0EC;
  padding: 4rem 7%;
  &__box {
    width: 100%;
  }
  &__text {
    max-width: 100vw;
    width: min(90%, 804px);
    margin: 0 auto;
    text-align: center;
  h3 {
    @include heading3 {
      color: $secondary-80;
      font-family: $karla;
    }
    @media screen and (max-width: 900px) {
      margin: 0 auto;
      font-size: 1.5rem;
    }
  }
  }
}
</style>