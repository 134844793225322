<template>
  <Blog />
</template>

<script>
import Blog from '@/components/Company/Blog.vue'
export default {
components: {
    Blog
}
}
</script>

<style>

</style>