<template>
  <section class="intro">
    <div class="intro--box">
      <div class="intro--text">
        <h1>DATA SCIENCE</h1>
        <p>
          Data science is an emerging scientific field that that involves data
          interpretation using processes, algorithms and systems to to make
          suitable predictions that can be applicable to various displines.
        </p>
        <button @click="handleMove" class="btn--course">View Curriculum</button>
        <div class="intro--text--figures">
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.mentors}}</h1>
            <p>
              <router-link to="/mentors"> MENTORS </router-link>
            </p>
          </div>
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.bootcamps}}</h1>
            <p>
              <router-link to="/bootcamp"> BOOTCAMPS </router-link>
            </p>
          </div>
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.projects}}</h1>
            <p>PROJECT</p>
          </div>
        </div>
      </div>
      <div class="intro--image">
        <img src="@/assets/Courses/DataScience/cuate.png" alt="" height="618" width="988"/>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore, mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router"
const router = useRouter
const store = useStore();
const active = ref(true)
const metric = ref()
mapActions(["Metric"])
onMounted(async () => {
  await store.dispatch("Metric")
  metric.value = store.getters.StateMetric
  setInterval(() => {
      active.value = active.value;
    }, 6000);
})
const handleMove = () => {
  if(window.sessionStorage.getItem("user") != null) {
        router.push("/curriculum")
      } else {
        store.state.AccessState = 2
      }
}
</script>

<style lang="scss" scoped>
@import "@/styles/Courses/intro.scss";
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.intro {
  height: auto;
  &--image {
    img {
      object-fit: contain;
      width: 100%;
      height: auto;
    }
  }
}
.intro--box {
  @include flex(space-around, center);
  flex-direction: column;
  grid-gap: 5rem;
}

@include xl {
  .intro {
    height: auto;
  }
  .intro--box {
        @include flex(center, center);
        align-content: center;
        flex-direction: column;
        grid-gap: 2rem;
      
        .intro--text {
          text-align: center;
          max-width: 100%;
          width: min(90%, 690px);
        }
      }
}

@include lg{
  .intro {
    height: auto;
  }
}
@include md{
  .intro {
    height: auto;
  }
}
@include sm{
  .intro {
    height: auto;
  }
}
@include xs{
  .intro {
    height: auto;
  }
}
</style>