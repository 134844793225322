<template>
  <section class="notif" @click.self="cancel">
    <div class="notif__item">
      <h2>Notification (3)</h2>
      <div class="notif__input">
        <input type="text" placeholder="Search for beginners">
      </div>
     <a :href="item.url" v-for="(item, index) in notifs" :key="index" @click="cancel(index)">
      <div class="item">
        <div class="item__image">
            <img :src="item.author.avatar" width="44" height="44" alt="">
            <svg v-if="item.seen === 0" width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="3.5" cy="3.5" r="3.5" fill="#009FFD"/> </svg>
        </div>
        <div class="item__text">
          <h3>{{item.author.name}}</h3>
          <h4>{{item.message}}</h4>
        </div>
        <span>
            Now
            <img src="@/assets/svg/mail.svg" alt=""></span>
      </div>
     </a>
      <span class="more" @click="cancel">
        View more
      </span>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import {useStore, mapActions, mapGetters} from "vuex";
import { useRouter } from "vue-router";
const store = useStore()
const router = useRouter()
const chat = [
  {
    user: 'Gabriel Lou',
    role: 'UI/UX Designer',
    img: require('@/assets/contact/gabriel.png'),
    active: true
  },
  {
    user: 'Mickson Tanny',
    role: 'Backend Developer',
    img: require('@/assets/contact/collins.png'),
    active: false
  },
  {
    user: 'Philemon Sulkan',
    role: 'DevOps Engineer',
    img: require('@/assets/contact/alao.png'),
    active: false
  },
  {
    user: 'Magret Carpal',
    role: 'Solutions Architect',
    img: require('@/assets/contact/ireti.png'),
    active: false
  },
  {
    user: 'Gabriel Messi',
    role: 'Front-end Developer',
    img: require('@/assets/contact/paul.png'),
    active: false
  }
]
const notifs = ref()
const props = defineProps(["notif"])
const emit = defineEmits(["closeNotif"])
const cancel = (number) => {
  notifs.value.map((item, index) => {
    if(number === index) {
    }
  })
    emit("closeNotif", !props.notif)
}
onMounted(async () => {
  mapActions(["Notif"])
  await store.dispatch("Notif")
  notifs.value = await store.getters.StateNotify
  notifs.value = notifs.value.slice(0, 4)
})
</script>

<style lang="scss" scoped>
.notif {
    position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(95, 94, 94, 0.5);
  top: 0;
  left: 0;
  @media screen and (max-width: 400px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
  &__input {
    padding: 0 1rem;
    margin-top: 1.5rem;
  }
  &__item {
   width: Min(90%, 354px);
    padding: 1.75rem 0rem;
    background-color: $white;
    position: absolute;
    width: max(22.125rem, 354px);
    right: 3rem;
    top: 10%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background-color: $white;
    @media screen and (max-width: 400px) {
        right: unset;
    }
    h2 {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: Max(1rem, 16px);
      line-height: Max(1.5rem, 24px);
      letter-spacing: 0.0016em;
      color: $black-100;
      padding: 0 1rem;
    }
    input[type = text] {
      background-image: url('@/assets/svg/search.svg');
      background-repeat: no-repeat;
      background-position: 4% center;
      background-color: #F9F9F9;
      border: 1px solid #D7DCE4;
      border-radius: 10px;
      height: Max(2rem, 32px);
      width: 100%;
      font-family: 'Karla', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: Max(1rem, 16px);
      line-height: Max(1.5rem, 24px);
      color: $black;
      padding: 0 8%;
      margin-bottom: 1rem;
      &::placeholder {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 10px;
        letter-spacing: 0.0016em;
        color: rgba(27, 28, 30, 0.3);
        margin-top: 0
      }
    }
    .item {
      @extend %flex-ac-jb;
      cursor: pointer;
      padding: 0 1rem;
      height: Max(4.563rem, 73px);
      &__image {
        position: relative;
        img {
            position: relative;
        }
        svg {
            position: absolute;
            bottom: 10%;
            right: 2%;
        }
      }
      &__text {
        text-align: left;
        width: 60%;
        h3 {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: Max(0.875rem, 14px);
          line-height: Max(1.25rem, 20px);
          letter-spacing: 0.0016em;
          color: $black-100;
          margin-bottom: unset;
        }
        h4 {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: Max(0.75rem, 12px);
          line-height: Max(1rem, 16px);
          letter-spacing: 0.0016em;
          color: rgba(27, 28, 30, 0.6);
        }
      }
      span {
        @extend %flex-ac;
        gap: 0.2rem;
        font-family: 'Outfit', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 8px;
        line-height: Max(1rem, 16px);
        letter-spacing: 0.02em;
        color: $black-40;
      }
    }
    span {
      &.more {
        cursor: pointer;
        @include flex(flex-end, center);
        margin-left: auto;
        margin-top: 0.35rem;
        padding: 0 1rem;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: Max(0.875rem, 14px);
        line-height: Max(1.5rem, 24px);
        letter-spacing: -0.0016em;
        text-transform: capitalize;
        color: $secondary;
      }
    }
  }
}

</style>