<template>
  <div class="intro__filter">
    <div class="intro__filter__item">
      <p>Country</p>
      <div class="dropdown">
        <span @click="toggleCountry = !toggleCountry, toggleCity = false, toggleMode = false">
          <p>{{selectCountry}}</p>
          <img
            src="@/assets/svg/arr-down-brown.svg"
            alt=""
            width="24"
            height="24"
          />
        </span>
        <div class="bottom" v-if="toggleCountry">
            <p v-for="item in country" :key="item.id" @click="toggleCountry = !toggleCountry, selectCountry = item.title, data.country = item.title">{{ item.title }}</p>
          </div>
      </div>
    </div>
    <div class="intro__filter__item">
      <p>City</p>
      <div class="dropdown">
        <span @click="toggleCity = !toggleCity, toggleCountry = false, toggleMode = false">
          <p>{{selectCity}}</p>
          <img
            src="@/assets/svg/arr-down-brown.svg"
            alt=""
            width="24"
            height="24"
          />
        </span>
        <div class="bottom" v-if="toggleCity">
            <p v-for="item in city" :key="item.id" @click="toggleCity = !toggleCity, selectCity = item.title, data.city = item.title">{{ item.title }}</p>
          </div>
      </div>
    </div>
    <div class="intro__filter__item">
      <p>Mode</p>
      <div class="dropdown">
        <span @click="toggleMode = !toggleMode, toggleCity = false, toggleCountry = false">
          <p>{{selectMode}}</p>
          <img
            src="@/assets/svg/arr-down-brown.svg"
            alt=""
            width="24"
            height="24"
          />
        </span>
        <div class="bottom" v-if="toggleMode">
          <p v-for="item in mode" :key="item.id" @click="toggleMode = !toggleMode, selectMode = item.title, data.mode = item.title">{{ item.title }}</p>
        </div>
      </div>
    </div>
    <button @click="filter">Filter</button>
    <button @click="reset" v-show="resetValue">Reset</button>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {useStore, mapActions, mapGetters} from "vuex";
const store = useStore();
const props = defineProps(["posts"])
const emit = defineEmits(["ChangePost"])
// import {filter} from "@/Data/filter.js";
// const {mode, city, country} = filter()
const toggleMode = ref(false)
const toggleCity = ref(false)
const toggleCountry = ref(false)
const selectMode = ref("Select Mode")
const selectCity = ref("Select City")
const selectCountry = ref("Select Country")
const mode = ref([
  {
    id: 1,
    title: "Online",
  },
  {
    id: 2,
    title: "In-Class",
  },
]);
const city = ref([
  {
    id: 1,
    title: "Johannesburg",
  },
  {
    id: 2,
    title: "Lagos",
  },
  {
    id: 3,
    title: "Abuja",
  },
  {
    id: 4,
    title: "Ibadan",
  },
  {
    id: 5,
    title: "Cape Town",
  },
  {
    id: 6,
    title: "Marrakesh"
  },
  {
    id: 7,
    title: "Cairo"
  }
]);
const country = ref([
  {
    id: 1,
    title: "Nigeria",
  },
  {
    id: 2,
    title: "Ghana",
  },
  {
    id: 3,
    title: "Rwanda",
  },
  {
    id: 4,
    title: "South Africa",
  },
  {
    id: 5,
    title: "Kenya",
  },
  {
    id: 6,
    title: "Morroco"
  },
  {
    id: 7,
    title: "Egypt"
  }
]);
const data = ref({
  city: "",
  country: "",
  mode: ""
})
const filter = async () => {
  mapActions(["Filter"])
  await store.dispatch("Filter", data.value)
  emit("ChangePost", props.posts = await store.getters.FilteredState)
  resetValue.value = true
}
const resetValue = ref(false)
const reset = async () => {
  mapActions(["Bootcamps"]);
  await store.dispatch("Bootcamps");
  emit("ChangePost", props.posts = await store.getters.BootcampState.data)
  selectMode.value = "Select Mode"
selectCity.value = "Select City"
selectCountry.value = "Select Country"
toggleMode.value = false
toggleCity.value = false
toggleCountry.value = false
resetValue.value = false
}
</script>

<style lang="scss" scoped>
.intro {
  &__filter {
    background: #fbf6f3;
    border-radius: 8px;
    height: 100px;
    width: Min(100%, 921px);
    margin: 0 auto;
    padding: 1rem;
    text-align: left;
    @include flex(space-between, flex-end);
    gap: 1rem;
    @media screen and (max-width: 1120px) {
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &__item {
      @media screen and (max-width: 1120px) {
        flex-grow: 1;
        width: 100%;
      }
      p {
        font-family: "Karla", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: Max(1rem, 16px);
        line-height: 150%;
        letter-spacing: -0.0016em;
        color: #ca8a9d;
        margin-bottom: unset;
      }
      .dropdown {
        background: #ffffff;
        border: 1px solid $secondary-80;
        border-radius: 5px;
        padding: 10px 12px;
        min-width: 10.438rem;
        height: 44px;
        cursor: pointer;
        
        span {
          @extend %flex-ac-jb;
          gap: 0.5rem;
          p {
            font-family: "Karla", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: Max(1rem, 16px);
            line-height: 150%;
            letter-spacing: -0.0016em;
            margin-bottom: unset;
            color: #8f3d55;
            flex-shrink: 0;
          }
        }
        .bottom {
            position: absolute;
            top: 44px;
            background-color: $white;
            z-index: 3;
            width: 100%;
            left: 0;
            padding: 1rem 0;border-width: 1px 1px 0px 1px;
            border-style: solid;
            border-color: rgba(202, 138, 157, 0.3);
            border-radius: 5px 5px 0px 0px;

            p {
                padding: 0.5rem 1rem;
                &:not(:last-child) {
                    border-bottom: 1px solid #8f3d55;
                }
            }
        }
      }
    }
    button {
      @media screen and (max-width: 1120px) {
        flex-grow: 1;
        width: 100%;
      }
      background: $secondary-80;
      border-radius: 5px;
      width: 132px;
      height: 45px;
      padding: 8px;
      font-family: "Karla";
      font-style: normal;
      font-weight: 700;
      font-size: Max(1.5rem, 24px);
      line-height: 120%;
      letter-spacing: -0.055em;
      color: $white;
    }
  }
}
</style>
