<template>
<section class="intro">
    <div class="intro--box">
        <div class="intro--text">
            <h1>
                Mentor
            </h1>
        </div>
        <!-- <div class="intro--button">
            <button class="button--filter">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.571 20.3998L5.82812 7.2854V4.37109H29.1426V7.2854L20.3997 20.3998V29.1427L14.571 32.057V20.3998Z" fill="#8F3D55"/>
</svg>
<p>
    Filter Search
</p>
            </button>
        </div> -->
    </div>
</section>
  <section class="details">
    <div class="details--box">
      <div class="details--cards">
        <div class="details--cards--card">
          <div class="details--cards--card--intro">
            <div class="details--cards--card--intro--split">
              <div class="details--cards--card--intro--image">
                <img src="@/assets/seyi.png" alt="" />
              </div>
              <div class="details--cards--card--intro--text">
                <h3>Oluwaseyi Babalola</h3>
                <p>Software Developer/ UI Designer</p>
                <div class="details--cards--card--intro--text--location">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 26.1249L21.1875 19.9374C22.4111 18.7137 23.2444 17.1546 23.5819 15.4572C23.9195 13.7599 23.7462 12.0006 23.0839 10.4018C22.4216 8.80305 21.3001 7.43654 19.8612 6.47511C18.4222 5.51369 16.7306 5.00053 15 5.00053C13.2695 5.00053 11.5778 5.51369 10.1389 6.47511C8.69995 7.43654 7.57844 8.80305 6.91614 10.4018C6.25385 12.0006 6.08052 13.7599 6.41807 15.4572C6.75562 17.1546 7.58888 18.7137 8.8125 19.9374L15 26.1249ZM15 29.6599L7.04501 21.7049C5.47168 20.1316 4.40023 18.127 3.96616 15.9447C3.53209 13.7624 3.75488 11.5004 4.60637 9.44478C5.45786 7.38912 6.8998 5.63212 8.74985 4.39596C10.5999 3.1598 12.775 2.5 15 2.5C17.225 2.5 19.4001 3.1598 21.2502 4.39596C23.1002 5.63212 24.5421 7.38912 25.3936 9.44478C26.2451 11.5004 26.4679 13.7624 26.0338 15.9447C25.5998 18.127 24.5283 20.1316 22.955 21.7049L15 29.6599ZM15 16.2499C15.663 16.2499 16.2989 15.9865 16.7678 15.5177C17.2366 15.0488 17.5 14.4129 17.5 13.7499C17.5 13.0869 17.2366 12.451 16.7678 11.9821C16.2989 11.5133 15.663 11.2499 15 11.2499C14.337 11.2499 13.7011 11.5133 13.2322 11.9821C12.7634 12.451 12.5 13.0869 12.5 13.7499C12.5 14.4129 12.7634 15.0488 13.2322 15.5177C13.7011 15.9865 14.337 16.2499 15 16.2499ZM15 18.7499C13.6739 18.7499 12.4022 18.2231 11.4645 17.2854C10.5268 16.3478 10 15.076 10 13.7499C10 12.4238 10.5268 11.1521 11.4645 10.2144C12.4022 9.27669 13.6739 8.7499 15 8.7499C16.3261 8.7499 17.5979 9.27669 18.5355 10.2144C19.4732 11.1521 20 12.4238 20 13.7499C20 15.076 19.4732 16.3478 18.5355 17.2854C17.5979 18.2231 16.3261 18.7499 15 18.7499Z"
                      fill="#8F3D55"
                    />
                  </svg>
                  <p>Yaba, Lagos. Nigeria.</p>
                </div>
                <div class="details--cards--card--intro--text--socials">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.94043 5.00002C6.94017 5.53046 6.7292 6.03906 6.35394 6.41394C5.97868 6.78883 5.46986 6.99929 4.93943 6.99902C4.409 6.99876 3.90039 6.78779 3.52551 6.41253C3.15062 6.03727 2.94016 5.52846 2.94043 4.99802C2.9407 4.46759 3.15166 3.95899 3.52692 3.5841C3.90218 3.20922 4.411 2.99876 4.94143 2.99902C5.47186 2.99929 5.98047 3.21026 6.35535 3.58552C6.73024 3.96078 6.9407 4.46959 6.94043 5.00002ZM7.00043 8.48002H3.00043V21H7.00043V8.48002ZM13.3204 8.48002H9.34043V21H13.2804V14.43C13.2804 10.77 18.0504 10.43 18.0504 14.43V21H22.0004V13.07C22.0004 6.90002 14.9404 7.13002 13.2804 10.16L13.3204 8.48002Z"
                      fill="#8F3D55"
                    />
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 13.5H16.5L17.5 9.5H14V7.5C14 6.47 14 5.5 16 5.5H17.5V2.14C17.174 2.097 15.943 2 14.643 2C11.928 2 10 3.657 10 6.7V9.5H7V13.5H10V22H14V13.5Z"
                      fill="#8F3D55"
                    />
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.1623 5.65593C21.3989 5.99362 20.5893 6.2154 19.7603 6.31393C20.634 5.79136 21.288 4.96894 21.6003 3.99993C20.7803 4.48793 19.8813 4.82993 18.9443 5.01493C18.3149 4.34151 17.4807 3.89489 16.5713 3.74451C15.6618 3.59413 14.7282 3.74842 13.9156 4.18338C13.1029 4.61834 12.4567 5.30961 12.0774 6.14972C11.6981 6.98983 11.607 7.93171 11.8183 8.82893C10.1554 8.74558 8.52863 8.31345 7.04358 7.56059C5.55854 6.80773 4.24842 5.75097 3.1983 4.45893C2.82659 5.09738 2.63125 5.82315 2.6323 6.56193C2.6323 8.01193 3.3703 9.29293 4.4923 10.0429C3.82831 10.022 3.17893 9.84271 2.5983 9.51993V9.57193C2.5985 10.5376 2.93267 11.4735 3.54414 12.221C4.15562 12.9684 5.00678 13.4814 5.9533 13.6729C5.33691 13.84 4.6906 13.8646 4.0633 13.7449C4.33016 14.5762 4.8503 15.3031 5.55089 15.824C6.25147 16.3449 7.09742 16.6337 7.9703 16.6499C7.10278 17.3313 6.10947 17.8349 5.04718 18.1321C3.98488 18.4293 2.87442 18.5142 1.7793 18.3819C3.69099 19.6114 5.91639 20.264 8.1893 20.2619C15.8823 20.2619 20.0893 13.8889 20.0893 8.36193C20.0893 8.18193 20.0843 7.99993 20.0763 7.82193C20.8952 7.23009 21.6019 6.49695 22.1633 5.65693L22.1623 5.65593Z"
                      fill="#8F3D55"
                    />
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.543 6.498C22 8.28 22 12 22 12C22 12 22 15.72 21.543 17.502C21.289 18.487 20.546 19.262 19.605 19.524C17.896 20 12 20 12 20C12 20 6.107 20 4.395 19.524C3.45 19.258 2.708 18.484 2.457 17.502C2 15.72 2 12 2 12C2 12 2 8.28 2.457 6.498C2.711 5.513 3.454 4.738 4.395 4.476C6.107 4 12 4 12 4C12 4 17.896 4 19.605 4.476C20.55 4.742 21.292 5.516 21.543 6.498ZM10 15.5L16 12L10 8.5V15.5Z"
                      fill="#8F3D55"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="details--cards--card--intro--content">
              <h3>About Oluwaseyi Babalola (5-Years Experience)</h3>
              <p>
                Front-end developer with expertise in React, architecting
                projects by bringing in the best tools, implementing best
                practices, and making apps scalable and maintainable in the long
                run, front-end developer with expertise in React, architecting
                projects by bringing in the best tools, implementing best
                practices, and making apps scalable and maintainable in the long
                run. front-end developer with expertise in React, architecting
                projects by bringing in the best tools, implementing best
                practices, and making apps scalable and maintainable in the long
                run, front-end developer with expertise in React, architecting
                projects by bringing in the best tools, implementing best
                practices, and making apps scalable and maintainable in the long
                run. .....Read more
              </p>
            </div>
            <div class="details--cards--card--intro--link">
              <h4>
                <router-link to="/premium">
                <button class="button--mentor">
                  Apply
                </button>
                </router-link>
              </h4>
            </div>
          </div>
          <div class="details--cards--card--stacks">
            <div class="details--cards--card--stacks--text">
              <h5>Stacks</h5>
            </div>
            <div class="details--cards--card--stacks--stack">
              <button class="button--stacks">HTML</button>
              <button class="button--stacks">CSS</button>
              <button class="button--stacks">JavaScript</button>
              <button class="button--stacks">React</button>
              <button class="button--stacks">Tailwind</button>
              <button class="button--stacks">Figma</button>
              <button class="button--stacks">Interaction Design</button>
            </div>
            <div class="details--mentorship">
              <div class="details--mentorship--text">
                <h5>Mentorship Supports</h5>
              </div>
              <div class="details--mentorship--list">
                <ul>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 12L7.25 17L9.875 14"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 12L13.25 17L22 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 7L12.5 11"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>Career Advice</p>
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 12L7.25 17L9.875 14"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 12L13.25 17L22 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 7L12.5 11"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>Code Review</p>
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 12L7.25 17L9.875 14"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 12L13.25 17L22 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 7L12.5 11"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>Interview Techniques</p>
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 12L7.25 17L9.875 14"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 12L13.25 17L22 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 7L12.5 11"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>Portfolio Review</p>
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 12L7.25 17L9.875 14"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 12L13.25 17L22 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 7L12.5 11"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>1-on-1 Coffee Chat</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.intro {
    background: $primary-40;
    height: 429.24px;
    position: relative;
}
.intro--box {
    padding: 5rem 7%;
    text-align: center;
    .intro--text {
        margin-bottom: 4rem;
        h1 {
            @include heading1 {
                color: $secondary-80;
                font-family: $karla;
            }
        }
    }
    .intro--button {
        @include flex(flex-end, flex-start);
    }
}
.details {
  padding: 5rem 7%;
  position: relative;
    top: -13rem;
    @include flex(center,center);
}
.details--cards--card {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.details--cards--card--intro {
  width: 707px;
  height: 798px;
  background: #f9f2ee;
  border-radius: 20px 0 0 20px;
  padding: 3rem 4%;
  .details--cards--card--intro--split {
    display: flex;
    gap: 3rem;
    .details--cards--card--intro--image {
      align-self: center;
      margin: auto 0;
    }
    .details--cards--card--intro--text {
      h3 {
        @include body-bold {
          color: $black-100;
          font-family: $karla;
        }
      }
      p {
        @include body {
          color: $gray-100;
          font-family: $karla;
        }
      }
      .details--cards--card--intro--text--location {
        display: flex;
        gap: 0.4rem;
        p {
          @include button {
            color: $black-50;
            font-family: $karla;
          }
        }
      }
      .details--cards--card--intro--text--socials {
        display: flex;
        gap: 1rem;
      }
    }
  }
  .details--cards--card--intro--content {
    margin-top: 2.5rem;
    h3 {
      @include body-bold {
        color: $black-100;
        font-family: $karla;
      }
    }
    p {
      @include caption {
        color: $black-100;
        font-family: $karla;
      }
    }
  }
  .details--cards--card--intro--link {
    text-align: center;
    @include flex(center, center);
    margin-top: 2rem;
    h4 {
      button {
        @include button {
          font-family: $karla;
          color: white;
        }
      }
      @include body-bold {
        color: $secondary-80;
        font-family: $karla;
      }
    }
  }
}
.details--cards--card--stacks {
  background: #daadbb;
  border-radius: 0px 20px 20px 0px;
  width: 447px;
  height: 798px;
  padding: 3rem 4%;
  .details--cards--card--stacks--text {
    text-align: center;
    h5 {
      margin-bottom: 2rem;
      @include sub-heading-bold {
        color: $white;
        font-family: $karla;
      }
    }
  }
  .details--cards--card--stacks--stack {
    @include flex(flex-start, flex-start);
    gap: 2rem;
    flex-wrap: wrap;
    .button--stacks {
      background: #ffffff;
      border-radius: 37px;
      width: auto;
      height: 38px;
      padding: 8px 16px;
      border: none;
      margin: 0.4rem;
      @include flex(center, center);
      @include button {
        color: $black-100;
        font-family: $karla;
      }
    }
  }
}
.details--mentorship {
    margin-top: 2rem;
    .details--mentorship--text {
        h5 {
            @include button {
        color: $white;
        font-family: $karla;
      }
        }
    }
    .details--mentorship--list {
        ul {
            li {
                display: flex;
                gap: 1rem;
                p {
                     @include body {
                         color: $white;
                         font-family: $karla;
      }
                }
            }
        }
    }
}
@include xl {
  .details--cards--card {
  display: flex;
  flex-direction: column;
}
.details--cards--card--intro {
  width: min(90%, 707px);
  height: auto;
  border-radius: 0px 0 0 0px;
  margin: 0 auto;
  text-align: center;
  .details--cards--card--intro--split {
    
    .details--cards--card--intro--text {
      
      h3 {
        @include body-bold {
          color: $black-100;
          font-family: $karla;
        }
      }
      p {
        @include body {
          color: $gray-100;
          font-family: $karla;
        }
      }
      .details--cards--card--intro--text--location {
        display: flex;
        gap: 0.4rem;
        p {
          @include button {
            color: $black-50;
            font-family: $karla;
          }
        }
      }
      .details--cards--card--intro--text--socials {
        display: flex;
        gap: 1rem;
      }
    }
  }
  .details--cards--card--intro--content {
    margin-top: 2.5rem;
    h3 {
      @include body-bold {
        color: $black-100;
        font-family: $karla;
      }
    }
    p {
      @include caption {
        color: $black-100;
        font-family: $karla;
      }
    }
  }
  .details--cards--card--intro--link {
    text-align: center;
    margin-top: 2rem;
    @include flex(center, center);
    h4 {
      @include body-bold {
        color: $secondary-80;
        font-family: $karla;
      }
    }
  }
}
.details--cards--card--stacks {
  border-radius: 0px 0px 0px 0px;
  width: min(90%, 707px);
  height: auto;
  margin: 0 auto;
  padding: 3rem 4%;
}
}
@include breakpoint(650px) {
  .details--cards--card--intro {
  width: min(90%, 707px);
  height: auto;
  border-radius: 0px 0 0 0px;
  margin: 0 auto;
  text-align: center;
  .details--cards--card--intro--split {
    flex-direction: column;
    .details--cards--card--intro--text {
      
      h3 {
        @include body-bold {
          color: $black-100;
          font-family: $karla;
        }
      }
      p {
        @include body {
          color: $gray-100;
          font-family: $karla;
        }
      }
      .details--cards--card--intro--text--location {
        display: flex;
        justify-content: center;
        gap: 0.4rem;
        p {
          @include button {
            color: $black-50;
            font-family: $karla;
          }
        }
      }
      .details--cards--card--intro--text--socials {
        display: flex;
        justify-content: center;
        gap: 1rem;
      }
    }
  }
  .details--cards--card--intro--content {
    margin-top: 2.5rem;
    h3 {
      @include body-bold {
       font-size: 1.2rem;
      }
    }
    p {
      @include caption {
        font-size: 0.9rem;
      }
    }
  }
  .details--cards--card--intro--link {
    text-align: center;
    margin-top: 2rem;
    @include flex(center, center);
    h4 {
      @include body-bold {
        color: $secondary-80;
        font-family: $karla;
      }
    }
  }
}
}
</style>