<template>
  <section class="profile">
      <div class="profile--box">
          <div class="profile--text">
              <h2>
                  Tech Personalities
              </h2>
          </div>
          <div class="profile--images">
              <div class="profile--images--details">
                  <router-link to="/personalitydetail">
                  <img src="@/assets/Personality/Profile/graphics.png" alt="">
                  <h4>
                      Graphics Design
                  </h4>
                  <p>
                      Innovative inventors with an unquenchable thirst for knowledge.
                  </p>
                  </router-link>
              </div>
              <div class="profile--images--details">
                  <router-link to="/personalitydetail">
                  <img src="@/assets/Personality/Profile/backend.png" alt="">
                  <h4>
                      Back End Dev.
                  </h4>
                  <p>
                      Imaginative and strategic thinkers, with a plan for everything.
                  </p>
                  </router-link>
              </div>
              <div class="profile--images--details">
                  <router-link to="/personalitydetail">
                  <img src="@/assets/Personality/Profile/writer.png" alt="">
                  <h4>
                      UX Writing
                  </h4>
                  <p>
                      Smart and curious thinkers who cannot resist an intellectual challenge.
                  </p>
                  </router-link>
              </div>
              <div class="profile--images--details">
                  <router-link to="/personalitydetail">
                  <img src="@/assets/Personality/Profile/blockchain.png" alt="">
                  <h4>
                      Blockchain Dev.
                  </h4>
                  <p>
                      Bold and practical experimenters, masters of all kinds of tools.
                  </p>
                  </router-link>
              </div>
              <div class="profile--images--details">
                  <router-link to="/personalitydetail">
                  <img src="@/assets/Personality/Profile/frontend.png" alt="">
                  <h4>
                      Front End Dev.
                  </h4>
                  <p>
                     Innovative inventors with an unquenchable thirst for knowledge.
                  </p>
                  </router-link>
              </div>
              <div class="profile--images--details">
                  <router-link to="/personalitydetail">
                  <img src="@/assets/Personality/Profile/product.png" alt="">
                  <h4>
                      Product Design
                  </h4>
                  <p>
                      Imaginative and strategic thinkers, with a plan for everything.
                  </p>
                  </router-link>
              </div>
              <div class="profile--images--details">
                  <router-link to="/personalitydetail">
                  <img src="@/assets/Personality/Profile/data.png" alt="">
                  <h4>
                      Data Science
                  </h4>
                  <p>
                     Smart and curious thinkers who cannot resist an intellectual challenge.
                  </p>
                  </router-link>
              </div>
              <div class="profile--images--details">
                  <router-link to="/personalitydetail">
                  <img src="@/assets/Personality/Profile/devops.png" alt="">
                  <h4>
                      Dev Ops
                  </h4>
                  <p>
                      Bold and practical experimenters, masters of all kinds of tools.
                  </p>
                  </router-link>
              </div>
          </div>
          <div class="profile--hiring">
              <Hiring />
          </div>
      </div>
  </section>
</template>

<script>
import Hiring from '@/components/Hiring.vue'
export default {
    components: {
        Hiring
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.profile {
    background: linear-gradient(180deg, #F0DED5 -18.15%, rgba(240, 222, 213, 0) 100%);
}
.profile--box {
    padding: 4rem 7%;
    text-align: center;
}
.profile--text {
    h2 {
        font-family: $karla;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 90%;
        letter-spacing: -0.035em;
        color: $secondary-80;
        margin-bottom: 4rem;
    }
}
.profile--images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    column-gap: 2rem;
    row-gap: 8rem;
    place-items: center;

    img {
        margin-bottom: 2rem;
    }
    h4 {
        margin-bottom: 1.5rem;
        @include profile {
            color: $black-50;
        }
    }
    p {
        @include profile-p {
            color: $black;
        }
    }
}
.profile--hiring {
    padding-top: 5rem;
}
</style>