<template>
  <Premium />
</template>

<script>
import Premium from '@/pages/Premium/Premium.vue'
export default {
components: {
    Premium
}
}
</script>

<style>

</style>