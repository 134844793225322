<template>
  <Intro />
  <Faq />
  <Tools />
</template>

<script>
import Intro from "@/components/Curriculum/Intro.vue"
import Price from '@/components/Curriculum/Price.vue'
import Testimonials from '@/components/Testimonials.vue'
import Tools from "@/components/Curriculum/Tools.vue"
import Faq from '@/components/Curriculum/faq.vue'
export default {
    components: {
        Intro,
        Testimonials,
        Tools,
        Price,
        Faq
    }
}
</script>

<style>

</style>