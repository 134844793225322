<template>
  <Mentors />
</template>

<script>
import Mentors from '@/components/Mentors/Mentors.vue'
export default {
components: {
    Mentors
}
}
</script>

<style>

</style>