import axios from "axios";

const state = {
    opening: null,
    field: null,
    location: null,
};

const getters = {
    internState: state => state.opening,
    FieldState: state => state.field,
    LocationState: state => state.location
};

const actions = {
    async Internship({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get("internship", {headers: headers})
         
        let Open = res.data.data
        await commit("setopening", Open)
    },
    async IntField({commit}, Field) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`internship/filter-field?fields[courses]=${Field}`, {headers: headers})
         
        let project = res.data.data.project
        await commit("setField", project);
    },
    async IntLevel({commit}, Level) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`internship/filter-location?fields[level]=${Level}`, {headers: headers})
         
        let project = res.data.data.project
        await commit("setLocation", project);
    }
};

const mutations = {
    setopening(state, Open) {
        state.opening = Open
    },
    setField(state, fields) {
        state.field = fields
    },
    setLocation(state, location) {
        state.location = location
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}