<template>
  <DataScience />
</template>

<script>
import DataScience from '@/components/Courses/DataScience/DataScience.vue'
export default {
components: {
    DataScience
}
}
</script>

<style>

</style>