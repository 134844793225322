<template>
  <section class="intro">
    <div class="intro--box">
      <div class="intro--text">
        <h1>FRONT-END DEVELOPMENT</h1>
        <p>
          Front-end Development refers to the practice of designing and
          developing the user interface (UI) of a website or web
          application.Front-end engineers work closely with UX designers and
          back-end developers to ensure that the user experience is seamless and
          that the design is consistent with the overall functionality of the
          site or application.They are responsible for creating the look and
          feel of the site, as well as ensuring that it is optimized for
          performance, accessibility, and usability.
        </p>
        <button @click="handleMove" class="btn--course">View Curriculum</button>
        <div class="intro--text--figures">
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.mentors}}</h1>
            <p>
              <router-link to="/mentors"> MENTORS </router-link>
            </p>
          </div>
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.bootcamps}}</h1>
            <p>
              <router-link to="/bootcamp"> BOOTCAMPS </router-link>
            </p>
          </div>
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.projects}}</h1>
            <p>PROJECT</p>
          </div>
        </div>
      </div>
      <div class="intro--image">
        <img
          v-if="active"
          src="@/assets/Courses/Frontend/desktop.png"
          alt=""
          height="661"
          width="639"
        />
        <img
          v-else
          src="@/assets/Courses/Frontend/desktop.svg"
          alt=""
          height="661"
          width="639"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore, mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router"
const router = useRouter()
const store = useStore();
const active = ref(true)
const metric = ref()
mapActions(["Metric"])
onMounted(async () => {
  await store.dispatch("Metric")
  metric.value = store.getters.StateMetric
  setInterval(() => {
      active.value = active.value;
    }, 6000);
})
const handleMove = () => {
  if(window.sessionStorage.getItem("user") != null) {
        router.push("/curriculum")
      } else {
        store.state.AccessState = 2
      }
}
</script>

<style lang="scss" scoped>
@import "@/styles/Courses/intro.scss";

img {
  transition: opacity 1s ease-in-out;
  animation: fade ease-in-out 2.5s;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
