<template>
  <section class="article">
    <div class="article--box">
      <div class="article--cards">
        <div class="article--cards--cardd">
          <div class="article--image">
            <img class="image" src="@/assets/Blog/article.png" alt="" />
          </div>
          <div class="article--text" v-for="blog in header" :key="blog.id">
            <h4> {{blog.title}} </h4>
            <h6>{{blog.content}}</h6>
            <p>{{ blog.content}}</p>
            <div class="article--timestamp">
              <div class="article--timestamp--image">
                <img class="image--sm" :src="blog.author.avatar" alt="" />
              </div>
              <div class="bloglist--timestamp--text">
                <h5>{{blog.author.name}}</h5>
                <h5>{{blog.author.career_field}} </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="article--bloglist">
        <div v-for="blog in $store.getters.PostState" :key="blog.id" class="article--bloglist--cards">
          <router-link :to="`/blog/${blog.id}`">
          <div class="article--bloglist--image">
            <img class="image" :src="blog.featured_image" alt="key" height="200" width="200" />
          </div>
          <div class="bloglist--text">
            <h4> {{blog.title}} </h4>
            <h6>{{blog.content}}</h6>
            <p>
             {{ blog.content}}
            </p>
            <div class="bloglist--timestamp">
              <div class="bloglist--timestamp--text">
                <h5>{{blog.author.name}}</h5>
                <h5>{{blog.author.career_field}} </h5>
              </div>
              <div class="bloglist--timestamp--image">
                <img class="image--sm" :src="blog.author.avatar" alt="" />
              </div>
            </div>
          </div>
          </router-link>
        </div>
      </div>
      <div class="btn">
         <!-- <button @click="blogsVisible += step" class="btn--bootcamp--filter">Load More...</button> -->
      </div>
    </div>
  </section>
</template>

<script setup>
import {onMounted, ref} from "vue"
import {useStore, mapActions, mapGetters} from "vuex";
const store = useStore();
const getPic = (index) => {
  return `@/assets/${this.blogs[index].img}.png`
}
const header = ref()
onMounted(async () => {
//  visibleBlogs.value = blogs.value.slice(0, this.blogsVisible)
 mapActions(["GetPost"])
 await store.dispatch("GetPost")
 header.value = store.getters.PostState.slice(0, 1)
})
      const blogsVisible = ref(3)
      const visibleBlogs = ref(null)
      const step = ref(3)
      const getblog = ref(null)
      const blogs = ref([
          {
              id: 1,
              title: "LEARNING TO CODE",
              reason: "Why You Should Consider Mentoring Other Developers",
              content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
              img: "code.png"
          },
          {
              id: 2,
              title: "BUSINESS",
              reason: "Why You Should Consider Mentoring Other Developers",
              content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
              img: "barchart.png"
          },
          {
              id: 3,
              title: "CAREER ADVICE",
              reason: "Why You Should Consider Mentoring Other Developers",
              content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
               img: "hack.png"
          },
          {
              id: 4,
              title: "COMMUNITY",
               reason: "Why You Should Consider Mentoring Other Developers",
              content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
              img: "data.png"
          },
          {
              id: 5,
              title: "BUSINESS",
               reason: "Why You Should Consider Mentoring Other Developers",
              content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
              img: "purple.png"
          },
          {
              id: 6,
              title: "UPDATES",
              reason: "Why You Should Consider Mentoring Other Developers",
              content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
              img: "python.png"
          },
          {
              id: 7,
              title: "LEARNING TO CODE",
              reason: "Why You Should Consider Mentoring Other Developers",
              content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
              img: "purple.png"
          },
          {
              id: 8,
              title: "BUSINESS",
              reason: "Why You Should Consider Mentoring Other Developers",
              content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
              img: "python.png"
          },
          {
              id: 9,
              title: "CAREER ADVICE",
             reason: "Why You Should Consider Mentoring Other Developers",
              content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
              img: "data.png"
          }
      ])
// export default {
//     mounted() {
//     },
//     methods: {
//         getPic(index) {
//             return `@/assets/${this.blogs[index].img}.png`
//         }
//     },
//   data() {
//     return {
//       blogsVisible: 3,
//       step: 3,
//       blogs: [
//           {
//               id: 1,
//               title: "LEARNING TO CODE",
//               reason: "Why You Should Consider Mentoring Other Developers",
//               content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
//               img: "code.png"
//           },
//           {
//               id: 2,
//               title: "BUSINESS",
//               reason: "Why You Should Consider Mentoring Other Developers",
//               content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
//               img: "barchart.png"
//           },
//           {
//               id: 3,
//               title: "CAREER ADVICE",
//               reason: "Why You Should Consider Mentoring Other Developers",
//               content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
//                img: "hack.png"
//           },
//           {
//               id: 4,
//               title: "COMMUNITY",
//                reason: "Why You Should Consider Mentoring Other Developers",
//               content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
//               img: "data.png"
//           },
//           {
//               id: 5,
//               title: "BUSINESS",
//                reason: "Why You Should Consider Mentoring Other Developers",
//               content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
//               img: "purple.png"
//           },
//           {
//               id: 6,
//               title: "UPDATES",
//               reason: "Why You Should Consider Mentoring Other Developers",
//               content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
//               img: "python.png"
//           },
//           {
//               id: 7,
//               title: "LEARNING TO CODE",
//               reason: "Why You Should Consider Mentoring Other Developers",
//               content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
//               img: "purple.png"
//           },
//           {
//               id: 8,
//               title: "BUSINESS",
//               reason: "Why You Should Consider Mentoring Other Developers",
//               content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
//               img: "python.png"
//           },
//           {
//               id: 9,
//               title: "CAREER ADVICE",
//              reason: "Why You Should Consider Mentoring Other Developers",
//               content: "bringing in the best tools, implementing best practices, and making apps scalable and maintainable in the long run...",
//               img: "data.png"
//           }
//       ]
//     }
//     },
//     computed: {
//       visibleBlogs() {
//         return this.blogs.slice(0, this.blogsVisible)
//       }
//     }
// };
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.image--sm {
  height: 70px;
  width: 70px;
  border-radius: 100%;
}
.article {
  padding: 3rem 7%;
}
.article--cards {
  margin-top: 3rem;
  @include flex(center, flex-start);
}
.article--cards--cardd {
  display: flex;
  column-gap: 1.5rem;
   max-width: 100vw;
  width: min(100%, 1200px);
  margin: 0 auto;
  height: 400px;
  background: #ebebeb;
  border-radius: 20px 20px 20px 20px;
}
.article--image {
  width: 100%;
  .image {
    height: 100%;
    width: 100%;
    border-radius: 20px 0px 0px 20px;
  }
}
.article--bloglist--image {
  width: 100%;
  .image {
    height: 100%;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
  }
}
.article--text {
  padding: 1.5rem 4%;
  text-align: left;
  h4 {
    @include body-bold {
      font-family: $karla;
      color: $black-100;
    }
  }
  h6 {
    margin-top: 2rem;
    padding-right: 11rem;
    @include sub-heading-bold {
      font-family: $karla;
      color: $secondary-80;
    }
  }
  p {
    margin-top: 2rem;
    padding-right: 11rem;
    @include caption {
      font-family: $karla;
      color: $black-100;
    }
  }
}
.article--timestamp {
  margin-top: 1.5rem;
  display: flex;
  gap: 1.5rem;
  .article--timestamp--image {
    img {
      height: 58px;
      width: 63px;
    }
  }
  .article--timestamp--text {
    align-self: center;
    h5 {
      @include label {
        font-family: $karla;
        color: $black-50;
      }
    }
  }
}
.article--bloglist {
  @media screen and (max-width: 1440px) {
     gap: 4rem;
     grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  }
  gap: 4.68rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  place-items: center;
  max-width: 100vw;
  width: min(100%, 1200px);
  margin: 4rem auto 0 auto ;
  padding: 0;
  .article--bloglist--cards {
    background: #ebebeb;
    width: 340px;
    border-radius: 20px 20px 20px 20px;
    margin: 0;
    height: 595px;
    margin: 1rem 0;
    .bloglist--text {
      padding: 1.5rem 1rem;
      text-align: left;
      h4 {
        @include body-bold {
          font-family: $karla;
          color: $black-100;
        }
      }
      h6 {
        margin-top: 0.7rem;
        @include sub-heading-bold {
          font-family: $karla;
          color: $secondary-80;
        }
      }
      p {
        margin-top: 0.7rem;
        padding-right: 3rem;
        @include caption {
          font-family: $karla;
          color: $black-100;
        }
      }
    }
  }
}
.bloglist--timestamp {
  margin-top: 1.5rem;
  display: flex;
  gap: 1.5rem;
  .bloglist--timestamp--image {
    img {
      height: 58px;
      width: 63px;
    }
  }
  .bloglist--timestamp--text {
    align-self: center;
    h5 {
      @include label {
        font-family: $karla;
        color: $black-50;
      }
    }
  }
}
.btn {
  @include flex(center,center);
  margin-top: 1.7rem;
}
@include xl {
  .article--cards--cardd {
  display: flex;
  flex-direction: column;
  width: min(90%, 570px);
  max-width: 100%;
  height: auto;
}
.article--bloglist {
   width: min(90%, 570px);
   display: flex;
   flex-direction: column;
  .article--bloglist--cards {
     width: min(100%, 570px);
     height: auto;
  max-width: 100%;
    margin: 0.75rem auto;
  }
}
.article--image {
  width: 100%;
  .image {
    height: 100%;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
  }
}
.article--text {
  text-align: left;
  width: 100%;
  h4 {
          padding-right: 0;
        @include body-bold {
          font-family: $karla;
          color: $black-100;
          padding-right: 0;
        }
      }
      h6 {
        margin-top: 0.7rem;
          padding-right: 0;
        @include sub-heading-bold {
          font-family: $karla;
          color: $secondary-80;
        }
      }
      p {
        margin-top: 0.7rem;
        padding-right: 3rem;

        @include caption {
          font-family: $karla;
          color: $black-100;
        }
  }
}
.article--bloglist {
  .article--bloglist--cards {
    
    .bloglist--text {
      text-align: left;
      h4 {
          padding-right: 0;
        @include body-bold {
          font-family: $karla;
          color: $black-100;
          padding-right: 0;
        }
      }
      h6 {
        margin-top: 0.7rem;
          padding-right: 0;
        @include sub-heading-bold {
          font-family: $karla;
          color: $secondary-80;
        }
      }
      p {
        margin-top: 0.7rem;
        padding-right: 3rem;

        @include caption {
          font-family: $karla;
          color: $black-100;
        }
  }
    }
  }
}
}
@include sm {
  .article--text {
  h4 {
    @include body-bold {
      font-size: 1rem;
    }
  }
  h6 {
    margin-top: 2rem;
    padding-right: 0rem;
    @include sub-heading-bold {
      font-size: 1.5rem;
    }
  }
  p {
    margin-top: 2rem;
    padding-right: 0rem;
    @include caption {
      font-family: $karla;
      color: $black-100;
    }
  }
}
.article--bloglist {
  .article--bloglist--cards {
    
    .bloglist--text {
      text-align: left;
       h4 {
    @include body-bold {
      font-size: 1rem;
    }
  }
  h6 {
    margin-top: 2rem;
    padding-right: 0rem;
    @include sub-heading-bold {
      font-size: 1.5rem;
    }
  }
  p {
    margin-top: 2rem;
    padding-right: 0rem;
    @include caption {
      font-family: $karla;
      color: $black-100;
    }
  }
    }
  }
}
}
</style>