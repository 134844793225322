<template>
  <router-link to="/mybootcamps">
    <div class="item">
      <div class="item__top">
        <div class="item__top__outer">
          <span>
            <img :src="logo" alt="" width="20" height="20" />
          </span>
        </div>
      </div>
      <div class="item__bottom">
        <h4>{{ title }}</h4>
        <span>
          <img src="@/assets/svg/note.svg" alt="" />
          {{ total }} Bootcamps
        </span>
        <div class="item__bottom__review">
          <span>
            <div class="icon__container">
              <div class="icon__container__star">
                <div
                  class="icon"
                  :class="{ icon__active: review >= rate.id }"
                  v-for="rate in rating"
                  :key="rate.id"
                  v-html="rate.icon"
                ></div>
              </div>
              <p>{{ review }}</p>
            </div>
          </span>
          <h3># {{ rank }}</h3>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps([
  "title",
  "rank",
  "logo",
  "rating",
  "bootcamp",
  "total",
  "review",
]);
const rating = ref([
  {
    id: 1,
    active: false,
    icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`,
  },
  {
    id: 2,
    active: false,
    icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`,
  },
  {
    id: 3,
    active: false,
    icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`,
  },
  {
    id: 4,
    active: false,
    icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`,
  },
  {
    id: 5,
    active: false,
    icon: `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#FFC100"/> </svg>`,
  },
]);
</script>

<style lang="scss" scoped>
::v-deep .icon {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  svg {
    margin-bottom: 0.3rem;
    cursor: pointer;
    path {
      // fill: #FFC100;
      fill: #c4c4c4;
    }
  }
  &__active {
    svg {
      path {
        fill: #ffc100;
      }
    }
  }
}
.icon {
  &__container {
    @extend %flex-ac-jc;
    gap: 0.75rem;
    @media screen and (max-width: 430px) {
      flex-direction: column;
    }
    &__star {
      @extend %flex-ac-jc;
      gap: 0.3rem;
    }
    p {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: Max(1rem, 16px);
      line-height: Max(1.5rem, 24px);
      letter-spacing: -0.0016em;
      color: $black-100;
      margin-bottom: unset;
    }
  }
}
.item {
  height: Max(9rem, 160px);
  width: Max(14.125rem, 226px);
  background: $white;
  box-shadow: 2px 2px 5px rgba(222, 198, 205, 0.3);
  border-radius: 20px;
  flex-grow: 1;
  cursor: pointer;
  @include respondMax("mobile3x") {
    width: 100%;
  }
  &__top {
    background: $secondary-40;
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    height: Max(1.938rem, 31px);
    position: relative;
    @extend %flex-ac-jc;
    &__outer {
      position: absolute;
      height: Max(2.5rem, 40px);
      width: Max(147px, 9.188);
      border-radius: 8px;
      background-color: $secondary-80;
      @extend %flex-ac-jc;
      margin: 0 auto;
      bottom: 50%;
      span {
        background-color: $white;
        @extend %flex-ac-jc;
        height: 36px;
        width: 36px;
        border-radius: 100%;
      }
    }
  }
  &__bottom {
    padding: 1rem 1.5rem;
    width: 100%;
    text-align: center;
    h4 {
      font-family: "Karla", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: Max(1.125rem, 18px);
      line-height: Max(22px, 1.375rem);
      letter-spacing: 0.085em;
      text-transform: uppercase;
      color: $secondary-80;
    }
    span {
      @extend %flex-ac-jc;
      gap: 0.2rem;
      font-family: "Karla", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: Max(0.75rem, 12px);
      line-height: Max(0.875rem, 14px);
      letter-spacing: 0.015em;
      color: $black;
    }
    &__review {
      @extend %flex-ac-jb;
      h3 {
        font-family: "Karla";
        font-style: normal;
        font-weight: 700;
        font-size: Max(2.5rem, 40px);
        line-height: Max(3.5rem, 56px);
        letter-spacing: -0.04em;
        color: rgba(203, 203, 203, 0.4);
      }
      span {
        @extend %flex-ac-jc;
        gap: 0.2rem;
        font-family: "Karla", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: Max(0.75rem, 12px);
        line-height: Max(0.875rem, 14px);
        letter-spacing: 0.015em;
        color: $black;
      }
    }
  }
}
</style>
