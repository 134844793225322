<template>
  <div id="carouselExampleIndicators" class="carousel slide" data-bs-interval="false" data-bs-pause="hover" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="testimonials--testimonial d-flex w-95">
          <div class="testimonials--testimonial--image">
            <img src="@/assets/Testimonial/student.png" alt="" />
          </div>
          <div class="testimonials--testimonial--text">
            <p>
              This program really helped me mold my idea of what UI/UX
              designer’s work should be. I could directly see my skills improve
              and my toolkit expand.
            </p>
            <h4>- Daniella Akpan</h4>
          </div>
        </div>
    </div>
    <div class="carousel-item">
     <div class="testimonials--testimonial d-flex w-95">
          <div class="testimonials--testimonial--image">
            <img src="@/assets/Testimonial/student.png" alt="" />
          </div>
          <div class="testimonials--testimonial--text">
            <p>
              This program really helped me mold my idea of what UI/UX
              designer’s work should be. I could directly see my skills improve
              and my toolkit expand.
            </p>
            <h4>- Daniella Akpan</h4>
          </div>
        </div>
    </div>
    <div class="carousel-item">
       <div class="testimonials--testimonial d-flex w-95">
          <div class="testimonials--testimonial--image">
            <img src="@/assets/Testimonial/student.png" alt="" />
          </div>
          <div class="testimonials--testimonial--text">
            <p>
              This program really helped me mold my idea of what UI/UX
              designer’s work should be. I could directly see my skills improve
              and my toolkit expand.
            </p>
            <h4>- Daniella Akpan</h4>
          </div>
        </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.carousel-control-prev, .carousel-control-next {
    .carousel-control-prev-icon {
    background-image: url(./arrleft.svg);
    }
    .carousel-control-next-icon {
    background-image: url(./arrright.svg);
    }
}
@include md {
  .carousel-control-prev, .carousel-control-next {
    .carousel-control-prev-icon {
    background-image: url(./arrleft.svg);
    display: none;
    }
    .carousel-control-next-icon {
    background-image: url(./arrright.svg);
    display: none;
    }
}
}
.carousel-indicators {
    button {
        width: 24px;
        background-color: $black-50;
        &.active {
background-color: #8F3D55;
    }
    }
    
}
.testimonials--testimonial {
    width:  80%;
    height: 228.38px;
    display: flex;
    margin: 2rem auto;
    background: $white;
    box-shadow: 5px 4px 4px rgba(143, 61, 85, 0.3);
    .testimonials--testimonial--text {
      align-self: center;
      p {
        @include body {
          color: $black;
          font-family: $karla;
        }
      }
      h4 {
        margin-top: 3rem;
        @include flex(flex-end, flex-end);
        @include button {
          color: $black;
          font-family: $karla;
        }
      }
    }
  }
@include xl {
  .testimonials {
    height: auto;
  }
  .testimonials--testimonial--box {
    height: auto;
.testimonials--testimonial {
  flex-direction: column;
  width: 100%;
  height: auto;
}
  }
}
@include breakpoint(520px) {
  
.testimonials--testimonial {
    height: auto;
     flex-direction: column;
  .testimonials--testimonial--image {
    img {
      width: 100%;
    }
  }
  flex-direction: column;
  width: 80%;
  height: auto;
}
  
}
</style>