<template>
  <section class="results">
    <div class="result--box">
      <div class="bg2">
      <img src="./bg.png" alt="">
    </div>
      <div class="result--text">
        <div class="result--text--head">
          <h3>Personality Test Result</h3>
        </div>
        <div class="result--text--sub">
          <h5>Hello Raymond,</h5>
          <p>
            Your personality test shows that you’re a User Advocate. You possess
            some of the following qualities which further explain your test
            result:
          </p>
          <p>
            Empathy, visualization, anticipation of behavior and communication
            are your underlying strengths. You like to create maps of processes
            and imagine where the pain points are. You make a difference by
            walking in others’ shoes, looking at problems from multiple angles
            and seeing the long-term consequences of today’s actions. Things
            you’re into: storytelling, exploring, listening and sharing.
          </p>
        </div>
        <div class="btn">
          <router-link to="/personalitydetail" >
          <button class="button--home">Details</button></router-link>
        </div>
      </div>
      <div class="result--socials">
        <div class="result--socials--images">
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M45.8334 14.292V35.7087C45.8334 41.292 41.2917 45.8337 35.7084 45.8337H32.8959V32.1045C32.8959 31.5212 33.3751 31.0628 33.9376 31.0628H37.0001C37.5209 31.0628 37.9584 30.667 38.0209 30.1462L38.5001 26.5628C38.5834 25.9378 38.1042 25.3753 37.4792 25.3753H33.9376C33.3751 25.3753 32.8959 24.917 32.8959 24.3337V21.792C32.8959 20.9587 33.0626 20.292 33.3959 19.792C33.8126 19.292 34.5626 19.042 35.6459 19.042H37.6251C38.1876 19.042 38.6667 18.5628 38.6667 18.0003V14.9587C38.6667 14.417 38.2709 13.9587 37.7501 13.917C36.7501 13.8128 35.5834 13.7712 34.3126 13.7712C32.0626 13.7712 30.3126 14.4378 28.9793 15.7712C27.6459 17.1045 27.0626 18.8753 27.0626 21.2087V24.3337C27.0626 24.917 26.5834 25.3753 26.0209 25.3753H23.0001C22.4376 25.3753 21.9584 25.8545 21.9584 26.417V30.0212C21.9584 30.5837 22.4376 31.0628 23.0001 31.0628H26.0209C26.5834 31.0628 27.0626 31.5212 27.0626 32.1045V45.8337H14.2917C8.70842 45.8337 4.16675 41.292 4.16675 35.7087V14.292C4.16675 8.70866 8.70842 4.16699 14.2917 4.16699H35.7084C41.2917 4.16699 45.8334 8.70866 45.8334 14.292Z"
              fill="#8F3D55"
            />
          </svg>
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.7084 4.16699H14.2917C8.70842 4.16699 4.16675 8.70866 4.16675 14.292V35.7087C4.16675 41.292 8.70842 45.8337 14.2917 45.8337H35.7084C41.2917 45.8337 45.8334 41.292 45.8334 35.7087V14.292C45.8334 8.70866 41.2917 4.16699 35.7084 4.16699ZM34.9792 18.4795C36.2709 23.5628 34.8334 28.8962 31.1668 32.167C26.4584 36.3753 18.8126 36.5628 13.2084 32.1878C13.6251 32.2503 14.0001 32.2712 14.3334 32.2503C16.9793 32.1462 18.7917 30.042 19.3542 29.3337C18.3751 28.4795 16.8543 26.9795 15.7918 24.667C14.2918 21.4587 14.4376 18.4378 14.6876 16.7295C14.7501 16.3962 15.1667 16.2712 15.4167 16.542C16.1876 17.417 17.4376 18.6045 19.2709 19.4587C21.2501 20.3753 23.0418 20.542 24.0626 20.542C24.0001 18.0628 25.4793 15.8337 27.7084 14.9795C29.6668 14.2087 31.9376 14.6462 33.5418 16.1045C34.6251 16.0628 35.7084 16.042 36.7918 16.0003C36.1876 16.8336 35.5834 17.6461 34.9792 18.4795Z"
              fill="#8F3D55"
            />
          </svg>
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M39.6042 10.292C35.6458 6.33366 30.4167 4.16699 24.875 4.16699C13.5209 4.16699 4.29168 13.4587 4.29168 24.8753C4.29168 28.3128 5.12502 31.667 6.70836 34.6045L4.22918 43.8753C4.08335 44.3962 4.22919 44.9795 4.62502 45.3753C4.91669 45.667 5.31252 45.8337 5.72919 45.8337C5.85419 45.8337 6.00003 45.8128 6.12503 45.792L15.6458 43.292C18.5208 44.792 21.7084 45.5837 24.875 45.5837C36.4375 45.5837 45.8334 36.292 45.8334 24.8753C45.8334 19.5003 43.625 14.3128 39.6042 10.292ZM33.6875 31.7712L31.9375 33.5003C30.1042 35.3337 25.2708 33.3336 20.9583 29.0211C16.6667 24.7295 14.7292 19.8753 16.4792 18.042L18.2084 16.292C18.4167 16.1253 18.8542 15.8337 19.4167 15.8128C19.9375 15.8128 20.3125 16.042 20.6875 16.292C22.1875 17.3337 22.9584 17.8545 23.25 18.8545C23.6042 20.0628 23.1458 21.167 22.9375 21.5837C23.0625 22.2087 23.4792 23.8753 24.9583 25.3128C26.4375 26.7711 28.1042 27.1878 28.7292 27.3128C29.125 27.042 30.0625 26.5003 31.125 26.7295C32.0209 26.917 32.5625 27.7295 33.6875 29.292C33.9167 29.6253 34.1458 30.0003 34.1667 30.5003C34.1667 31.1045 33.8542 31.5628 33.6875 31.7712Z"
              fill="#8F3D55"
            />
          </svg>
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.0001 4.16699C13.5001 4.16699 4.16675 13.5003 4.16675 25.0003C4.16675 36.5003 13.5001 45.8337 25.0001 45.8337C36.5001 45.8337 45.8334 36.5003 45.8334 25.0003C45.8334 13.5003 36.5001 4.16699 25.0001 4.16699ZM34.4376 17.7712L31.3334 32.7087C31.1042 33.792 30.4792 34.0212 29.5417 33.5628C28.0834 32.5628 26.6042 31.5837 25.1251 30.6045C24.7709 30.3545 24.2709 30.3753 23.9167 30.6462L21.8543 32.2295C21.2709 30.667 20.6668 29.1045 20.0626 27.5628C19.9584 27.2712 19.7501 27.042 19.4584 26.9378C18.3334 26.542 15.7709 25.6253 14.3126 25.0003C14.0626 24.8962 13.6251 24.6878 13.5834 24.3753C13.5626 24.0628 13.8751 23.7086 14.5417 23.4586L33.1251 16.3128C33.9792 16.0003 34.7501 16.542 34.4376 17.7712Z"
              fill="#8F3D55"
            />
          </svg>
        </div>
        <div class="result--socials--text">
          <p>Share with friends:</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0,0)
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.results {
  @include flex(center, flex-start);
  margin-top: 5rem;
}
.result--box {
  width: min(90%, 951px);
  height: 815px;
  background: $white;
  box-shadow: 4px 4px 4px #e1d4d8;
  border-radius: 20px;
  position: relative;
}
.result--text {
  margin: 3rem 0;
  position: relative;
  z-index: 2;
  .result--text--head {
    z-index: 2;
    h3 {
      @include heading2 {
        color: $black-50;
        font-family: $karla;
      }
    }
  }
  .result--text--sub {
    z-index: 2;
    padding: 4rem 15% 3rem 15%;
    text-align: left;
    h5 {
      z-index: 2;
      margin-bottom: 3rem;
      @include sub-heading-bold {
        color: $secondary;
        font-family: $karla;
      }
    }
    p {
      z-index: 2;
      margin: 1rem 0;
      @include body {
        color: $black-50;
        font-family: $karla;
      }
    }
  }
}
.btn {
  z-index: 3;
}
.result--socials {
  margin-top: 4rem;
  .result--socials--image {
    display: flex;
    grid-gap: 3rem;
    margin: 0 0.8;
  }
  .result--socials--text {
    p {
      @include caption {
        color: $gray-100;
        font-family: $karla;
      }
    }
  }
}
.bg2 {
  position: absolute;
  @include flex(flex-end, flex-start);
  margin: 0 0;
  img {
  z-index: 1;
  }
  // top: 13rem;
  // right: 18rem;
}
@include xl {
  .bg2 {
    display: none;
  }
  .result--box {
    height: auto;
  }
}
@include breakpoint(700px) {
  .result--text {
  margin: 3rem 0;
  position: relative;
  z-index: 2;
  .result--text--head {
    h3 {
      @include heading2 {
        font-size: 1.3rem
      }
    }
  }
  .result--text--sub {
    z-index: 2;
    padding: 2rem 5% 2rem 5%;
    text-align: left;
    h5 {
      margin-bottom: 1.5rem;
      @include sub-heading-bold {
        font-size: 1.5rem;
      }
    }
    p {
      @include body {
        font-size: 1.1rem;
      }
    }
  }
}
}
@include md {
  .result--text {
  margin: 3rem 0;
  position: relative;
  z-index: 2;
  .result--text--head {
    h3 {
      @include heading2 {
        font-size: 1.3rem
      }
    }
  }
  .result--text--sub {
    z-index: 2;
    padding: 2rem 5% 2rem 5%;
    text-align: left;
    h5 {
      margin-bottom: 1.5rem;
      @include sub-heading-bold {
        font-size: 1.1rem;
      }
    }
    p {
      @include body {
        font-size: 0.9rem;
      }
    }
  }
}
.result--socials {
  margin-top: 1rem;
}
}
@import "@/styles/TestStyle/Test.scss";
</style>s