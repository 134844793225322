import axios from 'axios'

const state = {
    user: null,
    owner: null,
    link: null,
    message: '',
    community: '',
    filtered: "",
    notify: "",
    metric: "",
    moodboard: "",
    searchMoodboard: ""

}

const getters = {
    isAuthenticated: state => !!state.user,
    StateOwner: state => state.owner,
    LinksState: state => state.link,
    StateMessage: state => state.message,
    stateCommunity: state => state.community,
    stateFiltered: state => state.filtered,
    StateNotify: state => state.notify,
    StateMetric: state => state.metric,
    StateMoodBoard: state => state.moodboard,
    StateMoodboardSearch: state => state.searchMoodboard

};

const actions = {
    // signup
    async SignUp({commit}, Form) {
        let res = await axios.post('register', Form)
        window.localStorage.setItem(
            "token",
            res.data.token
          );
          window.sessionStorage.setItem('user', 'authenticated')
    },

    // login user
     async LogIn({commit}, Form) {
        let res = await axios.post('login', Form)
        window.localStorage.setItem(
            "token",
            res.data.token
          );
          window.sessionStorage.setItem('user', 'authenticated')
     },

    //  google oauth
    async Oauth({commit}, Form) {
        let res = await axios.post('google-oauth', Form)
        window.localStorage.setItem(
            "token",
            res.data.token
          );
          window.sessionStorage.setItem('user', 'authenticated')
     },

    //  logout user
    async Logout({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.post("logout", {headers: headers})
        window.localStorage.setItem(
            "token",
            null
          );
    },
    // get user info on dashboard
    async User({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get("session", {headers: headers})
         
        let links = res.data.link
        let owner = res.data
        await commit('setOwner', owner)
        await commit('setLink', links)
    },
    async Community({commit}) {
        let res = await axios.get("communities")
        let community = res.data.data.Communities
        await commit('setCommunity', community)
    },
    async Filtered({commit, Country}) {
        let res = await axios.get(`communities?filter[location]=nigeria`)
        let filtered = res.data.data.Communities
        await commit('setFiltered', filtered)
        
    },
    async ChangePassword({commit}, Data) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.post("change-password", Data, {headers: headers})
         
    },
    async Update({commit}, Data) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.post("update-profile", Data, {headers: headers})
         
    },
    async Avatar({commit}, Image) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.post("update-avatar", Image, {headers: headers})
         
    },
    async Reset({commit}, Email) {
        let res = await axios.post("reset-password")
         
    },
    async Delete({commit}, Id) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.delete(`users/delete/${Id}`, {headers: headers})
         
    },
    async Notif({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get("notifications", {headers: headers})
         
        let item = res.data.data
        await commit("setNotify", item)
    },
    async Metric({commit}) {
         let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
        }
        let res = await axios.get('metric', {headers: headers})
         
        let item = res.data
        await commit("setMetric", item)
    },
    async Moodboard({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`users/moodboard`, {headers: headers})
         
        let item = res.data.data.data
        await commit("setMoodboard", item)
    },
    async MoodboardSearch({commit}, Name) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`users/moodboard?query=${Name}`, {headers: headers})
         
        let data = res.data.data.data
        await commit("setMoodboardSearch", data)
    }
};

const mutations = {
    setUser(state, person) {
        state.user = person
    },
    setOwner(state, owner) {
        state.owner = owner
    },
    setLink(state, links) {
        state.link = links
    },
    setMessage(state, message) {
        state.message = message
    },
    setCommunity(state, community) {
        state.community = community
    },
    setFiltered(state, filtered) {
        state.filtered = filtered
    },
    setNotify(state, item) {
        state.notify = item
    },
    setMetric(state, item) {
        state.metric = item
    },
    setMoodboard(state, item) {
        state.moodboard = item
    },
    setMoodboardSearch(state, data) {
        state.searchMoodboard = data
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}