<template>
<Payment v-if="active" :active="active" @togglePay.self="closePayment($event)" />
  <section class="plan">
    <div class="plan--box">
      <div class="plan--box--text">
        <h2>Pricing</h2>
      </div>
      <div class="plan--premium">
        <div class="plan--premium--box">
          <div class="plan--premium--box--image">
            <img src="@/assets/Premium/man.png" alt="" />
          </div>
          <div class="plan--premium--box--text">
            <h3>Free</h3>
            <h5>Basic access to::</h5>
            <ul>
              <li v-for="item in free" :key="item.id">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM16.6644 8.75259C17.0771 9.11951 17.1143 9.75158 16.7474 10.1644L11.4141 16.1644C11.2243 16.3779 10.9523 16.5 10.6667 16.5C10.381 16.5 10.109 16.3779 9.91926 16.1644L7.25259 13.1644C6.88567 12.7516 6.92285 12.1195 7.33564 11.7526C7.74842 11.3857 8.38049 11.4229 8.74741 11.8356L10.6667 13.9948L15.2526 8.83564C15.6195 8.42285 16.2516 8.38567 16.6644 8.75259Z"
                      fill="#444449"
                    />
                  </svg>
                  <h6>{{ item.title }}</h6>
                </div>
              </li>
            </ul>
            <div class="btn">
              <button @click="openPay" class="button--plan">{{ logged ? "Choose Plan" : "Log In" }}</button>
            </div>
          </div>
        </div>
        <div class="plan--premium--box">
          <div class="plan--premium--box--image">
            <img src="@/assets/Premium/woman.png" alt="" />
          </div>
          <div class="plan--premium--box--text">
            <h3>Paid-Monthly</h3>
            <h5>Unlimited access to:</h5>
            <ul>
              <li v-for="item in monthly" :key="item.id">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM16.6644 8.75259C17.0771 9.11951 17.1143 9.75158 16.7474 10.1644L11.4141 16.1644C11.2243 16.3779 10.9523 16.5 10.6667 16.5C10.381 16.5 10.109 16.3779 9.91926 16.1644L7.25259 13.1644C6.88567 12.7516 6.92285 12.1195 7.33564 11.7526C7.74842 11.3857 8.38049 11.4229 8.74741 11.8356L10.6667 13.9948L15.2526 8.83564C15.6195 8.42285 16.2516 8.38567 16.6644 8.75259Z"
                      fill="#444449"
                    />
                  </svg>
                  <h6>{{ item.title }}</h6>
                </div>
              </li>
            </ul>
            <div class="btn">
              <button @click="openPay" class="button--plan">$10/Month</button>
            </div>
          </div>
        </div>
        <div class="plan--premium--box">
          <div class="plan--premium--box--image">
            <img src="@/assets/Premium/people.png" alt="" />
          </div>
          <div class="plan--premium--box--text general">
            <h4>Paid-Annually</h4>
            <h6>Unlimited access to:</h6>
            <ul>
              <li v-for="item in annual" :key="item.id">
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM16.6644 8.75259C17.0771 9.11951 17.1143 9.75158 16.7474 10.1644L11.4141 16.1644C11.2243 16.3779 10.9523 16.5 10.6667 16.5C10.381 16.5 10.109 16.3779 9.91926 16.1644L7.25259 13.1644C6.88567 12.7516 6.92285 12.1195 7.33564 11.7526C7.74842 11.3857 8.38049 11.4229 8.74741 11.8356L10.6667 13.9948L15.2526 8.83564C15.6195 8.42285 16.2516 8.38567 16.6644 8.75259Z" fill="white"/>
</svg>

                  <h6 style="color: white;">{{ item.title }}</h6>
                </div>
              </li>
            </ul>
            <div class="btn">
              <button @click="openPay" class="button--plan">$60/Annum</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Payment from '@/Modals/Payment/Payment.vue'
export default {
  components: {
        Payment
    },
    methods: {
        openPay() {
          if (window.sessionStorage.getItem("user") != null) {
            this.$router.push("/setting")
    } else {
       
        this.$store.state.AccessState = 1
    }
        },
        closePayment(closed) {
            this.active = closed
        }
    },
  data() {
    return {
      active: false,
      logged: null,
      free: [
        {
          id: 1,
          title: "Projects",
        },
        {
          id: 2,
          title: "Interview Questions and Solutions",
        },
        {
          id: 3,
          title: "Road Maps",
        },
        {
          id: 4,
          title: "No Mentorship",
        },
        {
          id: 5,
          title: "Curriculums",
        },
        {
          id: 6,
          title: "Intro videos only",
        },
        {
          id: 9,
          title: "Invite to Private Discord Group"
        }
      ],
      monthly: [
        {
          id: 1,
          title: "Projects + Solutions",
        },
        {
          id: 2,
          title: "Interview Questions and Solutions",
        },
        {
          id: 3,
          title: "Road Maps",
        },
        {
          id: 4,
          title: "All Mentors",
        },
        {
          id: 5,
          title: "Curriculum",
        },
        {
          id: 6,
          title: "All videos resource.",
        },
        {
          id: 7,
          title: "Support Team",
        },
        {
          id: 8,
          title: "Collaboration with other techies",
        },
        {
          id: 9,
          title: "Internship assistance",
        },
        {
          id: 10,
          title: " Group mentorship sessions",
        },
        {
          id: 11,
          title: "Live Events",
        },
      ],
      annual: [
        {
          id: 1,
          title: "Projects + Solutions",
        },
        {
          id: 2,
          title: "Interview Questions and Solutions",
        },
        {
          id: 3,
          title: "Road Maps",
        },
        {
          id: 4,
          title: "All Mentors",
        },
        {
          id: 5,
          title: "Curriculum",
        },
        {
          id: 6,
          title: "All videos resource.",
        },
        {
          id: 7,
          title: "Support Team",
        },
        {
          id: 8,
          title: "Collaboration with other techies",
        },
        {
          id: 9,
          title: "Internship assistance",
        },
        {
          id: 10,
          title: " Group mentorship sessions",
        },
        {
          id: 11,
          title: "Live Events",
        },
      ],
    };
  },
  onMounted() {
    if (window.sessionStorage.getItem("user") != null) {
            this.logged = false
    } else {
       
      this.logged = true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.plan {
  padding: 5rem 7%;
  text-align: center;
}
.plan--box--text {
  h2 {
    @include heading3 {
      color: $secondary-80;
      font-family: $karla;
    }
  }
}
.plan--premium--box--text {
  background: #ffffff;
  border: 2px solid #8f3d55;
  box-sizing: border-box;
  border-radius: 0px 58px 0px 0px;
  width: 319px;
  height: auto;
  /* &.general {
    background-color: $secondary-80;
            h3 {
            color: white;
            }

  } */
}
.plan--premium {
  @include flex(space-around, flex-start);
  .plan--premium--box {
    margin: 0.7rem 0;
    .plan--premium--box--image {
      img {
        height: 138px;
      }
    }
    .plan--premium--box--text {
      h3 {
        margin: 1rem 0;
        @include sub-heading-bold {
          color: $secondary-80;
          font-family: $karla;
        }
      }
      h5 {
        @include caption {
          color: $black-100;
          font-family: $karla;
        }
      }
      ul {
        li {
          margin: 0.7rem;
          div {
            @include flex(flex-start, flex-start);
            gap: 0.4rem;
            h6 {
              align-self: flex-start;
              @include caption {
                color: $black-100;
                font-family: $karla;
                text-align: left;
              }
            }
          }
        }
      }
      button {
        margin-top: 1.5rem;
        &:nth-child(3) {
          color: red;
        }
      }
    }
  }
}
.btn {
  @include flex(center,center);
}
.general {
  background: $secondary-80;
  h4 {
  margin: 1rem 0;
        @include sub-heading-bold {
          color: $white;
          font-family: $karla;
        }
  }
  h6 {
        @include caption {
          color: $white;
          font-family: $karla;
        }
      }
       ul {
        li {
          margin: 0.7rem;
          div {
            @include flex(center, flex-start);
            gap: 0.4rem;
            h6 {
              align-self: center;
              @include caption {
                color: $white;
                font-family: $karla;
              }
            }
          }
        }
      }
}
@include xl {
  .plan--premium {
    flex-wrap: wrap;
  }
}
@include breakpoint(350px) {

}
</style>