<template>
  <Design />
</template>

<script>
import Design from '@/components/Courses/Design/Design.vue'
export default {
components: {
    Design
}
}
</script>

<style>

</style>