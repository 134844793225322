<template>
  <Test />
</template>

<script>
import Test from '@/pages/Personality/Test.vue'
export default {
    components: {
        Test
    }
}
</script>

<style>

</style>