<template>
  <section class="login" @click.self="close">
    <div class="login__main">
      <nav>
        <p @click="close">X</p>
      </nav>
      <div class="login__text">
        <h2>Create a new account</h2>
        <p>
          Begin your tech career and grow into Tech Hero with the right
          resources we provide.
        </p>
      </div>
      <button @click="base">
        <img src="@/assets/google.png" height="27" width="27" alt="" />
        Sign up with Google
      </button>
      <h6>or Signup with your full details</h6>
      <form action="dashboard" method="post" @submit.prevent="handleSubmit">
        <p
          v-show="error"
          style="
            color: red;
            text-align: center;
            margin-top: 0.5rem;
            margin-bottom: 1rem;
          "
        >
          {{ MessageError }}
        </p>
        <input
          type="text"
          name=""
          placeholder="Full Name"
          required
          v-model="Form.name"
        />
        <input
          type="email"
          name=""
          placeholder="Email Address"
          required
          v-model="Form.email"
        />
        <input
          type="password"
          name=""
          placeholder="Password"
          class="last"
          required
          v-model="Form.password"
        />
        <p class="passNumb">Between 8 and 72 characters</p>

        <h3>Gender</h3>

        <div class="gender">
          <span>
            <input type="checkbox" name="" id="" />
            Male
          </span>
          <span>
            <input type="checkbox" name="" id="" />
            Female
          </span>
          <span>
            <input type="checkbox" name="" id="" />
            I prefer not to say
          </span>
        </div>
        <h6>
          By creating your zero to hero account, you agree to our
          <span>Terms, Data Policy</span>
          and
          <span>Cookie Policy.</span>
        </h6>
        <button>Login</button>
        <p>
          Already have an acoount?
          <span @click="loginSwitch">Login</span>
        </p>
        <a href="/dashboard" ref="dash" style="display: none"></a>
      </form>
    </div>
  </section>
</template>

<script setup>
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {auth} from "@/FirebaseConfig"
import { useStore, mapActions } from "vuex";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
const store = useStore();
const router = useRouter();
const error = ref(true);
const MessageError = ref("");
const loginSwitch = () => {
  store.state.AccessState = 2;
};
const close = () => {
  store.state.AccessState = null;
};
const dash = ref(null);
mapActions(["SignUp"]);
const handleSubmit = async () => {
  try {
    await store.dispatch("SignUp", Form.value);
    // window.sessionStorage.setItem("user", "authenticated")
    store.state.AccessState = null;
    dash.value.click();
    router.push("/dashboard");
  } catch (error) {
    MessageError.value = error.response.data.message;
    error.value = false;
  }
};
const Form = ref({
  email: "",
  name: "",
  password: "",
});
const Fire = ref({
    name: "",
    email: "",
    avatar: ""
})
const base = () => {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
        signInWithPopup(auth, provider).then(async (result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                Fire.value.name = user.displayName
                Fire.value.email = user.email
                Fire.value.avatar = user.photoURL
                await store.dispatch("Oauth", Fire.value)
                window.sessionStorage.setItem("user", "authenticated")
                store.state.AccessState = null
                dash.value.click();
                router.push("/dashboard")
            }).catch((error) => {
                // Handle Errors here.
                MessageError.value = error.response.data.message
                error.value = false
            });
        }
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 4;
  @include flex(center, center);
  background-color: #dddddd80;
  &__text {
    width: min(320px, 100%);
    margin: 2rem auto;
    h2 {
      font-family: "Karla", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: Max(2rem, 22px);
      line-height: Max(2rem, 22px);
      text-align: center;
      letter-spacing: -0.045em;
      color: $secondary;
      margin-bottom: 2rem;
    }
    p {
      font-family: "Karla", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: Max(0.875rem, 12px);
      line-height: Max(0.875rem, 14px);
      color: $gray-60;
    }
  }
  &__main {
    width: Min(90%, 478px);
    height: 100%;
    background-color: $white;
    padding: 3rem 1.5rem;
    text-align: center;
    overflow: auto;
    scrollbar-width: none;
    nav {
      p {
        text-align: right;
        font-family: "Karla", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: Max(1rem, 16px);
        line-height: Max(1, 16px);
        letter-spacing: 0.015em;
        color: $black;
        cursor: pointer;
      }
    }
    &::-webkit-scrollbar {
      appearance: none;
      display: none;
    }

    button {
      width: min(320px, 100%);
      height: Max(2.75rem, 44px);
      background: $secondary-40;
      border-radius: 6px;
      @extend %flex-ac-jc;
      gap: 1rem;
      font-family: "Karla", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: Max(0.75rem, 12px);
      line-height: Max(0.875rem, 12px);
      letter-spacing: 0.015em;
      color: $secondary;
      margin: 1rem auto;
    }
    h6 {
      font-family: "Karla", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: Max(0.75rem, 12px);
      line-height: Max(0.875rem, 12px);
      letter-spacing: 0.015em;
      color: $secondary;
      margin: 1.5rem 0;
    }
    form {
      width: min(320px, 100%);
      margin: 2rem auto;
      input[type="text"],
      input[type="email"],
      input[type="password"] {
        background: $white;
        border: 1px solid #b8b8b8;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        width: 100%;
        height: Max(3rem, 46px);
        padding: 1rem 1.5rem;
        margin: 1rem 0;
        &::placeholder {
          font-family: "Karla", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: Max(0.75rem, 12px);
          line-height: Max(0.875, 14px);
          letter-spacing: 0.015em;
          color: $gray-20;
        }
        font-family: "Karla", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: Max(1rem, 16px);
        line-height: Max(1, 16px);
        letter-spacing: 0.015em;
        color: $black;
        &.last {
          margin: unset;
        }
      }
      .password {
        @extend %flex-ac;
        width: 100%;
        background: $white;
        border: 1px solid #b8b8b8;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        padding: 1.5px 1px;
        margin: 1rem 0;
        input {
          border: none;
          border-radius: 0;
        }
      }
      h3 {
        font-family: "Karla", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: Max(1.375rem, 18px);
        line-height: Max(1.5rem, 24px);
        letter-spacing: -0.015em;
        color: $black-50;
        text-align: left;
      }
      .gender {
        @extend %flex-ac;
        gap: 1rem;
        span {
          @extend %flex-ac;
          gap: 0.35rem;
        }
      }
      button {
        width: 100%;
        height: Max(3rem, 44px);
        background: $secondary-80;
        border-radius: 8px;
        font-family: "Space Grotesk", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: Max(1.125rem, 18px);
        line-height: Max(1.5rem, 24px);
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: $white;
      }
      p {
        font-family: "Karla";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: Max(0.875rem, 14px);
        text-align: center;
        letter-spacing: 0.015em;
        color: $black-100;
        text-align: left;
        span {
          color: $secondary;
          cursor: pointer;
        }
        &.passNumb {
          font-family: "Karla", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: Max(0.75em, 12px);
          line-height: Max(0.875rem, 14px);
          letter-spacing: 0.015em;
          color: $gray-60;
        }
      }
      h6 {
        font-family: "Karla", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: Max(0.75em, 12px);
        line-height: Max(0.875rem, 14px);
        letter-spacing: 0.015em;
        color: $black-50;
        span {
          color: $secondary;
        }
        padding: 0 2rem;
      }
    }
  }
}
</style>
