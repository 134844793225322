<template>
  <section class="intro">
    <div class="bg">
      <img src="./bg-big.png" alt="" />
    </div>
    <div class="intro--box">
      <div class="intro--text">
        <h2>Curriculum: UI/UX</h2>
        <p>
          The UI/UX Design course brings a design-centric approach to user
          interface and user experience design, and offers practical,
          skill-based instruction centered around a visual communications
          perspective, rather than on one focused on marketing or programming
          alone.
        </p>
      </div>
      <div class="container">
        <div class="intro--desc">
          <div class="intro--desc--image">
            <img
              src="@/assets/Curriculum/children.svg"
              alt=""
              height="247"
              width="400"
            />
          </div>
          <div class="class--intro--start">
            <h4>Get started today</h4>
            <div class="class--intro--start--button">
              <router-link to="/myBootcamps">
                <button class="button--curr">
                Bootcamps
              </button>
              </router-link>
              <router-link to="/roadmaps">
                <button class="button--curr btn--white">View Roadmap</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from "vue-router"
const router = useRouter()
const handleMove = () => {
  router.push("/mybootcamps")
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.intro {
  height: 649px;
  max-width: 100vw;
  width: 100%;
  @media screen and (max-width: 1250px) {
    height: auto;
  }
}
.intro--box {
  padding: 5rem 7%;
  @include flex(space-between, flex-start);
  position: absolute;
  z-index: 2;
  gap: 2rem;
  max-width: 100vw;
  @media screen and (max-width: 1250px) {
    flex-direction: column;
    position: static;
    text-align: center;
    justify-content: center;
  }
  /* margin: 0 auto; */
  .intro--text {
    align-self: center;
    text-align: left;
    z-index: 2;
    width: 100%;
    h2 {
      margin-bottom: 2rem;
      z-index: 2;
      @include heading1 {
        color: $secondary-80;
        font-family: $karla;
      }
    }
    p {
      z-index: 2;
      @include body {
        color: $secondary-80;
        font-family: $karla;
      }
    }
  }
}
.container {
  @include flex(flex-end, flex-start);
  overflow-x: hidden;
  width: 100%;
}
.intro--desc {
  width: 25rem;
  position: relative;
  .intro--desc--image {
    width: 25rem;
    text-align: right;
    @include flex(flex-end, flex-start);

    img {
      transition: all 0.6s ease-in-out;
      width: 100%;
      height: 100%;
    }
  }
  p {
    width: 25rem;
    margin: 2rem auto 0 auto;
    @include caption {
      color: $secondary-80;
      font-family: $karla;
    }
  }
}
.class--intro--start {
  width: 25rem;
  height: 150px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(184, 107, 65, 0.3);
  border-radius: 10px;
  text-align: left;
  padding: 2rem 2.5rem;
  @media screen and (max-width: 1250px) {
    ax-width: 100vw;
    width: min(85%, 450px);
    margin: 0 auto;
    position: relative;
    height: auto;
    ax-width: 100vw;
    width: min(85%, 450px);
    margin: 0 auto;
    position: relative;
    height: auto;
  }
  h4 {
    margin-bottom: 1rem;
    @include caption {
      color: $secondary-80;
      font-family: $karla;
    }
  }
  .class--intro--start--button {
    display: flex;
    gap: 2rem;
  }
}

.btn--white {
  background: white;
  border: 1px solid #8f3d55;
  box-sizing: border-box;
  border-radius: 91px;
  color: $secondary;
}
.bg {
  position: absolute;
  z-index: 1;
  left: 15%;
  @media screen and (max-width: 1250px) {
    display: none;
  }
  img {
    width: 90%;
  }
}
@include xl {
  .bg {
    display: none;
  }
  .intro {
    height: auto;
  }
  .intro--box {
    flex-direction: column;
    gap: 2.5rem;
    position: static;
    .intro--text {
      align-self: center;
      max-width: 100vw;
      width: min(90%, 570px);
      text-align: center;
      margin: 0 auto;
      h2 {
        margin-bottom: 2rem;
        z-index: 2;
        @include heading1 {
          color: $secondary-80;
          font-family: $karla;
        }
      }
      p {
        z-index: 2;
        @include body {
          color: $secondary-80;
          font-family: $karla;
        }
      }
    }
  }
  .intro--desc {
    max-width: 100vw;
    width: min(85%, 450px);
    margin: 0 auto;
    position: relative;
    .intro--desc--image {
      text-align: right;
      @include flex(flex-end, flex-start);
      /* img {
            @include flex(flex-end, flex-start);
            padding-left: 12rem;
        } */
    }
    p {
      max-width: 100vw;
      width: min(85%, 450px);
      margin: 2rem auto 0 auto;
      @include caption {
        color: $secondary-80;
        font-family: $karla;
      }
    }
  }
  .class--intro--start {
    max-width: 100vw;
    width: min(85%, 450px);
    margin: 0 auto;
    position: relative;
    height: auto;
    h4 {
      margin-bottom: 1rem;
      @include caption {
        color: $secondary-80;
        font-family: $karla;
      }
    }
    .class--intro--start--button {
      display: flex;
      gap: 2rem;
    }
  }
}
@include sm {
  .intro--box {
    .intro--text {
      h2 {
        margin-bottom: 1rem;
        @include heading1 {
          font-size: 3rem;
        }
      }
      p {
        z-index: 2;
        @include body {
          font-size: 1rem;
        }
      }
    }
  }
  .intro--desc {
    .intro--desc--image {
      text-align: right;
      max-width: 100vw;
      width: min(85%, 450px);
      margin: 0 auto;
      img {
        margin: 0 auto;
      }
    }
    p {
      max-width: 100vw;
      width: min(85%, 450px);
      margin: 1rem auto;
      @include caption {
        font-size: 0.8rem;
      }
    }
  }
  .class--intro--start {
    h4 {
      @include flex(center, center);
      margin-bottom: 1rem;
      @include caption {
        font-size: 0.8rem;
      }
    }
    .class--intro--start--button {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;
    }
  }
  .btn--white {
    background: white;
    border: 1px solid #8f3d55;
    box-sizing: border-box;
    border-radius: 91px;
    color: $secondary;
    margin-top: 0.7rem;
  }
}
@include xs {
  .intro--box {
    .intro--text {
      h2 {
        margin-bottom: 1rem;
        @include heading1 {
          font-size: 1.5rem;
        }
      }
      p {
        z-index: 2;
        @include body {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>
