<template>
  <section class="intro">
    <div class="intro--box">
      <div class="intro--text">
        <h1>DEVELOPMENT & OPERATIONS</h1>
        <p>
          DevOps engineering involves the designing, building, and maintaining
          the systems and processes that enable seamless integration between
          software development and operations teams, with the goal of achieving
          efficient and reliable software delivery. DevOps engineers are
          responsible for creating and managing automation tools and frameworks,
          such as build systems, deployment scripts, configuration management
          tools, and monitoring and logging systems, to streamline the software
          development process.
        </p>
        <button @click="handleMove" class="btn--course">View Curriculum</button>
        <div class="intro--text--figures">
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.mentors}}</h1>
            <p>
              <router-link to="/mentors"> MENTORS </router-link>
            </p>
          </div>
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.bootcamps}}</h1>
            <p>
              <router-link to="/bootcamp"> BOOTCAMPS </router-link>
            </p>
          </div>
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.projects}}</h1>
            <p>PROJECT</p>
          </div>
        </div>
      </div>
      <div class="intro--image">
        <img
          v-if="active"
          src="@/assets/Courses/DevOps/girl.png"
          alt=""
          height="663"
          width="736"
        />
        <img
          v-else
          src="@/assets/Courses/DevOps/girl2.svg"
          alt=""
          height="663"
          width="736"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      active: true,
    };
  },
  mounted() {
    setInterval(() => {
      this.active = !this.active;
    }, 6000);
  },
  methods: {
    handleMove() {
      if (window.sessionStorage.getItem("user") != null) {
        this.$router.push("/curriculum");
      } else {
        this.$store.state.AccessState = 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/Courses/intro.scss";
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";

/* .intro--box {
  @include flex(space-around, center);
  flex-direction: column;
  grid-gap: 5rem;

  .intro--text {
    text-align: center;
    width: 60%;
  }
}
@include xl {
  .intro--box {
        @include flex(center, center);
        align-content: center;
        flex-direction: column;
        grid-gap: 2rem;
      
        .intro--text {
          text-align: center;
          max-width: 100%;
          width: min(90%, 690px);
        }
      }
} */
img {
  transition: opacity 1s ease-in-out;
  animation: fade ease-in-out 2.5s;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* @include xl {
  .intro {
    height: 775px;
  }
} */
</style>
