<template>
  <section class="careerpath">
    <div class="careerpath--box">
      <div class="careerpath--text">
        <img class="image" src="@/assets/CareerPath/black.png" alt="" />
        <h2>Career Paths</h2>
        <img class="image" src="@/assets/CareerPath/white.png" alt="" />
      </div>
      <div class="intro--search--settings">
        <span @click="filter = !filter">
          <img src="@/assets/svg/filter.svg" alt="" />
          Filter Careers
        </span>
        <div v-if="filter" class="intro--search--settings--filter">
              <div class="intro--search--settings--filter--input">
                  <input type="checkbox" name="" id="">
                  <label for="">Coding</label>
              </div>
              <div class="intro--search--settings--filter--input">
                  <input type="checkbox" name="" id="">
                  <label for="">Design</label>
              </div>
              <div>
                  <button class="btn--bootcamp--filter">
                      Filter
                  </button>
              </div>
          </div>
      </div>
      <!-- <Filter v-if="filter" /> -->
      <div class="careerpath--paths">
        <div class="careerpath--paths--path">
          <div class="careerpath--paths--path--image">
            <img class="img" src="@/assets/fe.svg" alt="" />
          </div>
          <div
            class="careerpath--paths--path--text"
            style="background: #b86b41"
          >
            <h4>Front End Development</h4>
            <h5>
              <router-link to="/frontend">
                View career path
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.172 11.0002L10.808 5.63617L12.222 4.22217L20 12.0002L12.222 19.7782L10.808 18.3642L16.172 13.0002H4V11.0002H16.172Z"
                    fill="white"
                  />
                </svg>
              </router-link>
            </h5>
          </div>
        </div>
        <div class="careerpath--paths--path">
          <div class="careerpath--paths--path--image">
            <img class="img" src="@/assets/ux.svg" alt="" />
          </div>
          <div
            class="careerpath--paths--path--text"
            style="background: #444449"
          >
            <h4>UI/UX Design</h4>
            <h5>
              <router-link to="/design">
                View career path
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.172 11.0002L10.808 5.63617L12.222 4.22217L20 12.0002L12.222 19.7782L10.808 18.3642L16.172 13.0002H4V11.0002H16.172Z"
                    fill="white"
                  />
                </svg>
              </router-link>
            </h5>
          </div>
        </div>
        <div class="careerpath--paths--path">
          <div class="careerpath--paths--path--image">
            <img class="img" src="@/assets/chart.svg" alt="" />
          </div>
          <div
            class="careerpath--paths--path--text"
            style="background: #a44a65"
          >
            <h4>Data Science</h4>
            <h5>
              <router-link to="/datascience">
                View career path
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.172 11.0002L10.808 5.63617L12.222 4.22217L20 12.0002L12.222 19.7782L10.808 18.3642L16.172 13.0002H4V11.0002H16.172Z"
                    fill="white"
                  />
                </svg>
              </router-link>
            </h5>
          </div>
        </div>
        <div class="careerpath--paths--path">
          <div class="careerpath--paths--path--image">
            <img class="img" src="@/assets/be.svg" alt="" />
          </div>
          <div
            class="careerpath--paths--path--text"
            style="background: #a44a65"
          >
            <h4>Back End Development</h4>
            <h5>
              <router-link to="/backend">
                View career path
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.172 11.0002L10.808 5.63617L12.222 4.22217L20 12.0002L12.222 19.7782L10.808 18.3642L16.172 13.0002H4V11.0002H16.172Z"
                    fill="white"
                  />
                </svg>
              </router-link>
            </h5>
          </div>
        </div>
        <div class="careerpath--paths--path">
          <div class="careerpath--paths--path--image">
            <img class="img" src="@/assets/fe.svg" alt="" />
          </div>
          <div
            class="careerpath--paths--path--text"
            style="background: #b86b41"
          >
            <h4>Blockchain Development</h4>
            <h5>
              <router-link to="">
                View career path
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.172 11.0002L10.808 5.63617L12.222 4.22217L20 12.0002L12.222 19.7782L10.808 18.3642L16.172 13.0002H4V11.0002H16.172Z"
                    fill="white"
                  />
                </svg>
              </router-link>
            </h5>
          </div>
        </div>
        <div class="careerpath--paths--path">
          <div class="careerpath--paths--path--image">
            <img class="img" src="@/assets/bro.svg" alt="" />
          </div>
          <div
            class="careerpath--paths--path--text"
            style="background: #444449"
          >
            <h4>Dev Ops</h4>
            <h5>
              <router-link to="/devops">
                View career path
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.172 11.0002L10.808 5.63617L12.222 4.22217L20 12.0002L12.222 19.7782L10.808 18.3642L16.172 13.0002H4V11.0002H16.172Z"
                    fill="white"
                  />
                </svg>
              </router-link>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Filter from "@/shared/Filter.vue";
export default {
  components: {
    Filter,
  },
  data() {
    return {
      filter: false,
    };
  },
  methods: {
    handleFilter() {
      this.filter = !this.filter;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
/* filter */
.intro--search--settings {
  @include flex(space-between, center);
  margin: 0 auto;
  width: min(90%, 50rem);
  span {
    font-family: "Karla";
    font-style: normal;
    font-weight: 400;
    font-size: Max(1.125rem, 18px);
    line-height: Max(1.5rem, 24px);
    letter-spacing: -0.015em;
    color: $secondary-80;
    cursor: pointer;
  }
  .intro--search--settings--filter {
    width: 100%;
    @include flex(space-around, center);
    gap: 3rem;
    .intro--search--settings--filter--input {
      align-self: center;
      margin: auto 0;
      label {
        margin: 0 0.5rem;
        @include label {
          color: $black-50;
          font-family: $karla;
        }
      }
    }
  }
}
.careerpath--text {
  padding: 2rem 3% 0rem 3%;
  background: #f9f2ee;
  @include flex(space-around, center);
  h2 {
    @include title {
      font-family: $karla;
      color: $secondary;
    }
  }
}
.careerpath--paths {
  padding: 3rem 7%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2.5rem;
  place-items: center;
}
.careerpath--paths--path {
  background: #f0ded5;
  border: 1px solid #8f3d55;
  box-sizing: border-box;
  border-radius: 10px;
  width: 324px;
  height: 331px;
  text-align: center;
  .careerpath--paths--path--image {
    height: 192px;
    @include flex(center, center);
    .img {
      display: block;
      height: auto;
      align-self: center;
      margin: auto;
    }
  }
  .careerpath--paths--path--text {
    height: 139px;
    margin: auto 0;
    padding-top: 0.4rem;
    border-radius: 0px 0px 10px 10px;
    h4 {
      margin-top: 0.75rem;
      @include body-bold {
        font-family: $karla;
        color: $white;
      }
    }
    h5 {
      margin-top: 3.5rem;
      @include button {
        font-family: $karla;
        color: $white;
      }
    }
  }
}
@include xl {
  .image {
    display: none;
  }
}
@include md {
  .intro--search--settings {
    flex-direction: column;
    margin: 0 auto 1rem auto;
    .intro--search--settings--filter {
      flex-direction: column;
    }
  }
}
@include breakpoint(465px) {
  .careerpath--text {
    padding-top: 0.5rem;
    @include flex(center, center);
    h2 {
      @include title {
        font-size: 2rem;
      }
    }
  }
}
</style>
