<template>
  <section class="intro">
    <div class="intro--box">
      <div class="intro--search">
        <div class="intro--search--text">
          <h1>Best Tech Bootcamps</h1>
          <p>
            Bootcamps offer intensive training programs — which stack up
            competitively against bachelor's degrees — for everyone who want to
            break into the tech within a short period of time.
          </p>
        </div>
        <div class="intro--search--form">
          <form @submit.prevent="handleSubmit" action="">
            <input v-model="input" type="text" 
            placeholder="Have a bootcamp in mind? Search tech school of your choice..."
             name="" id="" />
            <button class="button--search" type="submit">Search</button>
          </form>
        </div>
        <div class="intro--search--settings">
          <img src="@/assets/svg/filter-3.svg" @click="handleFilter" alt="">
          <!-- <div v-if="filter" class="intro--search--settings--filter">
              <div class="intro--search--settings--filter--input">
                  <input type="checkbox" name="" id="">
                  <label for="">Online Bootcamps</label>
              </div>
              <div class="intro--search--settings--filter--input">
                  <input type="checkbox" name="" id="">
                  <label for="">Offline Bootcamps</label>
              </div>
              <div>
                  <button class="button--search">
                      Filter
                  </button>
              </div>
          </div> -->
        </div>
        <!-- <div class="intro__filter" v-if="filter">
            <div class="intro__filter__item">
                <p>Country</p>
                <div class="dropdown">
                    <span> <p>Select Country</p>
                    <img src="@/assets/svg/arr-down-brown.svg" alt="" width="24" height="24" />
                </span>
                </div>
            </div>
            <div class="intro__filter__item">
                <p>Country</p>
                <div class="dropdown">
                    <span> <p>Select Country</p>
                    <img src="@/assets/svg/arr-down-brown.svg" alt="" width="24" height="24" />
                </span>
                </div>
            </div>
            <div class="intro__filter__item">
                <p>Country</p>
                <div class="dropdown">
                    <span> <p>Select Country</p>
                    <img src="@/assets/svg/arr-down-brown.svg" alt="" width="24" height="24" />
                </span>
                </div>
            </div>
            <button>Reset</button>
        </div> -->
        <Filter v-if="filter" />
      </div>
      <div class="intro--popular">
        <div class="intro--popular--trending">
          <h5>Trending Courses</h5>
          <hr />
        </div>
        <div class="intro--popular--text">
          <h2>Popular Bootcamps</h2>
        </div>
            <div class="intro--popular--trends--cards">
                <Logo
        v-for="(item, index) in totalArray"
        :key="index"
        :title="item.country"
        :rank="index+1"
        :total="item.total_bootstrap"
        :logo="item.country_flag"
        :review="item.reviews_avg_rating"
      />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import Logo from "@/shared/logoipsum.vue";
import Filter from "@/shared/Filter.vue";
import { onMounted, watch, ref } from "vue";
import { useStore, mapActions, mapGetters } from "vuex";
import { useRouter, useRoute } from "vue-router";
const router = useRouter()
const store = useStore();
mapActions(["Popular"]);
const currentPosts = ref([]);
const totalArray = ref();
const review = ref()
onMounted(async () => {
  await store.dispatch("Popular");
  totalArray.value = await store.getters.PopularState;
  currentPosts.value = totalArray.value;
});
const filter = ref(false)
const input = ref('')

const handleFilter = () => {
    filter.value = !filter.value
}
const handleSubmit = () => {
    input.value = ""
}
const ranks = [
  {
    title: "NIGERIA",
    rank: 1,
    logo: require("@/assets/Community/naij.png")
  },
  {
    title: "GHANA",
    rank: 2,
    logo: require("@/assets/Community/ghana.png")
  },
  {
    title: "SOUTH AFRICA",
    rank: 3,
    logo: require("@/assets/Community/southy.png")
  },
];
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.intro--popular--trends--cards {
    display: flex;
    justify-content: center;
    gap: 2rem;
    width: Min(100%, 921px);
    margin: 2rem auto 0
}
.intro--search--settings {
    @include flex(space-between, center);
    margin: 0 auto;
    width: min(90%, 50rem);
    .intro--search--settings--filter {
        width: 100%;
        @include flex(space-between, center);
        gap: 3rem;
        .intro--search--settings--filter--input {
            align-self: center;
            margin: auto 0;
            label {
                margin: 0 0.5rem;
                @include label {
                    color: $black-50;
                    font-family: $karla;
                }
            }
        }
    }
}
.intro {
    background: linear-gradient(180deg, #F0DED5 -18.15%, rgba(240, 222, 213, 0) 100%);
}
.intro--box {
    padding: 3rem 7%;
    text-align: center;
    .intro--search {
        .intro--search--text {
                 margin: 1.5rem auto 1rem auto;
                 width: min(90%, 570px);
            h1 {
                @include title1 {
                    font-family: $karla;
                    color: $black-100;
                }
            }
             p {
                 margin: 1.5rem 0 1rem 0;
                @include caption {
                    font-family: $karla;
                    color: $black-100;
                }
            }
        }
        .intro--search--form {
            form {
                input {
                    outline: none;
                    border: none;
                    width: min(70%, 42rem);
                    height: 57px;
                    background: #FFFFFF;
                    border-radius: 8px 0px 0px 8px;
                    padding: 1rem;
                    @include caption {
                        font-family: $karla;
                        color: $secondary-60;
                        font-size: 18px;
                    }
                }
                .button--search {
                    background: #A44A65;
                    border-radius: 0px 8px 8px 0px;
                    width: min(30%, 8rem);
                    height: 57px;
                    border: none;
                    @include button {
                        font-family: $karla;
                        color: $white;
                    }
                }
            }
        }
    }
}
.intro--popular {
    .intro--popular--trending {
        h5 {
            @include caption {
                        font-family: $karla;
                        color: #FFC326;
                    }
        }
        hr {
            @include flex(center, flex-start);
            margin: 0 auto;
            border: 2px solid #A44A65;
            width: 76px;
            height: 0px;
        }
    }
    .intro--popular--text {
        h2 {
          width: min(90%, 770px);
      margin: 0 auto;
            @include title {
                color: $secondary-80;
                font-family: $karla;
            }
        }
    }
}
@include breakpoint(800px) {
  .intro--popular {
    .intro--popular--trending {
        h5 {
            @include caption {
                        font-family: $karla;
                        color: #FFC326;
                    }
        }
        hr {
            @include flex(center, flex-start);
            border: 2px solid #A44A65;
            width: 76px;
            height: 0px;
        }
    }
    .intro--popular--text {
        h2 {
          width: min(90%, 770px);
      margin: 0 auto;
            @include title {
                font-size: 2.38rem;
            }
        }
    }
}
}
@include md {
    .intro--popular--trends--cards {
    flex-direction: column;
}
.intro--popular--trends--card {
    margin: 2rem auto 0 auto;
}
.intro--search--settings {
    flex-direction: column;
    margin: 0 auto 1rem auto;
    .intro--search--settings--filter {
        flex-direction: column;
    }
}
}
@include breakpoint(670px) {
  .intro--box {
    .intro--search {
        .intro--search--text {
            h1 {
                @include title1 {
                    font-size: 1.75rem;
                }
            }
             p {
                 margin: 1.5rem 0 1rem 0;
                @include caption {
                }
            }
        }
        .intro--search--form {
            form {
                input {
                    @include caption {
                        font-size: 18px;
                    }
                }
                button {
                    background: #A44A65;
                    border-radius: 0px 8px 8px 0px;
                    width: min(30%, 8rem);
                    height: 57px;
                }
            }
        }
    }
}
}
@include xs {
  .intro--box {
    .intro--search {
        .intro--search--text {
            h1 {
                @include title1 {
                    font-size: 1.5rem;
                }
            }
             p {
                 margin: 1.5rem 0 1rem 0;
                @include caption {
                  font-size: 0.9rem;
                }
            }
        }
        .intro--search--form {
            form {
                input {
                    @include caption {
                        font-size: 18px;
                    }
                }
                button {
                    background: #A44A65;
                    border-radius: 0px 8px 8px 0px;
                    width: min(30%, 8rem);
                    height: 57px;
                }
            }
        }
    }
}
.intro--popular {
    .intro--popular--trending {
        h5 {
            @include caption {
                        font-family: $karla;
                        color: #FFC326;
                    }
        }
        hr {
            @include flex(center, flex-start);
            border: 2px solid #A44A65;
            width: 76px;
            height: 0px;
        }
    }
    .intro--popular--text {
        h2 {
          width: min(90%, 770px);
      margin: 0 auto;
            @include title {
                font-size: 1.35rem;
            }
        }
    }
}
}
</style>