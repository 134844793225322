<template>
  <section class="payment" @click.self="closePay">
    <div class="payment--box">
      <div class="close">
        <svg class="click"  @click.self="closePay" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z" fill="#9F9F9F"/>
</svg>
      </div>
      <div class="payment--intro">
        <h3>Select Payment Method</h3>
      </div>
      <div class="payment--person">
        <div class="payment--person--text">
          <h3>Olajide Raymond</h3>
          <p>olajideray2019@gmail.com</p>
        </div>
        <div class="payment--person--image">
          <img src="@/assets/Payment/woman.png" alt="" />
        </div>
      </div>
      <div class="payment--methods">
        <div class="payment--methods--method">
          <div class="payment--methods--method--text">
            <input type="radio" name="" id="" />
            <div class="payment--methods--method--text--content">
              <h2>Credit or Debit Card</h2>
              <p>
                Use a credit or debit card to pay with automatic payments
                enabled
              </p>
            </div>
          </div>
          <div class="payment--methods--method--image">
            <img src="@/assets/Payment/visa.png" alt="" />
          </div>
        </div>
        <div class="payment--methods--method">
          <div class="payment--methods--method--text">
            <input type="radio" name="" id="" />
            <div class="payment--methods--method--text--content">
              <h2>Paystack</h2>
              <p>Make using paystack integrated platform</p>
            </div>
          </div>
          <div class="payment--methods--method--image">
            <img src="@/assets/Payment/paystack.png" alt="" />
          </div>
        </div>
        <div class="payment--methods--method">
          <div class="payment--methods--method--text">
            <input type="radio" name="" id="" />
            <div class="payment--methods--method--text--content">
              <h2>Flutterwave</h2>
              <p>Make using paystack integrated platform</p>
            </div>
          </div>
          <div class="payment--methods--method--image">
            <img src="@/assets/Payment/flutterwave.png" alt="" />
          </div>
        </div>
      </div>
      <div class="payment--form">
        <form action="" @submit.prevent="handleSubmit">
          <label for="">Card Number</label>
          <div class="payment--form--cardnum">
            <div class="payment--form--cardnum--image">
              <svg
                width="35"
                height="24"
                viewBox="0 0 35 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_394_4985)">
                  <path
                    d="M0 3.375C0 1.78718 1.28718 0.5 2.875 0.5H31.625C33.2128 0.5 34.5 1.78718 34.5 3.375V20.625C34.5 22.2128 33.2128 23.5 31.625 23.5H2.875C1.28718 23.5 0 22.2128 0 20.625V3.375Z"
                    fill="#FDEACE"
                  />
                  <path
                    d="M20.8497 5.19127L13.4257 5.2168L13.6506 18.8082L21.0746 18.7827L20.8497 5.19127Z"
                    fill="#FF5F00"
                  />
                  <path
                    d="M13.9143 12.0415C13.8683 9.27708 15.1067 6.82081 17.0464 5.22756C15.5823 4.07867 13.7468 3.38781 11.7571 3.39462C7.04335 3.41077 3.29405 7.29397 3.37354 12.0776C3.45304 16.8612 7.33097 20.7183 12.0447 20.7021C14.0344 20.6953 15.8467 19.9919 17.2725 18.8332C15.2805 17.2774 13.9602 14.8059 13.9143 12.0415Z"
                    fill="#EB001B"
                  />
                  <path
                    d="M31.1267 11.9229C31.2062 16.7065 27.4569 20.5897 22.7432 20.6059C20.7535 20.6127 18.918 19.9218 17.4539 18.773C19.4172 17.1796 20.6319 14.7234 20.586 11.959C20.54 9.19465 19.2202 6.74713 17.2278 5.16735C18.6535 4.00857 20.4658 3.30521 22.4556 3.2984C27.1693 3.28225 31.0476 7.16338 31.1267 11.9229Z"
                    fill="#F79E1B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_394_4985">
                    <rect
                      width="34.5"
                      height="23"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <input
              placeholder="Card Number"
              type="number"
              name=""
              id=""
              required
            />
            <input type="month" placeholder="mm/yy" name="" id="" required />
          </div>
          <div class="payment--form--text-input">
            <label for="">Name of Card Holder</label>
            <input placeholder="Cardholders name" type="text" name="" id="" />
          </div>
          <div class="payment--form--text-input">
            <label for="">Payment Address</label>
            <input
              placeholder="Payment Address"
              type="address"
              name=""
              id=""
              required
            />
          </div>
          <div class="payment--form--text-input">
            <label for="">Payment Amount</label>
            <input
              placeholder="Cardholders name"
              type="number"
              name=""
              id=""
              value="60000"
              required
            />
          </div>
          <div class="btn">
            <button type="submit" class="button--payment">
              Proceed to Payment
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
    },
  },
  methods: {
    closePay() {
      this.$emit("togglePay", !this.active);
    },
    handleSubmit() {
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.payment {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0rem;
  z-index: 10;
  @include flex(center, center);
  background-color: #dddffd90;
}
.payment--box {
  width: 65%;
  height: 95%;
  background: $white;
  padding: 2rem 5%;
  text-align: center;
  position: absolute;
  overflow-y: scroll;
}
.close {
  @include flex(flex-end, center);
  .click {
    transform: rotate(45deg);
     cursor: pointer;
  }
}
.payment--intro {
  h3 {
    @include sub-heading-bold {
      color: $secondary-80;
      font-family: $karla;
    }
  }
}
.payment--person {
  @include flex(flex-end, flex-start);
  gap: 0.5rem;
  margin-top: 1.5rem;
  .payment--person--text {
    background: #ead0d8;
    border-radius: 10px;
    height: 72px;
    align-self: center;
    h3 {
      @include body {
        color: $black-100;
        font-family: $karla;
      }
    }
    p {
      @include caption {
        color: $secondary-80;
        font-family: $karla;
      }
    }
  }
}
.payment--methods {
  margin-top: 1.5rem;
  .payment--methods--method {
    @include flex(space-between, flex-start);
    .payment--methods--method--text {
      display: flex;
      gap: 0.6rem;
      text-align: left;
      input {
        align-self: center;
      }
    }
    .payment--methods--method--text--content {
      h2 {
        @include sub-heading-bold {
          color: $secondary-80;
          font-family: $karla;
        }
      }
      p {
        @include caption {
          color: $gray-100;
          font-family: $karla;
        }
      }
    }
    .payment--methods--method--image {
      @include flex(flex-start, flex-start);
      align-self: center;
    }
  }
}
.payment--form {
  text-align: left;
  label {
    display: block;
    margin: 0.5rem 0;
    @include body {
      color: $black-100;
      font-family: $karla;
    }
  }
  .payment--form--cardnum {
    display: flex;
    position: relative;
    .payment--form--cardnum--image {
      align-self: center;
      position: absolute;
      left: 0.4rem;
    }
    input[type="number"] {
      background: #ffffff;
      border: 2px solid #8f3d55;
      box-sizing: border-box;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
      border-radius: 6px 0px 0px 6px;
      width: min(70%, 454px);
      height: 72px;
      padding: 24px 70px 24px 40px;
      outline: none;
      @include body {
        font-size: 17px;
        color: $black-100;
        font-family: $karla;
      }
    }
    input[type="month"] {
      background: #ffffff;
      border: 2px solid #8f3d55;
      box-sizing: border-box;
      border-radius: 0px 6px 6px 0px;
      width: min(30%,181px);
      height: 72px;
      padding: 24px 40px 24px 0;
      outline: none;
      @include body {
        font-size: 17px;
        color: $black-100;
        font-family: $karla;
      }
    }
  }
  .payment--form--text-input {
    input[type="text"],
    input[type="address"],
    input[type="number"] {
      background: #ffffff;
      border: 2px solid #8f3d55;
      box-sizing: border-box;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      width: min(100%,626px);
      height: 72px;
      padding: 24px 70px 24px 40px;
      @include body {
        font-size: 17px;
        color: $black-100;
        font-family: $karla;
      }
      &::placeholder {
        @include body {
          font-size: 17px;
          color: $gray-100;
          font-family: $karla;
        }
      }
    }
  }
  .btn {
    margin: 2rem 0;
    @include flex(center, center);
  }
}
@include xl {
  .payment--box {
  width: 95%;
  height: 95%;
}
}
@include lg {
  .payment--person {
  @include flex(flex-end, flex-start);
  gap: 0.5rem;
  margin-top: 1rem;
  .payment--person--text {
    align-self: center;
    h3 {
      @include body {
        font-size: 1rem;
      }
    }
    p {
      @include caption {
         font-size: 0.9rem;
      }
    }
  }
}
.payment--intro {
  h3 {
    @include sub-heading-bold {
      font-size: 1.3rem;
    }
  }
}
}
@include sm {
  .payment--methods {
  margin-top: 1.5rem;
  .payment--methods--method {
    @include flex(space-between, flex-start);
    .payment--methods--method--text {
      display: flex;
      gap: 0.6rem;
      text-align: left;
      input {
        align-self: center;
      }
    }
    .payment--methods--method--text--content {
      width: 100%;
      h2 {
        @include sub-heading-bold {
          font-size: 1rem;
        }
      }
      p {
        @include caption {
          font-size: 0.9rem;
        }
      }
    }
    .payment--methods--method--image {
      @include flex(flex-end, flex-start);
      align-self: center;
      margin: 0 auto;
      img {
        width: 70%;
      }
    }
  }
}
}
@include breakpoint(525px) {
  .payment--methods {
  margin-top: 1.5rem;
  .payment--methods--method {
    @include flex(space-between, flex-start);
    .payment--methods--method--text {
      display: flex;
      gap: 0.6rem;
      text-align: left;
      input {
        align-self: center;
      }
    }
    .payment--methods--method--text--content {
      h2 {
        @include sub-heading-bold {
          font-size: 1rem;
        }
      }
      p {
        @include caption {
          font-size: 0.65rem;
        }
      }
    }
  }
}
}
</style>