<template>
  <div class="placement">
  <section v-if="!LoggedIn">
    <!-- <Modal /> -->
    <Nav v-if="showNav" />
    <Signup v-if="$store.state.AccessState === 1"/>
    <Login v-if="$store.state.AccessState === 2"/>
    <Reset v-if="$store.state.AccessState === 3"/>
    <New v-if="$store.state.AccessState === 4"/>
    <router-view />
    <Footer v-show="showNav" v-if="showNav" />
  </section>
  <section v-if="LoggedIn" class="logged">
    <div class="logged__review" v-if="$store.state.review === true">
      <p>Review Sent</p>
    </div>
    <Success />
    <Failed />
    <TestSelector v-if="$store.getters.StateOwner.career_field === null" />
    <div class="header">
      <Header :active="active" :bootcamps="bootcamps" @closeActive="toggleClose($event)" @handleBootcamps="toggleBootcamps($event)" />
    </div>
    <div class="split">
      <div class="side" :class="{side__active: active}">
        <Sidebar :active="active" @toggleClose="closeMenu($event)" />
      </div>
      <div class="screen">
        <router-view v-if="bootcamps === null" />
        <Card v-else v-for="bootcamp in $store.getters.SearchState" 
        :key="bootcamp.id"
        :company="bootcamp.name"
        :logo="bootcamp.logo"
        :courses="bootcamp.tag"
        :id="bootcamp.id"
        :review="bootcamp.reviews.length"
        @click="search"/>
        <p v-if="searchNum === 0">No Result</p>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "./components/Footer.vue";
import Signup from "@/Access/Modals/SignIn.vue";
import Login from "@/Access/Modals/Login.vue";
import Header from "./shared/header.vue";
import Sidebar from "./Layout/Sidebar.vue";
import Success from "@/Modals/success.vue";
import Failed from "@/Modals/Failed.vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import {useStore, mapActions, mapGetters} from "vuex";
import Reset from "@/Access/Modals/Reset.vue"
import New from "@/Access/Modals/ChangePassword.vue"
import TestSelector from "./shared/testSelector.vue";
import Card from "@/shared/Featured.vue";
export default {
  components: {
    Nav,
    Footer,
    Signup,
    Login,
    Header,
    Sidebar,
    Reset,
    New,
    TestSelector,
    Card,
    Success,
    Failed
},
  data() {
    return {
      signup: false,
      login: false,
      active: true,
      LoggedIn: false,
      selectedCourse: null,
    };
  },
  mounted() {
    if (window.sessionStorage.getItem("user") === "authenticated") {
      this.LoggedIn = !this.LoggedIn;
    } else {
    }
    if(window.sessionStorage.getItem("loaded") === null){
      this.$store.state.CourseSelect = true
  }
  else{
    this.$store.state.CourseSelect = false
  }
  },
  methods: {
    openSignUp(opened) {
      this.signup = opened;
    },
    openSignUp(opened) {
      this.login = opened;
    },
    closeSignUp(closed) {
      this.login = false;
      this.signup = closed;
    },
    openLogin(loginOpened) {
      this.signup = false;
      this.login = loginOpened;
    },
    closeLogin(closedlogin) {
      this.login = closedlogin;
    },
    toggleClose(toggle) {
      this.active = toggle;
    },
    closeMenu(close) {
      this.active = close
    }
  },
  setup() {
    const store = useStore();
    const bootcamps = ref(null)
    const showNav = ref(true);
    const route = useRoute();
    const router = useRouter();
    const searchNum = ref()
    const data = ref({
  name: "",
  career_field: "",
  telephone: "",
  country: ""
})
    mapActions(["User"])
    onMounted(async () => {
      await router.isReady();
      await store.dispatch("User")
      data.value.name = first.value
  data.value.telephone = store.getters.StateOwner.telephone
  data.value.career_field = store.getters.StateOwner.career_field
  data.value.country = store.getters.StateOwner.country
  searchNum.value = await store.getters.SearchState.length
    });
    const toggleBootcamps = async (boot) => {
      bootcamps.value = boot
      searchNum.value = await store.getters.SearchState.length
    }
    const search = () => {
      //console.log(searchNum.value)
      bootcamps.value = null
    }
    return {showNav, bootcamps, toggleBootcamps, data, search, searchNum}
  },
};
// if (route.path === "/login") {
//         showNav.value = false;
//       } else if (route.path === "/reset") {
//         showNav.value = false;
//       } else if (route.path === "/new-password") {
//         showNav.value = false;
//       } else {
//         showNav.value = true;
//       }
</script>

<style lang="scss" scoped>
.placement, .logged {
  position: relative;
}
.header {
  position: sticky;
  top: 0;
  z-index: 5;
  background: $white;
}
.split {
  display: flex;
  background-color: $bg-2;
  position: relative;
  .side {
    overflow: hidden;
    max-width: 275px;
    z-index: 2;
  }
  .screen {
    padding: 2rem;
    padding-bottom: 0;
    background-color: #F9F2EE;
    height: calc(100vh - 2rem);
    overflow: auto;
    position: relative;
    scroll-behavior: none;
    &::-webkit-scrollbar {
      display: none;
      appearance: none;
    }
    @include respondMax('tablet2x') {
      height: 100vh;
      padding: 0;
    }
  }
  & > div {
    width: 100%;
    position: relative;
  }
}
.logged {
  position: relative;
  &__review {
    position: absolute;
    z-index: 5;
    top: 10%;
    left: 50%;
    padding: 10px 25px;
    width: Min(300px, 90%);
    background-color: $secondary-40;
    text-align: center;
    @extend %flex-ac-jc;
    p {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: Max(2rem, 16px);
      line-height: Max(1.25rem, 20px);
      letter-spacing: -0.0016em;
      color: $black;
    }
  }
}
@media screen and (max-width: 992px) {
  // .split {
  //   .side {
  //     overflow: auto;
  //     max-width: 100%;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100vh;
  //     transform: translateX(-100);
  //     position: fixed;
  //     z-index: 5;
  //     transition: all 0.5s ease-in-out;
  //     background-color: red;
  //     &__active {
  //       transform: translateX(0);
  //     }
  //   }
  // }
  .split {
    position: relative;
    .side {
      overflow: auto;
      max-width: 100vw;
      top: 2rem;
      right: 0;
      width: 100%;
      height: 100vh;
      transform: translateX(-100);
      position: fixed;
      z-index: 3;
      transition: all 0.5s ease-in-out;
      background-color: $white;
      padding-top: 2rem;
      &__active {
        transform: translateX(-100%);
      }
    }
  }
}
</style>
