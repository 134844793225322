<template>
  <section class="roadmap">
    <div class="roadmap__box">
      <div class="roadmap__text">
        <h3>
          Roadmap to Becoming a Front-End Engineer
        </h3>
      </div>
      <Roadmap :started="started" :going="going" />
    </div>
  </section>
</template>
<script setup>
import Roadmap from "@/shared/roadmap.vue"
import {ref} from "vue";
const started = ref([
  {
    id: 1,
    title: "Learn HTML and CSS",
    desc: "Start with the basics of web development by learning HTML and CSS. These are the building blocks of web pages and user interfaces.",
    bg: "#DAADBB",
    btn: "Get Started"
  },
  {
    id: 2,
    title: "Choose a Front-End Programming Language",
    desc: "Specialize in a programming tool like JavaScript or TypeScript. JavaScript is the most widely used front-end language.",
    bg: "#dee2e6"
  },
  {
    id: 3,
    title: "Learn a Front-End Framework",
    desc: "Framework such as React, Vue.js, or Angular. These frameworks provide a set of tools and conventions for building complex user interfaces and web applications.",
    bg: "#DAADBB"
  },
  {
    id: 4,
    title: "Study Responsive Web Design",
    desc: "Learn about responsive web design, which allows web pages to adapt to different screen sizes and devices.",
    bg: "#dee2e6"
  },
  {
    id: 5,
    title: "Practice with Projects",
    desc: "Practice your front-end skills by working on small projects prepared for you in our dashboard . This will help you apply what you have learned and reinforce your knowledge.",
    bg: "#DAADBB"
  }
])
const going = ref([
  {
    id: 1,
    title: "Learn About APIs",
    desc: "Learn how to consume APIs (Application Programming Interfaces) to retrieve data from external sources and use it to build dynamic user interfaces.",
    bg: "#dee2e6",
    btn: "Get Going"
  },
  {
    id: 2,
    title: "Study Performance Optimization",
    desc: "Learn about performance optimization techniques such as minification, bundling, and lazy loading to improve the speed and responsiveness of your web applications.",
    bg: "#DAADBB"
  },
  {
    id: 3,
    title: "Learn Testing and Debugging",
    desc: "Learn about testing and debugging techniques to ensure your code works as expected and troubleshoot any issues that may arise.",
    bg: "#dee2e6"
  },
  {
    id: 4,
    title: "Participate in Open Source",
    desc: "Gain experience and learn from other developers and connect with mentors on your dashboard.",
    bg: "#DAADBB"
  },
  {
    id: 5,
    title: "Keep Learning",
    desc: "Stay up-to-date with the latest trends and best practices.",
    bg: "#dee2e6"
  },
  {
    id: 6,
    title: "Build a Portfolio",
    desc: "Share your projects on GitHub or other online platforms to get feedback and increase your visibility.",
    bg: "#DAADBB"
  }
])
</script>

<style lang="scss" scss scoped>
@import "@/styles/_typography.scss";
.roadmap {
  background: #F9F0EC;
  padding: 4rem 7%;
  &__box {
    width: 100%;
  }
  &__text {
    max-width: 100vw;
    width: min(90%, 804px);
    margin: 0 auto;
    text-align: center;
  h3 {
    @include heading3 {
      color: $secondary-80;
      font-family: $karla;
    }
    @media screen and (max-width: 900px) {
      margin: 0 auto;
      font-size: 1.5rem;
    }
  }
  }
}
</style>