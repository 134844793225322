<template>
  <section class="faq">
    <div class="faq--box">
      <div class="faq--questions">
        <div class="faq--questions--text">
          <h3>Course outline include but not limited to :</h3>
        </div>
        <div class="faq--questions--question">
          <div class="faq--questions--question--box">
            <div v-for="faq in faqs" :key="faq.id">
              <div class="faq--questions--question--content">
                <div class="faq--questions--question--content--intro">
                  <h2>
                    {{ faq.number }}
                  </h2>
                  <h5>
                    {{ faq.title }}
                  </h5>
                </div>
                <div class="faq--questions--question--content--text">
                  <svg
                    class="click"
                    :class="{ active: faq.active }"
                    @click="handleToggle(faq.id, faq.active)"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_771_2474)">
                      <path
                        d="M19.8412 21.7782L28.0257 13.5938L30.3636 15.9317L19.8412 26.4541L9.31885 15.9317L11.6568 13.5938L19.8412 21.7782Z"
                        fill="#A44A65"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_771_2474">
                        <rect width="39.6821" height="39.6821" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <!-- <svg v-else @click="handleToggle(faq.id, faq.active)" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_687_2472)">
<path d="M19.8407 17.9022L11.6567 26.0862L9.31885 23.7484L19.8407 13.2266L30.3625 23.7484L28.0247 26.0862L19.8407 17.9022Z" fill="#8F3D55"/>
</g>
<defs>
<clipPath id="clip0_687_2472">
<rect width="39.68" height="39.68" fill="white"/>
</clipPath>
</defs>
</svg> -->
                </div>
              </div>
              <div class="show--content">
                <p v-if="faq.active">
                  {{ faq.content }}
                </p>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="popular">
            <h2>Top Rated Bootcamps</h2>
          <div class="popular__cards">
            <Logo
        v-for="(item, index) in ranks"
        :key="index"
        :title="item.country"
        :rank="index+1"
        :total="item.total_bootstrap"
        :logo="item.country_flag"
        :review="item.reviews_avg_rating"
      />
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import Logo from '@/shared/logoipsum.vue'
import { onMounted, watch, ref } from "vue";
import { useStore, mapActions, mapGetters } from "vuex";
export default {
  components: {
    Logo
  },
  methods: {
    handleToggle(number, active) {
      this.faqs.map((newFaq, id) => {
        if (newFaq.id === number) {
          newFaq.active = !newFaq.active;
        }
      });
    },
  },
  data() {
    return {
      faqs: [
        {
          id: 0,
          number: "01",
          title: "Introduction To UI/UX Design",
          content:
            "Learn the fundamentals of accessibility in design and become familiar with the role brand platform plays in UI design and visual communication",
          active: true,
        },
        {
          id: 1,
          number: "02",
          title: "User Research & Requirement",
          content:
            "Learn the fundamentals of accessibility in design and become familiar with the role brand platform plays in UI design and visual communication",
          active: false,
        },
        {
          id: 2,
          number: "03",
          title: "Storyboards",
          content:
            "Learn the fundamentals of accessibility in design and become familiar with the role brand platform plays in UI design and visual communication",
          active: false,
        },
        {
          id: 3,
          number: "04",
          title: "Information Architeture & Designing",
          content:
            "Learn the fundamentals of accessibility in design and become familiar with the role brand platform plays in UI design and visual communication",
          active: false,
        },
        {
          id: 4,
          number: "05",
          title: "Flowcharts",
          content:
            "Learn the fundamentals of accessibility in design and become familiar with the role brand platform plays in UI design and visual communication",
          active: false,
        },
        {
          id: 5,
          number: "06",
          title: "Wireframes",
          content:
            "Learn the fundamentals of accessibility in design and become familiar with the role brand platform plays in UI design and visual communication",
          active: false,
        },
        {
          id: 6,
          number: "07",
          title: "Prototypes",
          content:
            "Learn the fundamentals of accessibility in design and become familiar with the role brand platform plays in UI design and visual communication",
          active: false,
        },
        {
          id: 7,
          number: "08",
          title: "Usability Testing",
          content:
            "Learn the fundamentals of accessibility in design and become familiar with the role brand platform plays in UI design and visual communication",
          active: false,
        },
        {
          id: 8,
          number: "09",
          title: "Career Opportunities for UI/UX Designer",
          content:
            "Learn the fundamentals of accessibility in design and become familiar with the role brand platform plays in UI design and visual communication",
          active: false,
        },
        {
          id: 9,
          number: "10",
          title: "Career Opportunities for UI/UX Designer",
          content:
            "Learn the fundamentals of accessibility in design and become familiar with the role brand platform plays in UI design and visual communication",
          active: false,
        },
        {
          id: 10,
          number: "11",
          title: "Career Opportunities for UI/UX Designer",
          content:
            "Learn the fundamentals of accessibility in design and become familiar with the role brand platform plays in UI design and visual communication",
          active: false,
        },
      ],
      ranks: [
  {
    title: "NIGERIA",
    rank: 1,
    logo: require("@/assets/Community/naij.png")
  },
  {
    title: "GHANA",
    rank: 2,
    logo: require("@/assets/Community/ghana.png")
  },
  {
    title: "SOUTH AFRICA",
    rank: 3,
    logo: require("@/assets/Community/southy.png")
  },
]
    };
  },
  setup() {
const store = useStore();
const ranks = ref()
onMounted(async () => {
  mapGetters(["BootcampState", "Country"])
  //await store.dispatch("Country")
  await store.dispatch("Bootcamps");
  await store.dispatch("Popular")

  ranks.value = await store.getters.PopularState
})
return{ranks}
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.popular {
  margin-top: 3rem;
  h2 {
    font-family: Karla, "sans-serif";
    font-style: normal;
    font-weight: 700;
    font-size: Max(1.9rem, 30px);
    line-height: 120%;
    letter-spacing: -0.045em;
    color: $white;
    text-align: center;
  }
  &__cards {
    margin-top: 3rem;
    @extend %flex-ac-jb;
    flex-wrap: wrap;
    @include respondMax("tablet") {
      justify-content: center;
      gap: 2.5rem;
    }
  }
}
.faq {
  background: linear-gradient(218deg, #4e1b3c, #231f20);
  .faq--box {
    padding: 3rem 7%;
    text-align: center;
  }
}
.show--content {
  p {
    text-align: left;
    @include caption {
      font-family: $karla;
      color: $black;
    }
  }
}
/* to rotate svg */
.click {
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.active {
  transform: rotate(180deg);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.faq--questions--text {
  h3 {
    @include heading3 {
      color: $white;
      font-family: $karla;
    }
  }
}
.faq--questions--question {
  @include flex(center, flex-start);
}
.faq--questions--question--box {
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 2rem 2rem;
}
.faq--questions--question--content {
  @include flex(space-between, flex-start);
  width: 100%;
  hr {
    border: 1px solid #8f3d55;
    width: 100%;
  }
  .faq--questions--question--content--intro {
    display: flex;
    gap: 1.5rem;
    width: 60%;
    h2 {
      @include heading3 {
        color: $secondary-80;
        font-family: $karla;
      }
    }
    h5 {
      align-self: center;
      text-align: left;
      margin-left: 0.6rem;
      @include body-bold {
        color: $secondary-80;
        font-family: $karla;
      }
    }
  }
  .faq--questions--question--content--text {
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
    p {
      align-self: center;
      width: 88%;
      text-align: left;
      transition: opacity 0.6s ease-in-out;
      @include caption {
        color: $secondary-80;
        font-family: $karla;
      }
    }
    svg {
      align-self: center;
    }
  }
}
/* bootcamp styling */
.intro--popular--trends--cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.intro--popular--trends--card {
  margin-top: 2rem;
  .intro--popular--trends--card--logo--box {
    background: #ead0d8;
    border-radius: 8px 8px 0px 0px;
    width: 226px;
    height: 31px;
    position: relative;
    .intro--popular--trends--card--logo {
      width: 147px;
      height: 40px;
      background: #8f3d55;
      border-radius: 8px;
      margin: auto;
      position: relative;
      z-index: 2;
      bottom: 70%;
      img {
        margin: 0.5rem auto;
      }
    }
  }
  width: 226px;
  height: 144px;
  background: #ffffff;
  box-shadow: 2px 2px 5px rgba(222, 198, 205, 0.3);
  border-radius: 20px;
  h3 {
    @include button {
      color: $secondary-80;
      font-family: $karla;
    }
  }
  .intro--popular--trends--card--reviews {
    @include flex(center, flex-start);
    gap: 0.3rem;
    p {
      margin: auto 0;
      @include label {
        color: $black;
        font-family: $karla;
      }
    }
  }
  .intro--popular--trends--card--stars--box {
    @include flex(center, center);
    flex-direction: column;
    margin-top: 1rem;
    .intro--popular--trends--card--stars {
      @include flex(center, center);
      margin: auto 0;
      gap: 0.3rem;
      p {
        margin: auto 0;
        @include label {
          color: $black;
          font-family: $karla;
        }
      }
    }
    h4 {
      @include heading3 {
        display: none;
        color: rgba(203, 203, 203, 0.4);
        font-family: $karla;
      }
    }
  }
}
/* company styling */
.hiring {
  padding: 2rem 7% 3rem 7%;
  text-align: center;
  width: 100%;
  .intro {
    &__popular {
      @extend %flex-ac-jb;
      gap: 2.5rem;
      flex-wrap: wrap;
    }
  }
}
.hiring--text {
  h2 {
    margin-bottom: 3rem;
    @include sub-heading-bold {
      font-family: $karla;
      color: $white;
    }
  }
}
.hiring--company--nig {
  @include flex(center, flex-start);
  gap: 5rem;
  margin-bottom: 3rem;
}
@include xl {
  .hiring--company--nig {
    flex-wrap: wrap;
    margin-bottom: 3rem;
    img {
      margin: 0.6rem;
    }
  }
  .faq--questions--question--box {
    height: auto;
    width: 100%;
  }
  .faq--questions--question--content {
    .faq--questions--question--content--intro {
      display: flex;
      gap: 0.5rem;
      width: 100%;
      h2 {
        @include heading3 {
          font-size: 2rem;
        }
      }
      h5 {
        align-self: center;
        text-align: left;
        @include body {
          font-size: 1rem;
        }
      }
    }
    .faq--questions--question--content--text {
      display: flex;
      justify-content: flex-end;
      gap: 1.5rem;
      p {
        width: 60%;
        text-align: left;
        @include caption {
          font-size: 0.9rem;
        }
      }
      svg {
        align-self: center;
      }
    }
  }
}
@include md {
  .intro--popular--trends--cards {
    flex-direction: column;
  }
  .intro--popular--trends--card {
    margin: 2rem auto 0 auto;
  }
}
@include breakpoint(670px) {
  .faq--questions--text {
    h3 {
      @include heading3 {
        font-size: 1.5rem;
      }
    }
  }
  .faq--questions--question--content--text {
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
    p {
      display: none;
      width: 80%;
      text-align: left;
      @include caption {
        font-size: 0.7rem;
      }
    }
    svg {
      align-self: center;
    }
  }
  .faq--questions--question--content--intro {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    h2 {
      @include heading3 {
        font-size: 1rem;
      }
    }
    h5 {
      align-self: center;
      text-align: left;
      @include body {
        font-size: 0.9rem;
      }
    }
  }
}
</style>