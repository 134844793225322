<template>
  <section class="details">
      <div class="details--box">
       
      </div>
  </section>
</template>
 
<script>
export default {

}
</script>

<style>

</style>