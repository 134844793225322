import axios from "axios";

const state = {
  video: null,
  player: null,
  list: null
};

const getters = {
  ListState: (state) => state.video,
  PlayState: (state) => state.player,
  CareerState: (state) => state.list
};

const actions = {
  async Playlist({ commit }, Id) {
    let headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": true,
      "Content-Type": "application/json",
    };
    let res = await axios({
      url: `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&maxResults=25&playlistId=${Id}&key=AIzaSyDfPUuadal1VV1I3TqcfDAiYhR8D7t8yzE`,
      baseURL: "",
    });

    let videos = res.data.items;
    console.log(videos)
    await commit("setVideo", videos);
  },
  async careerPlaylist({commit}, Field) {
    let headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": true,
      "Content-Type": "application/json",
      Authorization: `Bearer` + " " + window.localStorage.getItem("token")
    };
    let res = await axios.post("youtube/career", Field, {headers: headers})
    let careerList = res.data.data;
    await commit("setList", careerList)
  },
  async Video({ commit }, Id) {
    let headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": true,
      "Content-Type": "application/json",
    };
    let data = Id;
    let res = await axios({
      url: `https://www.googleapis.com/youtube/v3/videos?part=contentDetails,id,snippet&key=AIzaSyDfPUuadal1VV1I3TqcfDAiYhR8D7t8yzE&id=${data}`,
      baseURL: "",
    });
    let item = res.data.items;
    await commit("setPlayer", item);
  },
};

const mutations = {
  setVideo(state, videos) {
    state.video = videos;
  },
  setPlayer(state, item) {
    state.player = item;
  },
  setList(state, careerList) {
    state.list = careerList
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
