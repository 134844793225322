<template>
  <section class="intro">
    <div class="intro--box">
      <div class="intro--text">
        <h1>BACK-END DEVELOPMENT</h1>
        <p>
          Back-end development refers to the practice of designing and building
          the server-side of a website or web application. It involves creating
          and managing the server, database, and application logic that power
          the website or application, and enables it to communicate with the
          front-end user interface.
        </p>
          <button @click="handleMove" class="btn--course">View Curriculum</button>
          <div class="intro--text--figures">
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.mentors}}</h1>
            <p>
              <router-link to="/mentors"> MENTORS </router-link>
            </p>
          </div>
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.bootcamps}}</h1>
            <p>
              <router-link to="/bootcamp"> BOOTCAMPS </router-link>
            </p>
          </div>
          <hr />
          <div>
            <h1>{{$store.getters.StateMetric.projects}}</h1>
            <p>PROJECT</p>
          </div>
        </div>
      </div>
      <div class="intro--image">
        <img
          v-if="active"
          src="@/assets/Courses/Backend/student.png"
          alt=""
          height="560"
          width="560"
        />
        <img
          v-else
          src="@/assets/Courses/Backend/student.svg"
          alt=""
          height="560"
          width="560"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore, mapActions, mapGetters } from "vuex";
import { useRouter } from "vue-router"
const router = useRouter
const store = useStore();
const active = ref(true)
const metric = ref()
mapActions(["Metric"])
onMounted(async () => {
  await store.dispatch("Metric")
  metric.value = store.getters.StateMetric
  setInterval(() => {
      active.value = active.value;
    }, 6000);
})
const handleMove = () => {
  if(window.sessionStorage.getItem("user") != null) {
        router.push("/curriculum")
      } else {
        store.state.AccessState = 2
      }
}
</script>

<style lang="scss" scoped>
@import "@/styles/Courses/intro.scss";
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
img {
  transition: opacity 1s ease-in-out;
  animation: fade ease-in-out 2.5s;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* @include xl {
  .intro {
    height: 775px;
  }
} */
</style>
