<template>
  <Community />
</template>

<script>
import Community from '@/components/Community/Community.vue'
export default {
    components: {
        Community
    }
}
</script>

<style>

</style>