import axios from 'axios'

const state = {
    bootcamps: null,
    bootname: null,
    details: {},
    courses: null,
    reviews: null,
    filteredBootcamps: null,
    search: null,
    FirstCompare: null,
    SecondCompare: null,
    Popular: null,
    country: null
};

const getters = {
    BootcampState: state => state.bootcamps,
    DetailsState: state => state.details,
    CoursesState: state => state.courses,
    ReviewsState: state => state.reviews,
    FilteredState: state => state.filteredBootcamps,
    SearchState: state => state.search,
    FirstCompareState: state => state.FirstCompare,
    SecondCompareState: state => state.SecondCompare,
    PopularState: state => state.Popular,
    CountryState: state => state.country
};

const actions = {
    async Bootcamps({commit}, Id) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`bootcamps?page=${Id}`, {headers: headers})
        let bootcamp = res.data.data.bootcamps
        await commit('setBootcamps', bootcamp)
    },
    async Popular({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get("bootcamps/popular", {headers: headers})
        let pop = res.data.data.bootcamps
        await commit('setPopular', pop)
    },
    // bootcamps details
    async BootcampDetails({commit}, Id) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`bootcamps/${Id}`, {headers: headers})
        let details = res.data.data.bootcamps
        await commit('setDetails', details)

    },
    async Reviews({commit}, Form) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.post("reviews/create", Form, {headers: headers})
         
    },
    async GetReviews({commit}, Form) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get("reviews", {headers: headers})
        let data = res.data.data
        await commit("setReviews", data)
    },
    async Search({commit}, Name) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`bootcamps/search/${Name}`, {headers: headers})
         
        let data = res.data.data.bootcamps.data
        await commit("setSearch", data)
    },
    async Course({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get("courses", {headers: headers})
         
        let info = res.data.data
        await commit("setCourses", info)
    },
    async Filter({commit}, Data) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.post("bootcamps/filter", Data, {headers: headers})
        let filtered = res.data.data.bootcamps
        await commit('filterBootcamps', filtered)
    },
    async Compare({commit}, Link) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`bootcamps/compare/${Link}`, {headers: headers})
         
        let first = res.data.data.v1
        let second = res.data.data.v2
        await commit("setFirstCompare", first)
        await commit("setSecondCompare", second)
    },
    async Country({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get("https://api.countrystatecity.in/v1/countries", {headers: headers})
        let country = res.data.data
        await commit("setCountry", country)
    }
};

const mutations = {
    setBootcamps(state, bootcamp) {
        state.bootcamps = bootcamp
    },
    setPopular(state, pop) {
        state.Popular = pop
    },
    setDetails(state, details) {
        state.details = details
    },
    setCourses(state, info) {
        state.courses = info
    },
    setReviews(state, data) {
        state.reviews = data
    },
    filterBootcamps(state, filtered) {
        state.filteredBootcamps = filtered
    },
    setSearch(state, data) {
        state.search = data
    },
    setFirstCompare(state, data) {
        state.FirstCompare = data
    },
    setSecondCompare(state, data) {
        state.SecondCompare = data
    },
    setCountry(state, data) {
        state.country = data
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}