<template>
<section class="intro">
    <div class="intro--box">
        <div class="intro--image">
            <img src="@/assets/bro.png" alt="">
        </div>
        <div class="intro--text">
            <h2>
                PERSONALITY: USER ADVOCATE 
            </h2>
            <p>
               Your personality test shows that you’re a User Advocate. You possess some of the following qualities which further explain your
                test result: Empathy, visualization, anticipation of behavior and communication are your underlying strengths. You like to 
                create maps of processes and imagine where the pain points are. You make a difference by walking in others’ shoes, looking 
                at problems from multiple angles and seeing the long-term consequences of today’s actions. Things you’re into: storytelling, 
                exploring, listening and sharing.
            </p>
            <ul class="intro--rate">
                <li v-for="detail in details" :key="detail.id">
                    <div class="intro--rate--div">
                    <h4>
                        {{detail.mood}}
                    </h4>
                    <h3>
                        <!-- <svg width="237" height="20" viewBox="0 0 237 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="172" y="9" width="65" height="2" rx="1" fill="#C4C4C4"/>
<rect y="5" width="188" height="9" rx="4.5" fill="url(#paint0_linear_837_3223)"/>
<defs>
<linearGradient id="paint0_linear_837_3223" x1="9.2466e-06" y1="20.3" x2="182.921" y2="-121.933" gradientUnits="userSpaceOnUse">
<stop offset="0.036084" stop-color="#8F3D55"/>
<stop offset="1" stop-color="#D5A48A"/>
</linearGradient>
</defs>
</svg> -->
<div class="progress">
        <div class="line1"></div>
        <div class="line2" style="width: 52%"></div>
      </div>
                        {{detail.number}}
                        <svg :class="{ active: detail.isActive}" @click="handleToggle(detail.id)"  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0002 13.1727L16.9502 8.22266L18.3642 9.63666L12.0002 16.0007L5.63623 9.63666L7.05023 8.22266L12.0002 13.1727Z" fill="#9F9F9F"/>
                        </svg>
                    </h3>
                     </div>
                     <div v-if="detail.isActive" class="intro--rate--text">
                         <p>
                             this is my personality
                         </p>
                     </div>
                </li>
            </ul>
            <div class="intro--summary">
                <h3>
                    Summary:
                </h3>
                <p>
                    This personality type is for people who are very curious,
                     outstanding and pay attention to very minute details. 
                     They are very observant and can be very organized.
                </p>
            </div>
        </div>
    </div>
</section>

</template>

<script>
export default {
    methods: {
        handleToggle(number, active) {
            this.details.map((newDetail, id) => {
                if (newDetail.id === number){
                    newDetail.isActive = !newDetail.isActive
                }
            });
        }
    },
    data() {
        return {
            details: [
                {
                    id: 1,
                    mood: "Introverted",
                    number: "52%",
                    isActive: false
                },
                {
                    id: 2,
                    mood: "Creative",
                    number: "52%",
                    isActive: false
                },
                {
                    id: 3,
                    mood: "Curiosity",
                    number: "52%",
                    isActive: false
                },
                {
                    id: 4,
                    mood: "Attention To  Details",
                    number: "52%",
                  isActive: false
                },
                {
                    id: 5,
                    mood: "Empathy",
                    number: "52%",
                    isActive: false
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.progress {
  position: relative;
  height: 12px;
  background: transparent;
  border-radius: 8px;
  width: 100%;
  .line1 {
      position: relative;
    width: 100%;
    height: 2px;
    top: 50%;
    background: $black-50;
  }
  .line2 {
    position: absolute;
    /* width: 50%; */
    height: 12px;
    background: $secondary-80;
    border-radius: 0 8px 8px 0;
  }
}

.intro {
    background: #F9F2EE;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.intro--box {
    padding: 3rem 7%;
    @include flex(space-between, flex-start);
    gap: 4rem;
    .intro--image {
        width: 100%;
        img {
            width: 100%;
            align-self: center;
        }
    }
    .intro--text {
        text-align: left;
        padding-left: 0;
        h2 {
            padding-left: 2rem;
            @include heading3 {
                color: $secondary-80;
                font-family: $karla;
            }
        }
        p {
            padding-left: 2rem;
            @include body {
                color: $secondary-80;
                font-family: $karla;
            }
        }
        .intro--summary {
            text-align: left;
            padding-left: 2rem;
            h3 {
                 @include heading3 {
                color: $secondary-80;
                font-family: $karla;
            }
            }
            p {
                 @include body {
                color: $secondary-80;
                font-family: $karla;
            }
            }
        }
    }
}
.intro--rate {
    li {
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 0 1rem 0 1rem;
        width: 41rem;
        height: auto;
        margin: 0.6rem 0;
        .intro--rate--div {    
        @include flex(space-between, center);
        h3 {
            width: 60%;
            @include flex(center, center);
            gap: 1rem;
            @include button {
               color: $secondary-80;
                font-family: $karla; 
            }
        }
        h4 {
            @include body {
               color: $secondary-80;
                font-family: $karla; 
            }
        }
    }
    .intro--rate--text {
        p {
            padding: 0.2rem 0.1rem;
            @include caption {
               color: $secondary-80;
                font-family: $karla; 
            } 
        }
    }
    }
}
svg {
    margin-left: 1rem;
}
/* to rotate svg */
.active {
    transform: rotate(180deg);
}
@include xl {
    .intro--box {
        flex-direction: column;
        padding: 3rem 2rem;
        .intro--image {
        margin: 0 auto;
        img {
            margin: 0 auto;
        }
    }
     .intro--text {
         margin: 0 auto;
         width: min(95%, 700px);
         padding-left: 0rem;
         h2 {
             margin: 0 auto;
             width: min(100%, 695px);
            @include heading3 {
                font-size: 2rem;
            }
        }
         p {
             margin: 0 auto;
             width: min(100%, 695px);
            @include body {
                color: $secondary-80;
                font-family: $karla;
            }
        }
     }
     .intro--summary {
            text-align: left;
            width: min(100%, 700px);
            padding-left: 0rem;
            h3 {
                 @include heading3 {
                color: $secondary-80;
                font-family: $karla;
            }
            }
            p {
                 @include body {
                color: $secondary-80;
                font-family: $karla;
            }
            }
        }
    }
    .intro--rate {
        li {
        width: min(100%, 824px);
        .intro--rate--div {
         h3 {
             display: flex;
             justify-content: space-between;
            @include button {
                font-size: 0.9rem;
            }
            svg {
                margin-left: 0.2rem;
                    width: 100%;
                }
        }
        h4 {
            width: 30%;
            @include body {
                 font-size: 0.9rem;
            }
        }
        }
        }
    }
}
@include sm {
    .intro--box {
        .intro--text {
            h2 {
            @include heading3 {
                font-size: 1.2rem;
            }
        }
            p {
             @include body {
                     font-size: 0.9rem;
            }
        }
            .intro--summary {
            h3 {
                 @include heading3 {
                font-size: 1.5rem;
            }
            }
            p {
                 @include body {
                     font-size: 0.9rem;
            }
            }
        }
        }
    }
}
</style>