<template>
  <Intro />
</template>

<script>
import Intro from './Intro'
export default {
components: {
    Intro
}
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";

</style>