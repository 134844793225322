import axios from "axios";

const state = {
    mentors: null,
    details: null,
    field: null,
};

const getters = {
  MentorState: state => state.mentors,
  DetailsState: state => state.details,
  FieldState: state => state.field,
};

const actions = {
    async GetMentor({commit}) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get("mentors", {headers: headers});
        let data = res.data.data.mentors
        await commit("setMentor", data)
    },
    async MentorDetails({commit}, Id) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`mentors/${Id}`, {headers: headers})
        let deets = res.data.data.mentor
        await commit("setDetails", deets)
    },
    async MentField({commit}, Field) {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": true,
            "Content-Type": "application/json",
            Authorization: `Bearer` + " " + window.localStorage.getItem("token"),
        }
        let res = await axios.get(`mentors/filter?fields[focus]=${Field}`, {headers: headers})
         
        let project = res.data.data.mentors
        await commit("setField", project);
    },
};
const mutations = {
    setMentor(state, data) {
        state.mentors = data
    },
    setDetails(state, deets) {
        state.details = deets
    },
    setField(state, fields) {
        state.field = fields
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}