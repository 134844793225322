<template>
  <Intro />
<section class="details">
      <div class="details--box">
         <div class="details--similar">
          <h2>
            Careers that fits the User Advocate Personality:
          </h2>
          <ul class="details--similar--career">
            <li>UI/UX Design</li>
            <li>Product Design</li>
            <li>Determine the information architecture of a digital product.</li>
            <li>Design user flows and wireframes.</li>
            <li>Determine how users interact with products.</li>
            <li>Design product interface and features</li>
            <li>Design product interface and features</li>
            <li>Test products on real users.</li>
          </ul>
        </div>
        <div class="details--others--box">
          <div class="details--others">
            <div class="profile--text">
              <h2>
                  Popular Tech People with similar personality
              </h2>
          </div>
            <div class="profile--images">
              <div class="profile--images--details">
                  <img src="@/assets/Personality/Profile/graphics.png" alt="">
                  <h4>
                      Daniel Abayomi
                  </h4>
                  <p>
                      @Daniel Bezos
                  </p>
                  <p>
                      Lead Designer @Brass
                  </p>
              </div>
              <div class="profile--images--details">
                  <img src="@/assets/Personality/Profile/backend.png" alt="">
                  <h4>
                      Aderinsola
                  </h4>
                  <p>
                      @Aderinsola_O
                  </p>
                   <p>
                      Designer @Eden Life
                  </p>
              </div>
              <div class="profile--images--details">
                  <img src="@/assets/Personality/Profile/writer.png" alt="">
                  <h4>
                      Adedamole Adewale
                  </h4>
                  <p>
                      @moski____
                  </p>
                  <p>
                      Lead Designer @DABA
                  </p>
              </div>
              <div class="profile--images--details">
                  <img src="@/assets/Personality/Profile/blockchain.png" alt="">
                  <h4>
                      Usifor Murphy
                  </h4>
                  <p>
                      @Druids01
                  </p>
                  <p>
                     BlockchainDesigner
                  </p>
              </div>
          </div>
          </div>
          </div>
      </div>
     </section>
</template>

<script>
import Intro from '@/components/Company/People.vue'
import People from '@/components/Personality/Profile/People.vue'
export default {
components: {
  People,
  Intro
}
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
@import "@/styles/_typography.scss";
.details {
  padding: 0rem 0;
}
svg {
  width: 100%;
}
.details--similar {
  background: linear-gradient(180deg, #F0DED5 -18.15%, rgba(240, 222, 213, 0) 100%);
  padding: 2rem 0 3rem 3rem;
  text-align: center;
  h2 {
    margin-bottom: 3rem;
    @include sub-heading-bold {
      color: $secondary-80;
      font-family: $karla;
      font-size: 2rem;
    }
  }
  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8rem;
    text-align: left;
    margin: 0 auto;
    width: 90%;
    li {
      margin: 1rem 0;
      list-style: circle;
      @include caption {
        font-family: $karla;
        color: $secondary-80;
      }
    }
  }
}
.details--others--box {
  background: $primary-40;
}
.details--others {
  padding: 4rem 7%;
    text-align: center;
}
.profile--text {
    h2 {
        font-family: 'Karla';
        font-style: normal;
        font-weight: 700;
        font-size: 3rem;
        line-height: 120%;
        letter-spacing: -0.035em;
        color: $secondary-80;
        margin-bottom: 4rem;
    }
}
.profile--images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    column-gap: 2rem;
    row-gap: 8rem;
    place-items: center;

    img {
        margin-bottom: 2rem;
    }
    h4 {
        margin-bottom: 1.5rem;
        @include profile {
            color: $black-50;
        }
    }
    p {
        @include profile-p {
            color: $black;
        }
    }
}
@include lg {
  .details--similar {
  background: linear-gradient(180deg, #F0DED5 -18.15%, rgba(240, 222, 213, 0) 100%);
  padding: 2rem 0 3rem 3rem;
  text-align: center;
  h2 {
    margin-bottom: 3rem;
    @include sub-heading-bold {
      color: $secondary-80;
      font-family: $karla;
    }
  }
  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 8rem;
    text-align: left;
    margin: 0 auto;
    width: min(90%, 900px);
    li {
      margin: 1rem 0;
      @include caption {
        font-family: $karla;
        color: $secondary-80;
      }
    }
  }
}
}
@include sm {
  .details--similar {
    padding: 2rem;
    
         width: min(100%, 700px);
  h2 {
    @include sub-heading-bold {
      font-size: 1.1rem;
    }
  }
  }
  .profile--text {
    h2 {
        font-size: 2rem;
    }
}
}
</style>