<template>
    <section class="reset" @click.self="close">
      <div class="reset__main">
        <nav>
          <p @click="close">X</p>
        </nav>
          <div class="reset__main__form" v-if="steps === 1">
              <h4>Reset your  password</h4>
              <p>REnter your registered email address to reset your account</p>
              <form action="post" @submit.prevent="handleSubmit">
                <div class="password">
                <input required type="password" name="" placeholder="Password" />
                <img src="@/assets/svg/eyes.svg" alt="">
            </div>
            <div class="password">
                <input required type="password" name="" placeholder="Password" />
                <img src="@/assets/svg/eyes.svg" alt="">
            </div>
                  <button type="submit">Continue</button>
              </form>
          </div>
          <div class="reset__main__success" v-if="steps === 2">
            <img src="@/assets/svg/done.svg" width="317" height="211" alt="" />
        <h4>Your Password has been reset successfully</h4>
        <router-link to="/login">
          <button type="submit">Continue to Login</button>
        </router-link>
          </div>
      </div>
    </section>
  </template>
  
  <script setup>
  import {ref} from "@vue/reactivity";
  import { useStore } from "vuex";
  const store = useStore()
  
  const steps = ref(1)
  const handleSubmit = () => {
    steps.value = 2;
  };
  const close = () => {
  store.state.AccessState = null
}
  </script>
  
  <style lang="scss" scoped>
  .reset {
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      z-index: 4;
      @include flex(center, center);
      background-color: #dddddd80;
      &__main {
          width: Min(90%, 478px);
          background-color: $white;
          padding: 3rem 1.5rem;
          text-align: center;
          min-height: 75%;
          nav {
            p {
              text-align: right;
              font-family: 'Karla', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: Max(1rem, 16px);
              line-height: Max(1, 16px);
              letter-spacing: 0.015em;
              color: $black;
              cursor: pointer;
            }
          }
          &__form {
              width: Min(100%, 320px);
              margin: 0 auto;
              p {
            font-family: "Karla", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: Max(0.875rem, 12px);
            line-height: Max(0.875rem, 14px);
            color: $gray-60;
          }
        h4 {
          font-family: "Karl", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: Max(1.875rem, 24px);
          line-height: Max(3.188rem, 28px);
          color: $secondary;
          }
          form {
              width: 100%;
              input {
                  background: $white;
                  border: 1px solid #B8B8B8;
                  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
                  border-radius: 6px;
                  width: 100%;
                  height: Max(3rem, 46px);
                  padding: 1rem 1.5rem;
                  &::placeholder {
                      font-family: 'Karla', sans-serif;
                      font-style: normal;
                      font-weight: 400;
                      font-size: Max(0.75rem, 12px);
                      line-height: Max(0.875, 14px);
                      letter-spacing: 0.015em;
                      color: $gray-20;
                  }
                  font-family: 'Karla', sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  font-size: Max(1rem, 16px);
                  line-height: Max(1, 16px);
                  letter-spacing: 0.015em;
                  color: $black;
              }
              .password {
                @extend %flex-ac;
                width: 100%;
                background: $white;
                border: 1px solid #B8B8B8;
                box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
                border-radius: 6px;
                padding: 1.5px 1px;
                margin: 1rem 0;
                input {
                    border: none;
                    border-radius: 0;
                }
            }
              button {
                  width: 100%;
                  height: Max(3rem, 44px);
                  background: $secondary-80;
                  border-radius: 8px;
                  font-family: 'Space Grotesk', sans-serif;
                  font-style: normal;
                  font-weight: 700;
                  font-size: Max(1.125rem, 18px);
                  line-height: Max(1.5rem, 24px);
                  letter-spacing: 0.02em;
                  text-transform: capitalize;
                  color: $white;
                  margin-top: 1.5rem;
              }
          }
      }
      &__success {
          width: Min(100%, 320px);
              margin: 0 auto;
          text-align: center;
          h4 {
          font-family: "Space Grotesk", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: Max(1.875rem, 20px);
          line-height: Max(3.188rem, 28px);
          color: $secondary;
        }
        p {
          font-family: "Space Grotesk", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: Max(1rem, 14px);
          line-height: Max(1.5rem, 24px);
          color: $black-10;
          span {
              color: $secondary;
          }
        }
        button {
                  width: 100%;
                  height: Max(3rem, 44px);
                  background: $secondary-80;
                  border-radius: 8px;
                  font-family: 'Space Grotesk', sans-serif;
                  font-style: normal;
                  font-weight: 700;
                  font-size: Max(1.125rem, 18px);
                  line-height: Max(1.5rem, 24px);
                  letter-spacing: 0.02em;
                  text-transform: capitalize;
                  color: $white;
                  margin-top: 1.5rem;
              }
      }
  }
  }
  </style>