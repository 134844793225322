<template>
  <Backend />
</template>

<script>
import Backend from '@/components/Courses/Backend/Backend.vue'
export default {
components: {
    Backend
}
}
</script>

<style>

</style>